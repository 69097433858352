.display-options{
    display: none !important;
}

.nav-bar-title {
    width: 600px;
    color: #505050;
    font-size: 22px;
    font-family: 'Montserrat';
    font-weight: 700;
    letter-spacing: .1px;
    margin-top: 5px;
}

/* Tablets */
@media only screen and (max-width: 960px) {
    .display-options{
        display: none !important;
    }
}

/* Phones */
@media only screen and (max-width: 767px) {
    .display-options{
        display: block !important;
    }

    .nav-bar-title {
        display: none;
    }
}

