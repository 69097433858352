.maintitleprogram {
  font-family: Montserrat, sans-serif !important;
  color: #505050 !important;
  font-weight: 700 !important;
  flex-grow: 1;
  margin: 0 !important;
  margin-bottom: 5px !important;
  font-size: 2.5rem !important;
}
.containerall {
  display: flex;
  padding: 1.5em;
  margin: 0;
}
.leftcontainerprogram {
  width: 70%;
  margin-bottom: 14px;
}
.rightcontainerprogram {
  width: 30%;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;
  border-radius: 15px;
  background-color: #f8f6f6;
}

.right-cont-name {
  color: #283680 !important;
  font-weight: bold !important;
  font-family: "Montserrat" !important;
  letter-spacing: 0px !important;
  font-weight: 700 !important;
  font-size: 24px !important;
  margin: 15px 0 15px 0 !important;
  padding-top: 0px !important;
}

.pricingcard {
  width: 100%;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  height: 80px !important;
  cursor: pointer;
  background-color: #fff;
  margin-bottom: 15px !important;
}

.pricingcard-name {
  text-align: start !important;
  margin-bottom: 0 !important;
  font-weight: bold !important;
  color: #505050 !important;
  font-family: "Montserrat" !important;
  font-size: 0.9em !important;
  letter-spacing: 0px !important;
}

.pricingcard-days {
  text-align: justify;
  color: gray !important;
  margin-bottom: 0 !important;
  font-family: Open Sans, sans-serif !important;
  font-size: 0.9em !important;
}

.pricingcard-amount {
  color: #283680 !important;
  margin-bottom: 0 !important;
  font-weight: bold !important;
  font-size: 0.9em !important;
  text-align: right !important;
}

.maincontainerdiv {
  margin: 10px 30px;
  display: flex;
}

.image-icon-view {
  display: flex !important;
}

.imageprogram {
  width: 40% !important;
  height: 230px !important;
  box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.15) !important;
  border-radius: 7px !important;
  object-fit: cover !important;
  max-height: 300px !important;
}

.titledivision {
  display: block;

  margin: 10px 30px;
}
.widgetdata {
  text-align: center;
  margin-left: 50px !important;
}

.org-title {
  margin: 0px 0px 10px 0px !important;
  color: #999 !important;
  font-weight: 600 !important;
  font-family: "Montserrat" !important;
  text-decoration: underline !important;
  cursor: pointer !important;
  font-size: 1.2rem !important;
}

.icon-img {
  width: 40px !important;
  height: 40px !important;
  margin-right: 15px !important;
}

.icon-text {
  font-size: 12px !important;
  color: grey !important;
  text-align: justify !important;
  font-family: "Quicksand", sans-serif !important;
}

.box-size {
  margin: 20px 30px -13px !important;
  border-top: 1px solid #d3d2d3 !important;
  padding-top: 0px !important;
}

.box-description-text {
  font-weight: 400;
  font-family: "Open sans", sans-serif;
  white-space: pre-line;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

.sub-title {
  /* margin: 0 !important; */
  color: #ff6663 !important;
  margin-bottom: 20px !important;
  font-family: "Open Sans" !important;
  font-size: 24px !important;
}

.map-size {
  width: 100% !important;
  height: 40rem !important;
  display: block;
}

.show-map {
  display: block;
}

input[type='radio'] {
  background-color: grey;
  background: grey;
  color: grey;
}

input[type='radio']:after {
  /* width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative; */
  background-color: grey;
  background: grey;
  color: grey;
  /* content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white; */
}

input[type='radio']:checked:after {
  /* width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative; */
  background-color: grey;
  background: grey;
  color: grey;
  /* content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid white; */
}

.radio-btn-positioning-container{
  margin-right: -5px;
  margin-left: -15px;
}

/* Tablets */
@media only screen and (max-width: 1164px) {
  .image-icon-view {
    display: flex !important;
  }

  .imageprogram {
    width: 45% !important;
    height: 150px !important;
    box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.15) !important;
    border-radius: 7px !important;
    object-fit: cover !important;
    max-height: unset !important;
    margin-right: 0px !important;
  }

  .sub-title {
    /* margin: 0 !important; */
    color: #ff6663 !important;
    margin-bottom: 20px !important;
    font-family: "Open Sans" !important;
    font-size: 22px !important;
  }

  .widgetdata {
    text-align: center;
    margin-left: 30px !important;
  }

  .icon-img {
    width: 27px !important;
    height: 27px !important;
    margin-right: 10px !important;
  }

  .icon-text {
    font-size: 11px !important;
    color: grey !important;
    text-align: justify !important;
    font-family: "Quicksand", sans-serif !important;
    margin-bottom: 0px !important;
  }

  .rightcontainerprogram {
    width: 30%;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 15px;
    background-color: #f8f6f6;
  }

  .right-cont-name {
    color: #283680 !important;
    font-weight: bold !important;
    font-family: "Montserrat" !important;
    letter-spacing: 0px !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    margin: 15px 0 15px 0 !important;
    padding-top: 0px !important;
  }

  .pricingcard {
    width: 100%;
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px !important;
    height: 80px !important;
    cursor: pointer;
    background-color: #fff;
  }

  .pricingcard-name {
    text-align: start !important;
    margin-bottom: 0 !important;
    font-weight: bold !important;
    color: #505050 !important;
    font-family: "Montserrat" !important;
    font-size: 0.8em !important;
    letter-spacing: 0px !important;
  }

  .pricingcard-days {
    text-align: justify;
    color: gray !important;
    margin-bottom: 0 !important;
    font-family: Open Sans, sans-serif !important;
    font-size: 0.7em !important;
  }

  .pricingcard-amount {
    color: #283680 !important;
    margin-bottom: 0 !important;
    font-weight: bold !important;
    font-size: 0.8em !important;
    text-align: right !important;
  }

  .box-size {
    margin: 20px 30px -13px !important;
    border-top: 1px solid #d3d2d3 !important;
    padding-top: 0px !important;
  }

  .box-description-text {
    font-weight: 400;
    font-family: "Open sans", sans-serif;
    white-space: pre-line;
    margin-bottom: 20px;
    font-size: 13px; /* Changed */
  }

  .map-size {
    width: 100% !important;
    height: 30rem !important;
    display: block;
  }

  .show-map {
    display: block;
  }

  .radio-btn-positioning-container{
    margin-right: -7px;
    margin-left: -8px;
  }
}

@media only screen and (max-width: 767px) {
  .maintitleprogram {
    font-family: Montserrat, sans-serif !important;
    color: #505050 !important;
    font-weight: 700 !important;
    flex-grow: 1;
    margin: 0 !important;
    margin-bottom: 3px !important;
    font-size: 2rem !important;
  }
  .maincontainerdiv {
    margin: 10px 30px;
    display: block;
  }

  .image-icon-view {
    display: block !important;
  }

  .imageprogram {
    width: 100% !important;
    height: 150px;
    box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.15);
    border-radius: 7px;
    object-fit: cover;
    max-height: unset;
  }
  .widgetdata {
    text-align: center;
    margin-top: 20px;
    margin-left: 0px !important;
  }
  .leftcontainerprogram {
    width: 100%;
  }
  .containerall {
    display: block;
    padding: 0.5em;
    margin: 0;
  }
  .rightcontainerprogram {
    width: 100%;
    text-align: center;
    margin-top: 80px;
    margin-bottom: 10px;
    border-radius: 15px;
    padding: 10px 0;
  }

  .right-cont-name {
    color: #283680 !important;
    font-weight: bold !important;
    font-family: "Montserrat" !important;
    letter-spacing: 0px !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    margin: 15px 0 15px 0 !important;
    padding-top: 0px !important;
  }

  .pricingcard {
    width: 100%;
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px !important;
    height: 80px !important;
    cursor: pointer;
    background-color: #fff;
  }

  .pricingcard-name {
    text-align: start !important;
    margin-bottom: 0 !important;
    font-weight: bold !important;
    color: #505050 !important;
    font-family: "Montserrat" !important;
    font-size: 0.8em !important;
    letter-spacing: 0px !important;
  }

  .pricingcard-days {
    text-align: justify;
    color: gray !important;
    margin-bottom: 0 !important;
    font-family: Open Sans, sans-serif !important;
    font-size: 0.8em !important;
  }

  .pricingcard-amount {
    color: #283680 !important;
    margin-bottom: 0 !important;
    font-weight: bold !important;
    font-size: 0.8em !important;
    text-align: right !important;
  }

  .org-title {
    margin: 0px 0px 10px 0px !important;
    color: #999 !important;
    font-weight: 600 !important;
    font-family: "Montserrat" !important;
    text-decoration: underline !important;
    cursor: pointer !important;
    font-size: 1.2rem !important;
  }

  .icon-img {
    width: 30px !important;
    height: 30px !important;
    margin-right: 10px !important;
  }

  .icon-text {
    font-size: 12px !important;
    color: grey !important;
    text-align: justify !important;
    font-family: "Quicksand", sans-serif !important;
    margin-bottom: 15px !important;
  }

  .sub-title {
    /* margin: 0 !important; */
    color: #ff6663 !important;
    margin-bottom: 20px !important;
    font-family: "Open Sans" !important;
    font-size: 20px !important;
  }

  .box-size {
    margin: 0px 30px 0px !important;
    border-top: 1px solid #d3d2d3 !important;
    padding-top: 0px !important;
  }

  .box-description-text {
    font-weight: 400;
    font-family: "Open sans", sans-serif;
    white-space: pre-line;
    margin-bottom: 30px;
    font-size: 13px; /* Changed */
    padding-bottom: 20px;
  }

  .map-size {
    width: 100% !important;
    height: 20rem !important;
    display: block;
  }

  .show-map {
    display: none;
  }

  .radio-btn-positioning-container{
    margin-right: -7px;
    margin-left: -16px;
  }
}
