@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap");
@import "~react-agenda/build/styles.css";
@import "~react-datetime/css/react-datetime.css";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

.agenda__table.\--body {
  height: calc(100vh - 32vh) !important;
}
.console-pages .topbar .dropdown .dropdown-menu {
  left: 0;
  right: 0 !important;
  margin: auto;
  margin-left: -175px;
  width: 400px !important;
  overflow: inherit;
}
.hoverable:hover {
  color: #052470;
}
.console-pages .cont .parent__handbook_link {
  display: block;
  text-align: center;
  font-size: 13px;
  line-height: 28px;
  margin-top: 10px;
  width: 100%;
  padding: 8px auto;
  border-radius: 8px;
  color: #ffffff;
  background: #7ea1ff;
  border: 1px solid #7ea1ff;
  cursor: pointer;
}

.console-pages .cont .parent__handbook_link:hover {
  text-decoration: none;
}

.console-pages .cont .parent__handbook_link:focus {
  outline: none;
}

.react-dropdown-select-content input {
  margin-bottom: 0 !important;
}

.console-pages .photoContainer {
  position: relative;
}

.console-pages .bl-bbody p {
  font-size: 13px !important;
  color: black !important;
}

.console-pages .photoContainerX {
  opacity: 0;
  position: absolute;
  display: flex;
  top: 0px;
  right: -15px;
  border-radius: 8px;
}

.console-pages .photoContainerY {
  opacity: 0;
  position: absolute;
  display: flex;
  bottom: 0px;
  right: 11px;
  border-radius: 8px;
}

.console-pages .photoContainer:hover .photoContainerX {
  opacity: 1;
}

.console-pages .photoContainer:hover .photoContainerY {
  opacity: 1;
}

.console-pages .photoContainerX .innerX {
  color: red;
  font-size: 15px;
}

.console-pages .photoContainerY .innerY {
  color: blue;
  font-size: 20px;
}

.main-pages .gallery-control:hover,
.main-pages .gallery-control:focus,
.main-pages .gallery-close:hover,
.main-pages .gallery-close:focus {
  background: none;
  border: none;
}

.main-pages .gallery-control,
.main-pages .gallery-close {
  font-size: 0.4em;
}

.react-phone-number-input__country img,
.react-phone-number-input__country--native img {
  vertical-align: top !important;
}

.console-pages #wrapper #content-wrapper #content {
  background: #eceffa !important;
}

.console-pages #wrapper #content-wrapper {
  min-height: 100vh;
  padding-left: 14rem;
}

.console-pages .scroll-to-top {
  z-index: 1;
  width: 1.75rem !important;
  height: 1.75rem !important;
  line-height: 1.77rem !important;
}

.console-pages .bg-gradient-primary {
  position: relative;
  padding-bottom: 110px;
  background: #7ea0ff;
  background: -moz-linear-gradient(top, #7ea0ff 0%, #8f57ff 100%) !important;
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, #7ea0ff),
    color-stop(100%, #8f57ff)
  ) !important;
  background: -webkit-linear-gradient(top, #7ea0ff 0%, #8f57ff 100%) !important;
  background: -o-linear-gradient(top, #7ea0ff 0%, #8f57ff 100%) !important;
  background: -ms-linear-gradient(top, #7ea0ff 0%, #8f57ff 100%) !important;
  background: linear-gradient(to bottom, #7ea0ff 0%, #8f57ff 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7ea0ff', endColorstr='#8f57ff', GradientType=0 );
}
.console-pages .bg-gradient-secondary {
  position: relative;
  padding-bottom: 110px;
  background: #7ea0ff;
  background: -moz-linear-gradient(top, #01a3a7 0%, #007373 100%) !important;
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, #7ea0ff),
    color-stop(100%, #8f57ff)
  ) !important;
  background: -webkit-linear-gradient(top, #01a3a7 0%, #007373 100%) !important;
  background: -o-linear-gradient(top, #01a3a7 0%, #007373 100%) !important;
  background: -ms-linear-gradient(top, #01a3a7 0%, #007373 100%) !important;
  background: linear-gradient(to bottom, #01a3a7 0%, #007373 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#01A3A7', endColorstr='#007373', GradientType=0 );
}
.console-pages .bg-gradient-orange {
  position: relative;
  padding-bottom: 110px;
  background: #7ea0ff;
  background: -moz-linear-gradient(top, #fd9d4f 0%, #fc663a 100%) !important;
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, #7ea0ff),
    color-stop(100%, #8f57ff)
  ) !important;
  background: -webkit-linear-gradient(top, #fd9d4f 0%, #fc5e30 100%) !important;
  background: -o-linear-gradient(top, #fd9d4f 0%, #fc5e30 100%) !important;
  background: -ms-linear-gradient(top, #fd9d4f 0%, #fc5e30 100%) !important;
  background: linear-gradient(to bottom, #fd9d4f 0%, #fc5e30 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FD9D4F', endColorstr='#FC5E30', GradientType=0 );
}
.console-pages .bg-gradient-blue {
  position: relative;
  padding-bottom: 110px;
  background: #7ea0ff;
  background: -moz-linear-gradient(top, #7494de 0%, #4250ac 100%) !important;
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, #7ea0ff),
    color-stop(100%, #8f57ff)
  ) !important;
  background: -webkit-linear-gradient(top, #7494de 0%, #4250ac 100%) !important;
  background: -o-linear-gradient(top, #7494de 0%, #4250ac 100%) !important;
  background: -ms-linear-gradient(top, #7494de 0%, #4250ac 100%) !important;
  background: linear-gradient(to bottom, #7494de 0%, #4250ac 100%) !important;
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7494DE', endColorstr='#4250AC', GradientType=0 );
}

.console-pages .sidebar-dark .nav-link img {
  width: 12px;
  max-height: 12px;
  top: 0 !important;
  margin-right: 0 !important;
  position: relative !important;
}
.console-pages .sidebar-dark .nav-linkpartner img {
  width: 15px;
  max-height: 15px;
  top: 0 !important;
  margin-right: 0 !important;
  position: relative !important;
}

.console-pages .sidebar-dark hr.sidebar-divider {
  display: none;
}

.console-pages .sidebar-dark .sidebar-heading {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 700 !important;
  color: #f0f0f0 !important;
  font-size: 13px !important;
  line-height: 17px !important;
  margin-top: 20px;
  margin-bottom: 5px !important;
}

.console-pages .sidebar-dark .nav-item.active .nav-link {
  color: #fff;
  padding-left: 2rem;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0px;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}
.console-pages .sidebar-dark .nav-item.active .nav-linkpartner {
  color: #fff;
  padding: 5px 10px 5px 20px;
  width: 95%;
  align-items: center;
  margin: 0 auto;
  font-size: 20px;
  border-radius: 10px;
  display: flex;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.console-pages .sidebar-dark .nav-item.active .nav-link img,
.console-pages .sidebar-dark .nav-item.active .nav-link i {
  margin-right: 15px;
}
.routes-header {
  padding: 1rem 3rem;
}
@media only screen and (max-width: 1100px) {
  .routes-header {
    padding: 1rem;
  }
}
.console-pages .sidebar-dark .nav-item.active .nav-linkpartner img,
.console-pages .sidebar-dark .nav-item.active .nav-linkpartner i {
  margin-right: 15px;
}
.console-pages .sidebar-dark .nav-item.active .nav-link:hover {
  opacity: 0.7;
}
.console-pages .sidebar-dark .nav-item.active .nav-linkpartner:hover {
  cursor: pointer;
  background-color: rgba(250, 250, 250, 0.219);
}

.console-pages .sidebar .nav-item .nav-link span {
  font-weight: 400;
  font-size: 0.8rem !important;
  margin-left: 25px;
}

.console-pages .sidebar .nav-item .nav-link img {
  position: absolute;
  top: 5px;
}

.console-pages .sidebar .nav-item .nav-linkpartner span {
  font-weight: 400;
  font-size: 1rem !important;
  margin-left: 25px;
}
.lesspadding {
  padding: 30px 30px !important;
  max-height: 60vh;
  overflow-y: scroll;
}

.request-modal.lesspadding label {
  color: #7b7b90 !important;
  font-weight: bold !important;
  text-align: left !important;
  font-size: 14px;
  margin: 0;
}
.setcolorul a {
  color: #7f7f7f !important;
}
.setcolorul a:hover {
  color: #3b3a3a !important;
}
.centuryfont {
  font-family: "Century Gothic,CenturyGothic,AppleGothic,sans-serif" !important;
}
.quicksandff {
  font-family: "Quicksand", sans-serif !important;
}
.partnerbutton {
  background-color: #fff;
  border: 1px solid #ff6663;
  color: #ff6663 !important;
  padding: 3px 10px;
  font-weight: bold !important;
  font-family: "Quicksand" !important;
  font-size: 12px !important;
  border-radius: 8px !important;
}
.partnerbutton:hover {
  background-color: #ff6663 !important;
  border: 1px solid #ff6663 !important;
  color: #fff !important;
}

@media only screen and (max-width: 768px) {
  .partnerbutton {
    background-color: #fff;
    border: 1px solid #ff6663;
    color: #ff6663 !important;
    padding: 3px 10px;
    font-weight: bold !important;
    font-family: "Quicksand" !important;
    font-size: 12px !important;
    border-radius: 8px !important;
  }
}

.form-group.divlabelleft label {
  text-align: left !important;
}
.request-modal.lesspadding h3 {
  font-size: 18px !important;
  font-weight: 530 !important;
}
.request-modal.lesspadding .form-group {
  margin-bottom: 0.3rem !important;
}
.request-modal.lesspadding.more .form-group {
  margin-bottom: 0.2rem !important;
}
.request-modal.lesspadding .modal-footer {
  margin: 10px auto 0;
}
.request-modal.lesspadding .modal-footer button {
  padding: 5px 8px !important;
  margin: 4px 3px 0 !important;
}
.spanpageselected {
  border: 1px solid blue;
  border-radius: 50%;
  padding: 0px 6px;
  color: black;
  background-color: lightblue;
}
.console-pages .sidebar .nav-item .nav-linkpartner img {
  position: absolute;
  top: 5px;
}
.console-pages .sidebar-dark .sidebar-brand {
  margin-top: 10px;
}

.twitterbcg {
  background-image: url("../img/XLogo_1.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 27px;
  height: 27px;
}
.twitterbcg:hover {
  background-image: url("../img/XLogo_2.png");
  cursor: pointer;
}
.fbbcg {
  background-image: url("../img/IconFB_1.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 27px;
  height: 27px;
}
.fbbcg:hover {
  background-image: url("../img/IconFB_2.webp");
  cursor: pointer;
}
.igbcg {
  background-image: url("../img/IconIG_1.webp");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 27px;
  height: 27px;
}
.igbcg:hover {
  background-image: url("../img/IconIG_2.webp");
  cursor: pointer;
}
/* Tablet */
@media only screen and (max-width: 960px) {
  .twitterbcg {
    background-image: url("../img/XLogo_1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 26px;
    height: 26px;
  }
  .twitterbcg:hover {
    background-image: url("../img/XLogo_2.png");
    cursor: pointer;
  }
  .fbbcg {
    background-image: url("../img/IconFB_1.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 26px;
    height: 26px;
  }
  .fbbcg:hover {
    background-image: url("../img/IconFB_2.webp");
    cursor: pointer;
  }
  .igbcg {
    background-image: url("../img/IconIG_1.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 26px;
    height: 26px;
  }
  .igbcg:hover {
    background-image: url("../img/IconIG_2.webp");
    cursor: pointer;
  }
}
/* Phones */
@media only screen and (max-width: 767px) {
  .twitterbcg {
    background-image: url("../img/XLogo_1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
  }
  .twitterbcg:hover {
    background-image: url("../img/XLogo_2.png");
    cursor: pointer;
  }
  .fbbcg {
    background-image: url("../img/IconFB_1.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
  }
  .fbbcg:hover {
    background-image: url("../img/IconFB_2.webp");
    cursor: pointer;
  }
  .igbcg {
    background-image: url("../img/IconIG_1.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
  }
  .igbcg:hover {
    background-image: url("../img/IconIG_2.webp");
    cursor: pointer;
  }
}

.btn-temp-details {
  background-color: #fff;

  color: #ff6663 !important;
  padding: 5px 10px;
  font-weight: bold !important;
  cursor: pointer;
  display: inline-block;

  height: auto;
  width: 100%;

  border: 1.1px solid #ff6663 !important;
  margin: 0px;
  text-decoration: none;
  transition: all 0.3s ease;
  font-family: "Montserrat", sans-serif !important;
  border-radius: 5px !important;
}
.btn-temp-details:hover {
  background-color: #ff6663 !important;
  border: 1px solid #ff6663 !important;
  color: #fff !important;
}
.console-pages .gra-blue-purple {
  background: #7ea0ff;
  background: -moz-linear-gradient(left, #7ea0ff 0%, #915aff 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #7ea0ff),
    color-stop(100%, #915aff)
  );
  background: -webkit-linear-gradient(left, #7ea0ff 0%, #915aff 100%);
  background: -o-linear-gradient(left, #7ea0ff 0%, #915aff 100%);
  background: -ms-linear-gradient(left, #7ea0ff 0%, #915aff 100%);
  background: linear-gradient(to right, #7ea0ff 0%, #915aff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7ea0ff', endColorstr='#915aff', GradientType=1 );
}

.console-pages .gra-yellow-pink {
  background: #f5c68c;
  background: -moz-linear-gradient(left, #f5c68c 0%, #fd84c0 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #f5c68c),
    color-stop(100%, #fd84c0)
  );
  background: -webkit-linear-gradient(left, #f5c68c 0%, #fd84c0 100%);
  background: -o-linear-gradient(left, #f5c68c 0%, #fd84c0 100%);
  background: -ms-linear-gradient(left, #f5c68c 0%, #fd84c0 100%);
  background: linear-gradient(to right, #f5c68c 0%, #fd84c0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5c68c', endColorstr='#fd84c0', GradientType=1 );
}

.console-pages .gra-purple-blue {
  background: #ac93ff;
  background: -moz-linear-gradient(left, #ac93ff 0%, #98dfe8 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #ac93ff),
    color-stop(100%, #98dfe8)
  );
  background: -webkit-linear-gradient(left, #ac93ff 0%, #98dfe8 100%);
  background: -o-linear-gradient(left, #ac93ff 0%, #98dfe8 100%);
  background: -ms-linear-gradient(left, #ac93ff 0%, #98dfe8 100%);
  background: linear-gradient(to right, #ac93ff 0%, #98dfe8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ac93ff', endColorstr='#98dfe8', GradientType=1 );
}

.console-pages .gra-teal-green {
  background: #2cd6d6;
  background: -moz-linear-gradient(left, #2cd6d6 0%, #aee85e 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #2cd6d6),
    color-stop(100%, #aee85e)
  );
  background: -webkit-linear-gradient(left, #2cd6d6 0%, #aee85e 100%);
  background: -o-linear-gradient(left, #2cd6d6 0%, #aee85e 100%);
  background: -ms-linear-gradient(left, #2cd6d6 0%, #aee85e 100%);
  background: linear-gradient(to right, #2cd6d6 0%, #aee85e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2cd6d6', endColorstr='#aee85e', GradientType=1 );
}

.console-pages .gra-yellow-orange {
  background: #ffd446;
  background: -moz-linear-gradient(left, #ffd446 0%, #fc8c7e 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #ffd446),
    color-stop(100%, #fc8c7e)
  );
  background: -webkit-linear-gradient(left, #ffd446 0%, #fc8c7e 100%);
  background: -o-linear-gradient(left, #ffd446 0%, #fc8c7e 100%);
  background: -ms-linear-gradient(left, #ffd446 0%, #fc8c7e 100%);
  background: linear-gradient(to right, #ffd446 0%, #fc8c7e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd446', endColorstr='#fc8c7e', GradientType=1 );
}

.console-pages .justify-end {
  justify-content: flex-end;
}

.console-pages .align-center {
  align-items: center;
}

.console-pages .justify-evenly {
  display: flex;
  justify-content: space-between;
}

.console-pages .header-button {
  align-self: center;
  padding: 3px 14px;
  border-radius: 14px;
  border: 2px solid #cfe4f3;
  background: white;
  font-size: 11pt;
}

.console-pages .center-margin {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.console-pages .container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.console-pages .mt-50px {
  margin-top: 30px;
}

.console-pages .price {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 20px;
  line-height: 44px;
  color: #7ea1ff;
  text-align: center;
  margin: 0;
}

.console-pages .v-center {
  display: flex;
  width: 100%;
  align-items: center;
  height: 100%;
}

.console-pages .forbtnwrap {
  display: flex;
  width: 100%;
  align-items: center;
  height: 100%;
}

.console-pages .forbtnwrap .forbtnapp {
  text-align: left;
  display: inline-block;
  margin-left: 0;
}

.console-pages .forbtnwrap .forbtnapp .newapplications {
  margin-top: -3px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  color: #1c3ec3;
  background: #fff;
  border-radius: 15px;
  padding: 6px 20px 6px 16px;
  display: flex;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
  border: none;
  -webkit-box-shadow: 0px 0px 7px 2px rgb(214, 214, 214, 1);
  -moz-box-shadow: 0px 0px 7px 2px rgb(214, 214, 214, 1);
  box-shadow: 0px 0px 7px 2px rgb(214, 214, 214, 1);
}

.console-pages .forbtnwrap .forbtnapp .newapplications img {
  margin-right: 15px;
  margin-top: 2px;
}

.console-pages .forbtnwrap .forbtnapp .newapplications:hover {
  opacity: 0.7;
  text-decoration: none;
}

.console-pages h1 {
  font-family: "Open Sans" !important;
  font-weight: 700 !important;
  font-size: 25px !important;
  line-height: 34px !important;
  color: #232795 !important;
  text-align: left !important;
  margin-top: 22px !important;
  margin-bottom: 22px !important;
}

.console-pages h1 a {
  color: #042470;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.console-pages h1 a img {
  position: absolute;
  margin-top: 10px;
  margin-left: -30px;
}

.console-pages h1 a:hover {
  opacity: 0.7;
  text-decoration: none;
}

.console-pages h1 span {
  font-size: 20px;
  color: #575757;
}

.console-pages .cont {
  padding-left: 3rem;
  padding-right: 3rem;
}

.console-pages .marks {
  margin-top: -7px;
  max-width: 100%;
  margin-left: -8px;
}

.console-pages .tablecont {
  width: 100%;
  display: block;
}

.console-pages .tablecont ul {
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  align-items: flex-end;
  min-height: 43px;
  overflow-x: auto;
}

.console-pages .tablecont ul li {
  display: inline-block;
  margin-right: 15px;
  z-index: 0;
}

.console-pages .tablecont ul li button:focus {
  outline: none;
}

.console-pages .tablecont ul li button {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #a3a3a3;
  padding: 8px 12px;
  background: #fff;
  border-top-right-radius: 14px;
  border-top-left-radius: 14px;
  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16);
  position: relative;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
  border: none;
}

.console-pages .tablecont ul li button:hover {
  text-decoration: none;
  color: #000;
}

.console-pages .tablecont ul li .foractive {
  position: absolute;
  right: -14px;
  bottom: 0px;
  position: absolute;
  right: -34px;
  display: none;
}

.console-pages .tablecont ul .active {
  margin-right: 15px;
}

.console-pages .tablecont ul .active button {
  padding: 12px 12px;
  position: relative;
  display: table;
  color: #000;
}

.console-pages .tablecont ul .active .foractive {
  display: block;
}

.console-pages .numb {
  text-align: right;
  margin-bottom: 50px !important;
  margin-top: 25px;
  padding: 0;
}

.console-pages .numb *::selection {
  background-color: transparent;
}

.console-pages .numb li {
  margin-right: 0;
  margin-left: 10px;
  display: inline-block;
}

.console-pages .numb li a {
  background: transparent;
  box-shadow: none;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 27px;
  color: #a3a3a3;
  text-align: center;
  padding: 0;
  margin: 0;
  display: block;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
  text-decoration: none;
  cursor: pointer;
}

.console-pages .numb li a:hover {
  background: #7f9aff;
  color: #fff;
  opacity: 0.7;
}

.console-pages .numb .activeno a {
  background: #7f9aff;
  color: #fff;
}

.console-pages .tablecont .table {
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.05);
  z-index: 1;
  position: relative;
}

.console-pages .tablecont .table thead {
  background: #7ea0ff;
  background: -moz-linear-gradient(left, #80a1ff 0%, #2453bb 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #80a1ff),
    color-stop(100%, #2453bb)
  );
  background: -webkit-linear-gradient(left, #80a1ff 0%, #2453bb 100%);
  background: -o-linear-gradient(left, #80a1ff 0%, #2453bb 100%);
  background: -ms-linear-gradient(left, #80a1ff 0%, #2453bb 100%);
  background: linear-gradient(to right, #80a1ff 0%, #2453bb 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7ea0ff', endColorstr='#915aff', GradientType=1 );
}

.console-pages .tablecont .table thead th {
  border: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;
  color: #fff;
  padding: 10px 15px;
  white-space: nowrap;
}
/* 
.console-pages .tablecont .table thead th:first-child {
  padding-left: 40px;
} */

.console-pages .tablecont .table tbody {
  background: #fff;
}

.console-pages .tablecont .table tbody th,
.console-pages .tablecont .table tbody td {
  border: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  color: #000000;
  padding: 15px;
  border-bottom: 1px solid #e0e0e0;
  vertical-align: middle !important;
}

.console-pages .tablecont .table tbody th button,
.console-pages .tablecont .table tbody td button {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  color: #fff;
  padding: 10px 23px;
  background: #9bb0eb;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
  border: 0;
  margin: 0 1px;
}

.console-pages .tablecont .table tbody td button:disabled {
  color: rgb(199, 199, 199);
  background: #3c4766;
}

.console-pages .tablecont .table tbody td button:disabled:hover {
  color: #fff;
  background: #3c4766;
}

.console-pages .tablecont .table tbody th button:hover,
.console-pages .tablecont .table tbody td button:hover {
  background: #915aff;
  text-decoration: none;
}

.console-pages .tablecont .table tbody th .orange,
.console-pages .tablecont .table tbody td .orange {
  border-radius: 13px;
  background: #ffa81f;
  color: #fff;
  max-width: 70px;
  width: 100%;
  display: block;
  text-align: center;
  padding: 5px;
  margin-top: -5px;
}

.console-pages .tablecont .table tbody th .red,
.console-pages .tablecont .table tbody td .red {
  border-radius: 13px;
  background: #ff3b8e;
  color: #fff;
  max-width: 70px;
  width: 100%;
  display: block;
  text-align: center;
  padding: 5px;
  margin-top: -5px;
}

.console-pages .tablecont .table tbody th .green,
.console-pages .tablecont .table tbody td .green {
  border-radius: 13px;
  background: #1bc88a;
  color: #fff;
  max-width: 75px;
  width: 100%;
  display: block;
  text-align: center;
  padding: 5px;
  margin-top: -5px;
}

.console-pages .tablecont .table tbody th:first-child {
  padding-left: 40px;
}

/* .console-pages .tablecont .table tbody td:last-child {
  padding-right: 40px;
  padding-left: 40px;
  width: 165px;
} */

.console-pages .tablecont .table tbody tr:last-child th,
.console-pages .tablecont .table tbody tr:last-child td {
  border: 0;
}

.console-pages .tablecont .table tbody .space td {
  height: 36px;
  background: #eceffa !important;
  border: 0;
}

.console-pages .tablecont .table tbody .space th:first-child {
  background: #eceffa;
  border: 0;
}

.console-pages .tablecont .table tbody .lastone {
  background: #7ea0ff;
  background: -moz-linear-gradient(left, #7ea0ff 0%, #915aff 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #7ea0ff),
    color-stop(100%, #915aff)
  );
  background: -webkit-linear-gradient(left, #7ea0ff 0%, #915aff 100%);
  background: -o-linear-gradient(left, #7ea0ff 0%, #915aff 100%);
  background: -ms-linear-gradient(left, #7ea0ff 0%, #915aff 100%);
  background: linear-gradient(to right, #7ea0ff 0%, #915aff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7ea0ff', endColorstr='#915aff', GradientType=1 );
}

.console-pages .tablecont .table tbody .lastone th,
.console-pages .tablecont .table tbody .lastone td {
  background: transparent;
  border: 0 !important;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 45px;
  color: #fff;
  margin: 0;
  text-align: center;
}

.console-pages .tablecont .table tbody .lastone th h2,
.console-pages .tablecont .table tbody .lastone td h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 45px;
  color: #fff;
  margin: 0;
}

.console-pages .search {
  text-align: left;
  display: block;
  width: 100%;
  padding: 15px;
  padding-bottom: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #919191;
}

.console-pages .search .input {
  margin-bottom: 15px;
  position: relative;
}

.console-pages .search .input input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #a2a2a2;
  width: 290px;
  padding-bottom: 10px;
}

.console-pages .search .input input:focus {
  outline: none;
}

.console-pages .search .input button {
  background: transparent;
  border: 0;
  position: absolute;
  right: 0;
}

.console-pages .search .input button:focus {
  outline: none;
}

.console-pages .tablebox {
  overflow: hidden;
  margin-top: 25px;
  margin-bottom: 50px;
  background: #ffffff;
  border-radius: 5px;
  -webkit-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  flex-grow: 1;
  font-size: 0.8rem !important;
}
.emoji-mart-scroll + .emoji-mart-bar {
  display: none;
}
.console-pages .tablebox .tableheader {
  padding: 0 18px;
  border-bottom: 1px solid #e5e5e5;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.console-pages .tablebox .tableheader h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 28px;
  color: #000000;
  margin: 0;
}

.console-pages .tablebox .tableheader h2 img {
  margin-left: 20px;
}

.console-pages .tablebox .tableheader h2 .imgfix {
  height: 28px;
  width: 28px;
  padding: 6px;
  background-color: #d9e1ff;
  border-radius: 50%;
}

.console-pages .tablebox .tableheader .select {
  text-align: right;
  position: relative;
}

.console-pages .tablebox .tableheader .select select {
  display: inline-block;
  border: 0;
  border-radius: 5px;
  -webkit-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.1);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding: 5px 60px 5px 11px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  color: #7b7b7b;
  margin: 0;
}

.console-pages .tablebox .tableheader .select .arrowdownselect {
  position: absolute;
  right: 10px;
  top: 10px;
  text-outline: 4px;
}

.console-pages .tablebox .tableheader .select select:focus {
  outline: none;
}

.console-pages .tablebox .tablebody .name {
  margin-bottom: 20px;
}
.console-pages .tablebox .tablebody .mysuperdiv {
  border-bottom: 1px solid #f0f0f0;
  padding: 10px;
}
.console-pages .tablebox .tablebody .mysuperdiv:last-child {
  border-bottom: none;
}

.console-pages .tablebox .tablebody .name h2 {
  text-align: left;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #fff;
  display: inline-block;
  padding: 13px 20px 13px 20px;
  background: #9bb6ff;
  border-radius: 40px;
  text-align: center;
  width: 100%;
}

.console-pages .tablebox .tablebody .name .parent-child {
  position: absolute;
  padding: 13px 35px 13px 75px;
  left: 30px;
  top: 12px;
  z-index: 0;
  margin: 0;
  width: 90%;
}

.console-pages .tablebox .tablebody .name img {
  z-index: 1;
  position: relative;
}

.console-pages .tablebox .tablebody .boxleft,
.console-pages .tablebox .tablebody .boxright {
  display: block;
  float: left;
  margin-bottom: 18px;
}

.console-pages .tablebox .tablebody .boxleft p,
.console-pages .tablebox .tablebody .boxright p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  margin: 0;
  margin-bottom: 5px;
  display: block;
}

.console-pages .tablebox .tablebody p span,
.console-pages .tablebox .tablebody p span {
  margin-bottom: 0 !important;
  color: #7c7c7c !important;
}

.console-pages .tablebox .tablebody .boxleft p {
  min-width: 144px;
}

.console-pages .tablebox .tablebody a {
  padding: 28px 0;
  display: block;
  text-decoration: none;
}

.console-pages .tablebox .tablebody h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  text-align: left;
  margin: 0;
}

.console-pages .tablebox .tablebody p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #7e7e7e;
  text-align: left;
  margin: 0;
}

.console-pages .tablebox .tablebody .rightside p {
  line-height: 21px;
  text-align: center;
}

.console-pages .tablebox .tablebody .rightside .status {
  color: #000000;
  line-height: 36px;
}

.console-pages .tablebox .tablebody .overdue {
  background: #f7f8ff;
}

.console-pages .tablebox .tablebody .overdue h2 {
  color: #797979;
}

.console-pages .tablebox .tablebody .paid {
  background: #f7f8ff;
}

.console-pages .tablebox .tablebody .paid .paidbox {
  text-align: center;
}

.console-pages .tablebox .tablebody .paid .paidbox-inner {
  position: relative;
  display: inline-block;
  padding-right: 25px;
}

.console-pages .tablebox .tablebody .paid .paidbox-inner p {
  font-weight: 700;
}

.console-pages .tablebox .tablebody .paid .check {
  position: absolute;
  right: 0;
  top: 15px;
}

.console-pages .tablebox .tablebody .paid .status {
  padding-right: 10px;
}

.console-pages .tablebox .tablebody .paid h2 {
  color: #797979;
}

.console-pages .tablebox .tablebody .graph-header {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
  text-align: left;
  margin-bottom: 15px;
}

.console-pages .tablebox .tablebody .graph-header span {
  font-size: 24px;
  line-height: 30px;
  margin-right: 15px;
}

.console-pages .tablebox .tablebody .recent-deposits-head th {
  border: 0;
  border-bottom: 1px solid #e5e5e5;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  color: #919191;
  padding-left: 15px;
  padding-right: 15px;
  white-space: nowrap;
}

.console-pages .tablebox .tablebody .recent-deposits-body td,
.console-pages .tablebox .tablebody .recent-deposits-body th {
  white-space: nowrap;
  padding: 0;
  padding-left: 15px;
  padding-right: 15px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  padding-top: 30px;
  padding-bottom: 30px;
  border: 0;
}

.console-pages .tablebox .tablebody .recent-deposits-body td a,
.console-pages .tablebox .tablebody .recent-deposits-body th a {
  margin: 0;
  padding: 0;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.console-pages .tablebox .tablebody .recent-deposits-body td a:hover,
.console-pages .tablebox .tablebody .recent-deposits-body th a:hover {
  opacity: 0.7;
}

.console-pages .tablebox .tablebody .recent-deposits-body td .btnt,
.console-pages .tablebox .tablebody .recent-deposits-body th .btnt {
  padding: 5px 10px;
  border-radius: 11px;
  width: auto;
  display: initial;
  background: #fff;
}

.console-pages .tablebox .tablebody .recent-deposits-body td .btnt:hover,
.console-pages .tablebox .tablebody .recent-deposits-body th .btnt:hover {
  text-decoration: none;
}

.console-pages .tablebox .tablebody .recent-deposits-body td .pending,
.console-pages .tablebox .tablebody .recent-deposits-body th .pending {
  border: 1px solid #9f9f9f;
  color: #9f9f9f;
}

.console-pages .tablebox .tablebody .recent-deposits-body td .paid,
.console-pages .tablebox .tablebody .recent-deposits-body th .paid {
  border: 1px solid #1bc88a;
  color: #1bc88a;
}

.console-pages .tablebox .tablebody .recent-deposits-body td .failed,
.console-pages .tablebox .tablebody .recent-deposits-body th .failed {
  border: 1px solid #ff0000;
  color: #ff0000;
}

.console-pages .tablebox .tablebody .recent-deposits-body td .link,
.console-pages .tablebox .tablebody .recent-deposits-body th .link {
  color: #000000;
  text-decoration: underline;
}

.console-pages .tablebox .padded {
  padding: 20px 20px;
}

.console-pages .tablebox .leftlist {
  padding: 0;
  text-align: right;
  margin: 0;
}

.console-pages .tablebox .leftlist li {
  display: inline-block;
  text-align: center;
}

.console-pages .tablebox .leftlist li a {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #a2a2a2;
  padding-bottom: 21px;
  display: inline-block;
  border-bottom: 4px solid #fff;
}

.console-pages .tablebox .leftlist li button {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 10px;
  line-height: 42px;
  color: #a2a2a2;
  /* padding-bottom: 15px; */
  display: inline-block;
  border-bottom: 4px solid #fff;
  background: none;
  border: none;
}

.console-pages .tablebox .leftlist li button:active {
  outline: none;
}

.console-pages .tablebox .leftlist li button:focus {
  outline: none;
}

.console-pages .tablebox .leftlist li .active {
  color: #000;
  border-bottom: 4px solid #7ea1ff;
}

.console-pages .data {
  text-align: right;
  display: block;
  width: 100%;
  padding: 15px;
  padding-bottom: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #919191;
  margin-bottom: 20px;
}

.console-pages .data p {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  color: #000000;
}

.console-pages .data p span {
  font-weight: 400;
}

.console-pages .data .leftarrow {
  margin-right: 15px;
  margin-top: -4px;
}

.console-pages .data .rightarrow {
  margin-top: -4px;
  margin-left: 15px;
}

.console-pages .whatisit {
  margin-left: 10px;
  position: relative;
}

.console-pages .whatisit .infobox {
  visibility: hidden;
  opacity: 0;
  left: 30px;
  top: -10px;
  position: absolute;
  background: #fff;
  padding: 15px;
  padding-bottom: 10px;
  -webkit-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.console-pages .whatisit .infobox p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: #919191;
  margin: 0;
  margin-bottom: 5px;
}

.console-pages .whatisit .infobox p img {
  margin-top: -2px;
  margin-right: 5px;
}

.console-pages .whatisit:hover .infobox {
  opacity: 1;
  visibility: visible;
}

.console-pages .circle {
  margin-right: 10px;
}

.console-pages .ppimg {
  margin-right: 10px;
  margin-top: -3px;
}

.console-pages .boxorange {
  padding: 15px;
  margin-top: 25px;
  background: #f5c68c;
  background: -moz-linear-gradient(left, #f5c68c 0%, #fd85c1 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #f5c68c),
    color-stop(100%, #fd85c1)
  );
  background: -webkit-linear-gradient(left, #f5c68c 0%, #fd85c1 100%);
  background: -o-linear-gradient(left, #f5c68c 0%, #fd85c1 100%);
  background: -ms-linear-gradient(left, #f5c68c 0%, #fd85c1 100%);
  background: linear-gradient(to right, #f5c68c 0%, #fd85c1 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5c68c', endColorstr='#fd85c1', GradientType=1 );
  -webkit-box-shadow: 0px 5px 3px 0px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0px 5px 3px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 5px 3px 0px rgba(0, 0, 0, 0.03);
  display: inline-block;
  flex-grow: 1;
  margin-left: 8px;
  margin-right: 8px;
  border-radius: 5px;
  flex-basis: 0;
  position: relative;
  /* overflow: hidden; */
  min-width: 180px;
}

.console-pages .boxorange h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 20px;
  color: #fff;
  margin-bottom: 15px;
}

.console-pages .info-boxes {
  margin-left: -8px;
  margin-right: -8px;
}

.console-pages .boxorange h3 {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 35px;
  line-height: 41px;
  color: #fff;
  border-right: 0.5px solid #fff;
  padding-right: 15px;
  margin-right: 15px;
}

.console-pages .boxorange .inlinebox {
  display: inline-block;
}

.console-pages .boxorange .inlinebox p {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 19px;
  color: #fff;
}

.console-pages .boxorange .arrowright {
  position: absolute;
  right: 0px;
  top: calc(50% - 40px);
  width: 80px;
  height: 80px;
}

.console-pages .section:last-child a:last-child .boxorange {
  margin-right: 0;
}

.console-pages .bluebox {
  background: #ac93ff;
  background: -moz-linear-gradient(left, #ac93ff 0%, #098cfe 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #ac93ff),
    color-stop(100%, #098cfe)
  );
  background: -webkit-linear-gradient(left, #ac93ff 0%, #098cfe 100%);
  background: -o-linear-gradient(left, #ac93ff 0%, #098cfe 100%);
  background: -ms-linear-gradient(left, #ac93ff 0%, #098cfe 100%);
  background: linear-gradient(to right, #ac93ff 0%, #098cfe 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ac93ff', endColorstr='#098cfe', GradientType=1 );
}

.console-pages .bluebox2 {
  background: #af97ff;
  background: -moz-linear-gradient(left, #af97ff 0%, #99dfe8 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #af97ff),
    color-stop(100%, #99dfe8)
  );
  background: -webkit-linear-gradient(left, #af97ff 0%, #99dfe8 100%);
  background: -o-linear-gradient(left, #af97ff 0%, #99dfe8 100%);
  background: -ms-linear-gradient(left, #af97ff 0%, #99dfe8 100%);
  background: linear-gradient(to right, #af97ff 0%, #99dfe8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#af97ff', endColorstr='#99dfe8', GradientType=1 );
}

.console-pages .orange,
.console-pages .blue {
  display: inline-flex;
  position: relative;
  overflow-x: hidden;
  white-space: nowrap;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
}

.console-pages .orange h4,
.console-pages .blue h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  color: #b1b1b1;
  position: absolute !important;
  left: 0;
  top: -20px;
  height: fit-content;
  margin-bottom: 0px;
}

.console-pages .box {
  margin-top: 0px;
  background: transparent;
  margin-bottom: 15px;
  margin-right: 25px;
  display: flex;
}

.console-pages .box .bgofbox {
  background: #fff;
  border-radius: 8px;
  width: 100%;
  -webkit-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
}

.console-pages .box .header {
  border-bottom: 1px solid #e5e5e5;
}

.console-pages .box .footer {
  border-top: 1px solid #e5e5e5;
  font-size: 9px;
  font-weight: 600;
}

.console-pages .box .header a,
.console-pages .box .header button {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 17px;
  color: #a2a2a2;
  padding: 20px 0px 16px 0;
  margin-right: 30px;
  display: inline-block;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
  border: 0;
  background: none;
}

.console-pages .box .header button:focus {
  outline: none;
}

.console-pages .box .header a:hover {
  text-decoration: none;
  opacity: 0.7;
}

.console-pages .box .header .active {
  border-bottom: #7ea1ff 4px solid;
  color: #000;
}

.console-pages .box h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #000000;
  padding: 12px 0 12px 25px;
  margin-bottom: 0px !important;
}

.console-pages .box .boxofprfilephotos {
  padding: 25px 25px 0;
}

.console-pages .box .boxofprfilephotos img {
  max-height: 80px;
  max-width: 80px;
}

.console-pages .box .boxofprfilephotos .img {
  width: 80px;
  height: 80px;
  vertical-align: middle;
}

.console-pages .box .boxofprfilephotos p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #696969;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

.console-pages .box .boxofprfilephotos button {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #393939;
  text-align: center;
  display: block;
  margin-bottom: 10px !important;
  margin: 0;
  padding: 0;
  margin-top: 16px;
  padding: 5px;
  border: 1px solid #707070;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
  background: none;
  width: 100%;
}

.console-pages .box .boxofprfilephotos button:hover {
  color: #fff;
  background: #707070;
  text-decoration: none;
}

.console-pages .box .formbody {
  padding-bottom: 30px;
}

/* .console-pages .box .formbody hr + .row {
  padding: 10px 20px 0;
} */

.console-pages .box .formbody h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 11px;
  line-height: 17px;
  color: #696969;
  /* padding: 30px 0px 0px 25px; */
  padding: 8px 0 0;
}

.box .formbody select {
  border: none;
  border-bottom: 1px solid #cbcbcb;
  font-size: 11px;
  outline: none;
}

.box .formbody select::after {
  border-color: #cbcbcb;
}

.box .formbody .row {
  padding: 12px 20px 0;
}

.css-12zlm52-ReactDropdownSelect {
  height: auto !important;
}

.box .formbody .d-flex {
  align-items: flex-end;
}

.box .formbody .formbody-invalid {
  border-bottom: 1px solid red;
}

.box .formbody .col-7 {
  justify-content: space-between;
}

.console-pages .box form input {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  /* line-height: 17px; */
  color: #2f2f2f;
  /* color: #696969; */
  border: 0;
  border-bottom: 1px solid #cbcbcb;
  /* padding: 10px 2px; */
  /* margin-top: 20px; */
  /* width: calc(100% - 35px); */
  width: 100%;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
  align-self: flex-end;
  flex-grow: 1;
}

.console-pages .box form input:focus {
  outline: none;
  border-bottom-color: #7ea1ff;
}

.console-pages .box form .swipupbtn {
  padding-left: 60px;
  /* SMALL */
  /* LARGE */
}

.console-pages .box form .swipupbtn .status {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #696969;
  position: absolute;
  margin-top: 6px;
  margin-left: -30px;
}

.console-pages .box form .swipupbtn input[type="checkbox"].ios8-switch {
  position: absolute;
  margin: 8px 0 0 16px;
  visibility: hidden;
}

.console-pages .box form .swipupbtn input[type="checkbox"].ios8-switch + label {
  position: relative;
  padding: 5px 0 0 50px;
  line-height: 2em;
}

.console-pages
  .box
  form
  .swipupbtn
  input[type="checkbox"].ios8-switch
  + label:before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 40px;
  /* x*5 */
  height: 24px;
  /* x*3 */
  border-radius: 16px;
  /* x*2 */
  background: #bcbcbc;
  border: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

.console-pages
  .box
  form
  .swipupbtn
  input[type="checkbox"].ios8-switch
  + label:after {
  content: "";
  position: absolute;
  display: block;
  left: 0px;
  top: 0px;
  width: 24px;
  /* x*3 */
  height: 24px;
  /* x*3 */
  border-radius: 16px;
  /* x*2 */
  background: #fff;
  border: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  margin-left: 1px;
}

.console-pages
  .box
  form
  .swipupbtn
  input[type="checkbox"].ios8-switch
  + label:hover:after {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.console-pages
  .box
  form
  .swipupbtn
  input[type="checkbox"].ios8-switch:checked
  + label:after {
  margin-left: 16px;
}

.console-pages
  .box
  form
  .swipupbtn
  input[type="checkbox"].ios8-switch:checked
  + label:before {
  background: #55d069;
}

.console-pages .box form .swipupbtn input[type="checkbox"].ios8-switch-sm {
  margin: 5px 0 0 10px;
}

.console-pages
  .box
  form
  .swipupbtn
  input[type="checkbox"].ios8-switch-sm
  + label {
  position: relative;
  padding: 0 0 0 32px;
  line-height: 1.3em;
}

.console-pages
  .box
  form
  .swipupbtn
  input[type="checkbox"].ios8-switch-sm
  + label:before {
  width: 28px;
  height: 17px;
  border-radius: 10px;
  /* x*2 */
}

.console-pages
  .box
  form
  .swipupbtn
  input[type="checkbox"].ios8-switch-sm
  + label:after {
  width: 15px;
  /* x*3 */
  height: 15px;
  /* x*3 */
  border-radius: 10px;
  /* x*2 */
  margin-top: 1px;
}

.console-pages
  .box
  form
  .swipupbtn
  input[type="checkbox"].ios8-switch-sm
  + label:hover:after {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
}

.console-pages
  .box
  form
  .swipupbtn
  input[type="checkbox"].ios8-switch-sm:checked
  + label:after {
  margin-left: 12px;
  /* x*2 */
}

.console-pages .box form .swipupbtn input[type="checkbox"].ios8-switch-lg {
  margin: 10px 0 0 20px;
}

.console-pages
  .box
  form
  .swipupbtn
  input[type="checkbox"].ios8-switch-lg
  + label {
  position: relative;
  padding: 7px 0 0 60px;
  line-height: 2.3em;
}

.console-pages
  .box
  form
  .swipupbtn
  input[type="checkbox"].ios8-switch-lg
  + label:before {
  width: 50px;
  /* x*5 */
  height: 30px;
  /* x*3 */
  border-radius: 20px;
  /* x*2 */
}

.console-pages
  .box
  form
  .swipupbtn
  input[type="checkbox"].ios8-switch-lg
  + label:after {
  width: 30px;
  /* x*3 */
  height: 30px;
  /* x*3 */
  border-radius: 20px;
  /* x*2 */
}

.console-pages
  .box
  form
  .swipupbtn
  input[type="checkbox"].ios8-switch-lg
  + label:hover:after {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

.console-pages
  .box
  form
  .swipupbtn
  input[type="checkbox"].ios8-switch-lg:checked
  + label:after {
  margin-left: 20px;
  /* x*2 */
}

.console-pages .fullbox {
  margin: auto;
  background: #fff;
  padding: 55px;
  margin-bottom: 50px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.04);
  position: relative;
}

.console-pages .fullbox .header h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 19px;
  line-height: 26px;
  color: #000000;
  margin: 0;
}

.console-pages .completeline {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 3rem;
  /* background: #dfe4f4; */
}

.console-pages .completeline p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin: 0;
  padding: 0;
}

.console-pages .completeline .complete {
  width: 900px;
  height: 10px;
  background: #fff;
  border-radius: 5px;
  position: absolute;
  margin-left: 130px;
  margin-top: -12px;
}

.console-pages .completeline .complete .completed {
  background: #7ea1ff;
  width: 35%;
  height: 10px;
  position: absolute;
  border-radius: 5px;
}

.console-pages .completeline .complete .circle1 {
  width: 24px;
  height: 24px;
  background: #fff;
  left: calc(25% - 24px);
  border-radius: 50%;
  position: absolute;
  margin-top: -6px;
}

.console-pages .completeline .complete .circle2 {
  width: 24px;
  height: 24px;
  background: #fff;
  left: calc(50% - 24px);
  border-radius: 50%;
  position: absolute;
  margin-top: -6px;
}

.console-pages .completeline .complete .circle3 {
  width: 24px;
  height: 24px;
  background: #fff;
  left: calc(75% - 24px);
  border-radius: 50%;
  position: absolute;
  margin-top: -6px;
}

.console-pages .completeline .complete .circle4 {
  width: 24px;
  height: 24px;
  background: #fff;
  left: calc(100% - 24px);
  border-radius: 50%;
  position: absolute;
  margin-top: -6px;
}

.console-pages .completeline .complete .activeblue {
  background: #7ea1ff;
}

.console-pages .addchild {
  text-decoration: none !important;
  background: transparent;
  border-radius: 8px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  display: flex;
  border: 3px dashed #d3d3d3;
  min-height: 300px;
  flex-grow: 1;
  min-width: 300px;
  max-width: 430px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 15px;
}

.console-pages .addchild:focus {
  outline: none;
}

.console-pages .addchild h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 34px;
  color: #bababa;
  text-align: center;
}

.console-pages .addchild:hover {
  border: 3px dashed #868686;
}

.console-pages .addchild:hover h3 {
  color: #868686;
}

.console-pages .interested-program-box {
  width: 298px;
  height: 348px;
  background: #fff;
  padding-bottom: 10px;
  -webkit-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.03);
  margin: 0 8px 16px 8px;
  display: inline-block;
}

.console-pages .interested-program-box .imghere {
  padding: 10px;
  position: relative;
}

.console-pages .interested-program-box .divforimg {
  position: relative;
}

.console-pages .interested-program-box .divforimg img:first-of-type {
  width: 278px;
  height: 209px;
}

.console-pages .interested-program-box .divforimg .heart {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.console-pages .interested-program-box .divforimg .paragraph {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(88, 88, 88, 0.55);
}

.console-pages .interested-program-box .divforimg .paragraph p {
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  display: inline-block;
  left: 0;
  right: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
}

.program-name {
  white-space: pre-line;
  padding: 3px 5px;
  text-align: center;
  position: inherit;
  font-family: "futura-pt", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;
}

.console-pages .interested-program-box .continbox {
  border-bottom: 1px solid #bcbcbc;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
}

.console-pages .interested-program-box .continbox h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #575757;
}

.console-pages .interested-program-box .continbox p {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #959595;
}

.console-pages .interested-program-box .continbox p a,
.console-pages .interested-program-box .continbox p button {
  color: #7ea1ff;
  border: 1.5px solid #7ea1ff;
  background: white;
  padding: 7px 13px;
  border-radius: 17px;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.console-pages .interested-program-box .continbox p a:hover,
.console-pages .interested-program-box .continbox p button:hover {
  color: #fff;
  background: #7ea1ff;
  text-decoration: none;
}

.console-pages .interested-program-box .continbox p .complete,
.console-pages .interested-program-box .continbox p .complete:disabled {
  color: #fff;
  /* background: #ba7eff;
  border-color: #ba7eff; */
  background: #8f58ff;
  border-color: #8f58ff;
  text-decoration: none;
}

.console-pages .interested-program-box .continbox:last-child {
  border: 0;
}

.console-pages .enrollment-box {
  max-width: 449px;
  width: 449px;
  background: #fff;
  padding-bottom: 10px;
  -webkit-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.03);
  margin-right: 30px;
  margin-bottom: 30px;
  display: inline-block;
}

.console-pages .enrollment-box .imghere {
  padding: 10px;
  position: relative;
}

.console-pages .enrollment-box .divforimg {
  position: relative;
  width: 400px;
  height: 226.42px;
}

.console-pages .enrollment-box .divforimg img {
  max-width: 100%;
}

@media (max-width: 800px) {
  .console-pages .orange,
  .console-pages .blue {
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .console-pages .enrollment-box {
    width: 304px;
  }

  .console-pages .enrollment-box .divforimg {
    width: 284px;
    height: 161px;
  }
}

.console-pages .enrollment-box .divforimg .paragraph {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(88, 88, 88, 0.55);
}

.console-pages .enrollment-box .divforimg .paragraph .verticalcenter {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  left: 0;
  right: 0;
}

.console-pages .enrollment-box .divforimg .paragraph p {
  text-align: center;
  position: relative;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
}

.console-pages .enrollment-box .divforimg .paragraph p a {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #ffffff;
  padding: 7px 21px;
  background: #7ea1ff;
  margin-top: 20px;
  border-radius: 17px;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.console-pages .enrollment-box .divforimg .paragraph p a:hover {
  opacity: 0.7;
  text-decoration: none;
}

.console-pages .enrollment-box .divforimg .paragraph .btnvd {
  margin-top: 13px;
}

.console-pages .enrollment-box .continbox {
  border-bottom: 1px solid #bcbcbc;
  padding-left: 30px;
  padding-right: 20px;
  padding-top: 20px;
}

.console-pages .enrollment-box .continbox .margintopdiv {
  margin-top: -70px;
}

.console-pages .enrollment-box .continbox .imgchild {
  border-radius: 50%;
  border: 2px solid #fff;
  margin-bottom: 15px;
}

.console-pages .enrollment-box .continbox h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #393939;
  margin-bottom: 5px;
}

.console-pages .enrollment-box .continbox .skills {
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.console-pages .enrollment-box .continbox .skills p {
  margin: 0;
  display: inline-block;
  margin-right: 17px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #606060;
}

.console-pages .enrollment-box .continbox .skills p img {
  margin-right: 7px;
}

.console-pages .enrollment-box .continbox h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: #393939;
  margin-top: 4px;
}

.console-pages .enrollment-box .continbox p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color: #606060;
  margin: 0;
  margin-top: 4px;
}

.console-pages .enrollment-box .continbox p a {
  color: #7ea1ff;
  border: 1.5px solid #7ea1ff;
  padding: 7px 13px;
  border-radius: 17px;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.console-pages .enrollment-box .continbox p a:hover {
  color: #fff;
  background: #7ea1ff;
  text-decoration: none;
}

.console-pages .enrollment-box .continbox:last-child {
  border: 0;
}

@media (min-width: 992px) {
  .console-pages .money-box {
    margin-top: 60px;
  }
}

.console-pages .money-box .money-box-inner {
  width: 100%;
  background: #fff;
  border-radius: 7px;
  -webkit-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  margin-bottom: 18px;
}

.console-pages .money-box .money-box-inner h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  color: #afafaf;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 0px 10px 20px;
  margin: 0;
}

.console-pages .money-box .money-box-inner h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 30px;
  line-height: 41px;
  color: #000000;
  padding: 30px 0px 30px 0px;
  text-align: center;
  margin: 0;
}

/*************************************************
	        @MEDIA(MAX-WIDTH: 772PX)
*************************************************/
@media (max-width: 772px) {
  .console-pages .applications_view_page .cont .box2 a {
    margin-left: 20px;
    margin-right: 0;
  }

  .console-pages .interested_programs .cont .boxes .box .continbox {
    padding-right: 0;
  }

  .console-pages .new_application_3 .cont .contbox .body .rightside {
    text-align: left;
    margin-top: 40px;
  }

  .console-pages .new_application_3 .buttons {
    text-align: left;
  }

  .console-pages .my_enrollments .cont .boxes .box .continbox {
    padding-right: 0;
  }

  .console-pages .my_enrollments .cont .boxes .box .continbox {
    margin: 0;
  }

  .console-pages .educator .hero .tablebox2 .tablebody .boxleft,
  .console-pages .hero .tablebox2 .tablebody .boxright {
    float: none;
  }

  .console-pages .admin .pricingplan .cont .boxes .box {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .console-pages .my_earning .tablebox .tableheader .select {
    text-align: left;
    margin-top: 5px;
    position: relative;
    max-width: 138px;
  }
}

/*************************************************
	        @MEDIA(MAX-WIDTH: 1400PX)
*************************************************/
@media (max-width: 1400px) {
  .console-pages .completeline .complete {
    width: calc(80% - 120px);
  }

  .console-pages .completeline {
    padding-left: 20px;
  }

  .console-pages .marks {
    margin-top: 0px;
    max-width: 100%;
    margin-left: -6px;
  }

  .console-pages .messagecenter .rightside {
    display: none;
  }
}

/*************************************************
			@MEDIA(MAX-WIDTH: 1500PX)
			@MEDIA(MIN-WIDTH: 1500PX)
*************************************************/
@media (min-width: 1500px) {
  .console-pages .topbar {
    padding-right: calc(3rem - 10px);
  }
}

@media (max-width: 1500px) {
  .console-pages .my_earning .cont .boxes .box {
    max-width: 240px !important;
  }

  .console-pages .my_earning .cont .boxes .box .info h3 {
    font-size: 32px !important;
  }
}

/*************************************************
	        @MEDIA(MAX-WIDTH: 1725PX)
*************************************************/
@media (max-width: 1725px) {
  .console-pages .tablebox .tablebody p {
    font-size: 11px;
  }
}

/*************************************************
	        @MEDIA(MAX-WIDTH: 1560PX)
*************************************************/
@media (max-width: 1560px) {
  .console-pages .tablebox .tablebody p {
    font-size: 11px;
  }
}

/*************************************************
	        @MEDIA(MAX-WIDTH: 1450PX)
*************************************************/
@media (max-width: 1450px) {
  .console-pages .tablebox .tablebody p {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 11px;
    line-height: 22px;
    color: #7e7e7e;
    text-align: left;
    margin: 0;
  }

  .console-pages .tablebox {
    max-width: 870px;
  }

  .console-pages .buttons {
    text-align: left;
  }

  .console-pages .buttons .savebtn,
  .console-pages .buttons .nextbtn {
    margin-left: 6px;
    margin-right: 0px;
  }

  .console-pages .cont .formbody h3 {
    font-size: 11px;
  }

  .console-pages .cont .box .boxofprfilephotos {
    padding: 25px 25px 0;
  }

  .console-pages .applications_view_page .cont .box,
  .console-pages .applications_view_page .cont .box2 {
    position: relative;
    display: block;
    width: 100%;
    max-width: 100%;
  }

  .console-pages .applications_view_page .cont .box .bgofbox,
  .console-pages .applications_view_page .cont .box2 .bgofbox {
    max-width: 500px;
    width: 100%;
  }

  .console-pages .applications_view_page h1 {
    margin-bottom: 0;
  }
}

/*************************************************
	        @MEDIA(MAX-WIDTH: 992PX)
*************************************************/
@media (max-width: 992px) {
  .console-pages .tablebox .tablebody h3 {
    text-align: center;
  }

  .divcenterspan {
    display: flex;
    justify-content: center;
  }
  .console-pages .tablebox .tablebody p {
    text-align: center;
  }

  .console-pages .rightside {
    margin: auto;
  }

  .console-pages .tablebox .tablebody a {
    padding: 10px 0;
    border-bottom: 1px solid #e5e5e5;
  }

  .console-pages .tablebox .tablebody a:last-child {
    border-bottom: 0;
  }

  .console-pages .tablebox .leftlist li {
    display: inline-block;
    width: auto;
    text-align: center;
  }

  .console-pages .cont .contbox .body .boxes .carousel-control-prev {
    left: -5px;
  }

  .console-pages .cont .contbox .body .boxes .carousel-control-next {
    right: -10px;
  }

  .console-pages .new_application_2 .cont .contbox .body .boxes {
    text-align: center;
  }

  .console-pages .new_application_5 .cont .contbox_5 {
    padding: 40px 20px;
  }

  .console-pages .new_application_5 .cont .contbox_5 h1 {
    font-size: 28px;
    line-height: 42px;
    margin-top: 30px;
  }

  .console-pages .new_application_5 .cont .contbox_5 h2 {
    margin-bottom: 50px;
    font-size: 23px;
    line-height: 32px;
  }

  .console-pages .new_application_5 h1 {
    margin-bottom: 50px;
  }

  .console-pages .admin .cont .search .input input {
    margin-bottom: 15px;
  }

  .console-pages .admin .cont .search {
    text-align: left;
  }

  .console-pages .admin .cont .search .input {
    display: inline-block;
  }
}

/*************************************************
	        @MEDIA(MAX-WIDTH: 767PX)
*************************************************/
@media (max-width: 767px) {
  .console-pages .hours {
    margin-top: 10px;
  }

  .console-pages .tablebox .tableheader h2 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 27px;
  }

  .console-pages #wrapper #content-wrapper #content {
    min-width: 320px;
  }

  .console-pages .hero .tablebox2 .leftlist {
    text-align: center;
    margin-top: 20px;
  }

  .console-pages .hero .tablebox .tableheader .select select {
    background: #fff;
  }

  .console-pages .hero .tablebox .tableheader .select {
    position: relative;
    text-align: center;
    max-width: 140px;
    margin: auto;
    margin-top: 20px;
  }

  .console-pages .topbar .dropdown .dropdownsearch {
    background: #fff;
  }

  .console-pages .dropdownsearch {
    max-width: 300px !important;
    width: 100% !important;
    min-width: 240px;
  }

  .console-pages .input-group > .input-group-append > .btn {
    padding: 2px 10px !important;
  }

  .console-pages .searchdesktop {
    display: none !important;
  }
}

/*************************************************
	        @MEDIA(MAX-WIDTH: 600PX)
*************************************************/
@media (max-width: 600px) {
  .console-pages .hero .tablebox2 .tablebody .name h2 {
    display: table;
    position: relative;
    margin: auto;
    padding: 13px 35px;
    margin: auto;
    left: 0;
    right: 0;
  }

  .console-pages .hero .tablebox2 .tablebody .boxleft,
  .console-pages .hero .tablebox2 .tablebody .boxright {
    width: 100%;
  }

  .console-pages .hero .tablebox2 .tablebody .boxleft,
  .console-pages .hero .tablebox2 .tablebody .boxright {
    margin-bottom: 0;
  }

  .console-pages .hero .tablebox2 .tablebody .boxleft {
    margin-top: 10px;
  }

  .console-pages .hero .tablebox2 .tablebody .boxright {
    margin-bottom: 10px;
  }

  .console-pages .hero .tablebox2 .tablebody .name img {
    margin: auto;
    display: block;
  }

  .console-pages .hero .tablebox2 .tablebody .name h2 {
    font-size: 17px;
  }
}

/*************************************************
	        @MEDIA(MAX-WIDTH: 572PX)
*************************************************/
@media (max-width: 572px) {
  .console-pages .topbar .dropdown .dropdown-menu {
    right: 0 !important;
    margin: 0 auto;
    width: 350px !important;
    overflow: inherit;
  }

  .console-pages .topbar .dropdown .dropdown-menu .arrowtop {
    visibility: visible;
  }
}

/*************************************************
	        @MEDIA(MAX-WIDTH: 550PX)
*************************************************/
@media (max-width: 550px) {
  .console-pages
    .tuition_payments
    .cont
    .boxpaid
    .box
    .body
    .tablediv
    .table
    thead
    th,
  .console-pages
    .tuition_payments
    .cont
    .boxpaid
    .box
    .body
    .tablediv
    .table
    tbody
    td,
  .console-pages
    .tuition_payments
    .cont
    .boxpaid
    .box
    .body
    .tablediv
    .table
    tbody
    th {
    padding-left: 20px;
  }

  .console-pages .tuition_payments .cont .boxpaid .box .header .paid {
    right: 6px;
  }

  .console-pages .tuition_payments .cont h3 {
    margin-left: 20px;
  }
}

/*************************************************
	        @MEDIA(MAX-WIDTH: 1100PX)
*************************************************/
@media (max-width: 1100px) {
  .console-pages .cont .formbody h3 {
    font-size: 12px;
  }
}

/*************************************************
	        @MEDIA(MAX-WIDTH: 375PX)
*************************************************/
@media (max-width: 375px) {
  .console-pages .new_application .cont .contbox .body p {
    font-size: 11px;
  }

  .console-pages .new_application .cont .contbox .body i {
    font-size: 11px;
  }

  .console-pages .new_application .cont .contbox .header h2 {
    font-size: 25px;
    line-height: 36px;
  }

  .console-pages .new_application .cont .contbox .header p {
    font-size: 16px;
    line-height: 21px;
  }
}

/*************************************************
	        @MEDIA(MAX-WIDTH: 410PX)
*************************************************/
@media (max-width: 410px) {
  .console-pages .new_application_3 .buttons .savebtn,
  .console-pages .new_application_3 .buttons .nextbtn {
    font-size: 14px;
    padding: 8px 8px;
  }

  .console-pages .new_application_3 .buttons .nextbtn {
    margin: 0;
  }
}

/*************************************************
	        @MEDIA(MAX-WIDTH: 500PX)
*************************************************/
@media (max-width: 500px) {
  .console-pages .new_application_5 .cont .contbox_5 h1 {
    font-size: 25px;
  }

  .console-pages .cont .data p {
    font-size: 15px;
  }

  .console-pages .cont .marks {
    margin: auto;
    margin-top: 5px;
  }

  .console-pages .cont .data {
    text-align: center;
  }
}

/*************************************************
	        @MEDIA(MAX-WIDTH: 350PX)
*************************************************/
@media (max-width: 350px) {
  .console-pages .tuition_payments .cont .boxpaid .box .body .info p {
    font-style: 12px;
  }

  .console-pages
    .tuition_payments
    .cont
    .boxpaid
    .box
    .body
    .tablediv
    .table
    thead
    th {
    font-style: 11px;
  }

  .console-pages
    .tuition_payments
    .cont
    .boxpaid
    .box
    .body
    .tablediv
    .table
    tbody
    td,
  .console-pages
    .tuition_payments
    .cont
    .boxpaid
    .box
    .body
    .tablediv
    .table
    tbody
    th {
    font-style: 11px;
  }

  .console-pages .tuition_payments .cont h3 {
    font-size: 17px;
  }
}

/*************************************************
	        @MEDIA(MAX-WIDTH: 450PX)
*************************************************/
@media (max-width: 450px) {
  .console-pages .my_enrollments .cont .boxes .box .continbox {
    padding-left: 20px;
  }

  .console-pages .my_enrollments .cont .boxes .box .continbox p {
    font-size: 12px;
    line-height: 24px;
  }

  .console-pages .my_enrollments .cont .boxes .box .continbox h2 {
    font-size: 13px;
    line-height: 24px;
  }
}

.console-pages .logoutbtn {
  padding: 0 1rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.console-pages .logoutbtn button {
  width: 100%;
  display: block;
  font-weight: 400;
  color: #f0f0f0;
  font-size: 13px;
  line-height: 18px;
  font-family: "Open Sans", sans-serif;
  padding: 10px 0px;
  border: 1px solid #fff;
  border-radius: 5px;
  text-align: center;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
  margin-bottom: 10px;
  background: transparent;
}

.console-pages .logoutbtn button:hover {
  text-decoration: none;
  background: #fff;
  color: #8e61ff;
}

.console-pages .logoutbtn img {
  margin-bottom: 10px;
  max-width: 100%;
}

.console-pages .topbar .topbar-divider {
  width: 0;
  border-right: 1px solid #e3e6f0;
  height: 35px !important;
  margin: auto 0.5rem !important;
}

.console-pages .topbar.navbar-light .navbar-nav .nav-item .nav-link img {
  width: 18px;
}
.console-pages .topbar.navbar-light .navbar-nav .nav-item .nav-linkpartner img {
  width: 18px;
}
.console-pages .topbar {
  z-index: 2;
  height: 55px !important;
}

.console-pages .topbar .nav-item .nav-link {
  height: 55px !important;
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
}
.console-pages .topbar .nav-item .nav-linkpartner {
  height: 55px !important;
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
}

.console-pages .topbar .mr-2,
.console-pages .topbar .mx-2 {
  margin-right: 0rem !important;
}

.console-pages .topbar .img-profile {
  margin-right: 20px;
  height: 24px !important;
  width: 24px !important;
}

.console-pages .topbar .nameofc span {
  color: #6c6c6c;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.console-pages .topbar .bluecircle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid #fff;
  background: #6cc1ff;
  position: absolute;
  margin-top: 11px;
  margin-left: 27px;
}

.console-pages .topbar .bluecircletop {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #6cc1ff;
  position: absolute;
  margin-top: -9px;
  margin-left: 20px;
}

.console-pages .topbar .dropdown .dropdown-menu {
  background: transparent;
  box-shadow: none !important;
}

.console-pages .topbar .dropdown .dropdown-menu .arrowtop {
  margin: auto;
  display: block;
}

.console-pages .topbar .dropdown .dropdown-menu .boxofit {
  background: #fff;
  -webkit-box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.16);
}

.console-pages .topbar .dropdown-list .dropdown-header {
  background: #fff !important;
  color: #434343 !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 400 !important;
  font-size: 17px !important;
  line-height: 15px !important;
  border: 0 !important;
  text-transform: unset !important;
  padding: 25px 30px !important;
  text-transform: capitalize !important;
}

.console-pages .topbar .dropdown-list .dropdown-item {
  border: 0;
  border-top: 1px solid #eaeaea !important;
}

.console-pages .recent {
  text-align: right;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: #7ea1ff;
}

.console-pages .recent .pbno {
  position: absolute;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #7ea1ff;
  right: 64px;
  margin-top: -2px;
}

.console-pages .titleofnot {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #434343;
  margin: 0;
  padding-left: 10px;
}

.console-pages .descofnot {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #939393;
  margin: 0;
  padding-left: 10px;
}

.console-pages .topbar .dropdown-list .dropdown-item {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.console-pages .hours {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 9px;
  line-height: 15px;
  color: #747474;
  margin: 0;
  position: absolute;
  right: 16px;
}

.console-pages .clicked {
  background: #f7f8ff;
}

.console-pages .clicked img {
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: 0.8;
}

.console-pages .topbar .dropdown-list .clicked:active {
  background: #f7f8ff !important;
  opacity: 1 !important;
}

.console-pages #content {
  position: relative;
}

.console-pages
  .calendarcf
  .boxofcalendar
  .calendar
  .nom
  .boxwidthcal
  .orangeactivebgofno {
  background: #f5c68c;
  background: -moz-linear-gradient(left, #f5c68c 0%, #fd84c0 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #f5c68c),
    color-stop(100%, #fd84c0)
  );
  background: -webkit-linear-gradient(left, #f5c68c 0%, #fd84c0 100%);
  background: -o-linear-gradient(left, #f5c68c 0%, #fd84c0 100%);
  background: -ms-linear-gradient(left, #f5c68c 0%, #fd84c0 100%);
  background: linear-gradient(to right, #f5c68c 0%, #fd84c0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5c68c', endColorstr='#fd84c0', GradientType=1 );
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: absolute;
  right: 12px;
  top: 13px;
}

.console-pages
  .calendarcf
  .boxofcalendar
  .calendar
  .nom
  .boxwidthcal
  .greenactivebgofno {
  background: #00eaea;
  background: -moz-linear-gradient(left, #00eaea 0%, #a3eb3e 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #00eaea),
    color-stop(100%, #a3eb3e)
  );
  background: -webkit-linear-gradient(left, #00eaea 0%, #a3eb3e 100%);
  background: -o-linear-gradient(left, #00eaea 0%, #a3eb3e 100%);
  background: -ms-linear-gradient(left, #00eaea 0%, #a3eb3e 100%);
  background: linear-gradient(to right, #00eaea 0%, #a3eb3e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00eaea', endColorstr='#a3eb3e', GradientType=1 );
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: absolute;
  right: 12px;
  top: 13px;
}

.console-pages .calendarcf .boxofcalendar .calendar .nom .blueline {
  background: #ac93ff;
  background: -moz-linear-gradient(left, #ac93ff 0%, #98dfe8 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #ac93ff),
    color-stop(100%, #98dfe8)
  );
  background: -webkit-linear-gradient(left, #ac93ff 0%, #98dfe8 100%);
  background: -o-linear-gradient(left, #ac93ff 0%, #98dfe8 100%);
  background: -ms-linear-gradient(left, #ac93ff 0%, #98dfe8 100%);
  background: linear-gradient(to right, #ac93ff 0%, #98dfe8 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#AD93FF', endColorstr='#98dfe8', GradientType=1 );
}

.console-pages .calendarcf .boxofcalendar .calendar .nom .orangeline {
  background: #f5c68c;
  background: -moz-linear-gradient(left, #f5c68c 0%, #fd84c0 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #f5c68c),
    color-stop(100%, #fd84c0)
  );
  background: -webkit-linear-gradient(left, #f5c68c 0%, #fd84c0 100%);
  background: -o-linear-gradient(left, #f5c68c 0%, #fd84c0 100%);
  background: -ms-linear-gradient(left, #f5c68c 0%, #fd84c0 100%);
  background: linear-gradient(to right, #f5c68c 0%, #fd84c0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5c68c', endColorstr='#fd84c0', GradientType=1 );
}

.console-pages .calendarcf .boxofcalendar .calendar .nom .greenline {
  background: #00eaea;
  background: -moz-linear-gradient(left, #00eaea 0%, #a3eb3e 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #00eaea),
    color-stop(100%, #a3eb3e)
  );
  background: -webkit-linear-gradient(left, #00eaea 0%, #a3eb3e 100%);
  background: -o-linear-gradient(left, #00eaea 0%, #a3eb3e 100%);
  background: -ms-linear-gradient(left, #00eaea 0%, #a3eb3e 100%);
  background: linear-gradient(to right, #00eaea 0%, #a3eb3e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00eaea', endColorstr='#a3eb3e', GradientType=1 );
}

.console-pages
  .calendarcf
  .boxofcalendar
  .calendar
  .nom
  .lineinc
  .hoverline
  .colour
  .coln {
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  right: 5px;
  top: 5px;
  background: #f5c68c;
  background: -moz-linear-gradient(left, #f5c68c 0%, #fd84c0 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #f5c68c),
    color-stop(100%, #fd84c0)
  );
  background: -webkit-linear-gradient(left, #f5c68c 0%, #fd84c0 100%);
  background: -o-linear-gradient(left, #f5c68c 0%, #fd84c0 100%);
  background: -ms-linear-gradient(left, #f5c68c 0%, #fd84c0 100%);
  background: linear-gradient(to right, #f5c68c 0%, #fd84c0 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f5c68c', endColorstr='#fd84c0', GradientType=1 );
}

.console-pages .calendarcf {
  overflow-y: hidden;
  background-color: #ffffff;
}

.console-pages .calendarcf .boxofcalendar {
  width: calc(100% - 420px);
  padding: 30px;
}

.console-pages .calendarcf .upcomingeventslist {
  background: #ffffff;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 420px;
  border-left: 2px solid #e3e3e3;
  z-index: 0;
  padding-top: 70px;
}

.console-pages .calendarcf .toast-container {
  height: calc(100vh - 140px);
}

.console-pages .calendarcf .cal-menu {
  height: 68px;
  line-height: 68px;
  border-bottom: 2px solid #e3e3e3;
  background-color: #ffffff;
}

@media (max-width: 800px) {
  .console-pages .calendarcf .boxofcalendar {
    width: 100%;
  }
  .console-pages .calendarcf .upcomingeventslist {
    width: 100%;
    position: relative;
    /* background-color: #ffffff !important; */
    background-color: #eceffa;
    height: auto !important;
    padding-top: 0;
    border-top: 2px solid #e3e3e3;
    border-left: none;
  }
  .console-pages .calendarcf .toast-container {
    height: auto;
  }
}

.console-pages .calendarcf .upcomingeventslist .boxofevent {
  padding-top: 25px;
  padding-bottom: 25px;
  border-top: 1px solid #d6d6d6;
}

.console-pages .calendarcf .upcomingeventslist .boxofevent .col-4 {
  padding-left: 50px;
}

.console-pages .calendarcf .upcomingeventslist .boxofevent h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #042470;
  margin: 0;
  padding: 0;
}

.console-pages .calendarcf .upcomingeventslist .boxofevent .hours2 {
  margin: 0;
  margin-top: 10px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #404040;
  margin-left: 0;
}

.console-pages .calendarcf .upcomingeventslist .boxofevent h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  margin: 0;
  padding: 0;
  max-width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.console-pages .calendarcf .upcomingeventslist .boxofevent .desc {
  margin-top: 10px;
  padding-left: 25px;
}

.console-pages .calendarcf .upcomingeventslist .boxofevent .desc p {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #969696;
}

.console-pages .calendarcf .upcomingeventslist .boxofevent .desc img {
  margin-right: 5px;
}

.console-pages .calendarcf .upcomingeventslist .boxofevent:last-child {
  border-bottom: 1px solid #d6d6d6;
}

.console-pages .mychildhero {
  padding-left: 0px;
  padding-right: 0;
}

.console-pages .mychildhero .cont {
  padding-left: 3rem;
}

.console-pages .mychildhero .cont .lineofcont {
  display: block;
  width: 100%;
}

.console-pages .mychildhero .cont .box {
  margin-top: 50px;
  min-height: 500px;
  background: #fff;
  border-radius: 8px;
  max-width: 440px;
  width: 100%;
  margin-bottom: 0px;
  margin-right: 25px;
  display: inline-block;
  -webkit-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
}

.console-pages .mychildhero .cont .box .header {
  border-bottom: 1px solid #e5e5e5;
}

.console-pages .mychildhero .cont .box h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #000000;
  padding: 20px 0px 0px 25px;
}

.console-pages .mychildhero .cont .formbody {
  padding-bottom: 30px;
}

.console-pages .mychildhero .cont .formbody h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #000000;
  padding: 30px 0px 0px 25px;
}

.console-pages .mychildhero .cont form input {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #696969;
  border: 0;
  border-bottom: 1px solid #cbcbcb;
  padding: 10px 2px;
  margin-top: 20px;
  width: calc(100% - 35px);
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.console-pages .mychildhero .cont form input:focus {
  outline: none;
  border-bottom-color: #7ea1ff;
}

.console-pages .mychildhero .buttons {
  text-align: right;
  margin-bottom: 50px;
  margin-top: 75px;
}

.console-pages .mychildhero .buttons .savebtn {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  color: #ffffff;
  display: inline-block;
  background: #7ea1ff;
  border: 1px solid #7ea1ff;
  padding: 11px 18px;
  border-radius: 5px;
  margin-right: 6px;
}

.console-pages .mychildhero .buttons .nextbtn {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  color: #7ea1ff;
  display: inline-block;
  background: transparent;
  border: 1px solid #7ea1ff;
  padding: 11px 18px;
  border-radius: 5px;
  margin-right: 6px;
}

.console-pages .mychildhero .buttons a:hover {
  text-decoration: none;
  opacity: 0.7;
}

.console-pages .buttons .savebtn {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  color: #ffffff;
  display: inline-block;
  background: #7ea1ff;
  border: 1px solid #7ea1ff;
  padding: 8px 11px;
  border-radius: 5px;
  margin-left: 6px;
}

.console-pages .buttons .savebtn:disabled {
  background: #a5b9f1 !important;
  border: 1px solid #a5bdff !important;
  cursor: not-allowed;
}

.console-pages .buttons .cancelbtn {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  color: #ffffff;
  display: inline-block;
  background: #ff7e7e;
  border: 1px solid #ff7e7e;
  padding: 8px 11px;
  border-radius: 5px;
  margin-left: 6px;
}

.console-pages .buttons .cancelbtn:disabled {
  background: #f1a5a5;
  border: 1px solid #ffa5a5;
}

.console-pages .buttons .nextbtn {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  color: #7ea1ff;
  display: inline-block;
  background: transparent;
  border: 1px solid #7ea1ff;
  padding: 11px 18px;
  border-radius: 5px;
  margin-right: 6px;
}

.console-pages .buttons .submitbtn {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  color: white;
  display: inline-block;
  background: #7ea1ff;
  border: 1px solid #7ea1ff;
  padding: 8px 11px;
  border-radius: 5px;
  margin-left: 6px;
}

.console-pages .buttons .submitNonCompletebtn {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  color: white;
  display: inline-block;
  background: #dcdcdc;
  border: 1px solid #dcdcdc;
  padding: 8px 11px;
  border-radius: 5px;
  margin-left: 6px;
}

.console-pages .buttons .deletebtn {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  color: #dc3545;
  display: inline-block;
  background: transparent;
  border: 1px solid #dc3545;
  padding: 8px 12px;
  border-radius: 5px;
  margin-right: 6px;
}

.console-pages .buttons a:hover {
  text-decoration: none;
  opacity: 0.7;
}

.console-pages .buttons button:hover {
  text-decoration: none;
  opacity: 0.7;
}

.console-pages .applications_view_page {
  padding-left: 0px;
  padding-right: 0;
}

.console-pages .applications_view_page .cont {
  max-width: 2000px;
}

.console-pages .applications_view_page .cont .lineofcont {
  display: block;
  width: 100%;
}

.console-pages .applications_view_page .cont .box {
  margin-top: 50px;
  max-width: calc(30% - 25px);
  width: 100%;
  margin-bottom: 0px;
  margin-right: 25px;
  display: inline-block;
}

.console-pages .applications_view_page .cont .box .bgofbox {
  -webkit-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  background: #fff;
  border-radius: 8px;
}

.console-pages .applications_view_page .cont .box .header {
  border-bottom: 1px solid #e5e5e5;
}

.console-pages .applications_view_page .cont .box h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #000000;
  padding: 20px 0px 0px 25px;
}

.console-pages .applications_view_page .cont .box a {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 17px;
  color: #a2a2a2;
  padding: 20px 0px 16px 0;
  margin-right: 50px;
  display: inline-block;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.console-pages .applications_view_page .cont .box a:hover {
  text-decoration: none;
  opacity: 0.7;
}

.console-pages .applications_view_page .cont .box .active {
  border-bottom: #7ea1ff 4px solid;
  color: #000;
}

.console-pages .applications_view_page .cont .boxwithoutmenu h2 {
  padding-bottom: 12px;
}

.console-pages .applications_view_page .cont .box2 {
  margin-top: 50px;
  min-height: 500px;
  max-width: calc(40% - 25px);
  width: 100%;
  margin-bottom: 0px;
  margin-right: 25px;
  display: inline-block;
}

.console-pages .applications_view_page .cont .box2 .bgofbox {
  -webkit-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  background: #fff;
  border-radius: 8px;
}

.console-pages .applications_view_page .cont .box2 .header {
  border-bottom: 1px solid #e5e5e5;
}

.console-pages .applications_view_page .cont .box2 h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #000000;
  padding: 20px 0px 0px 25px;
}

.console-pages .applications_view_page .cont .box2 a {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 17px;
  color: #a2a2a2;
  padding: 20px 0px 16px 0;
  margin-right: 20px;
  display: inline-block;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.console-pages .applications_view_page .cont .box2 a:hover {
  text-decoration: none;
  opacity: 0.7;
}

.console-pages .applications_view_page .cont .box2 .active {
  border-bottom: #7ea1ff 4px solid;
  color: #000;
}

.console-pages .applications_view_page .cont .formbody {
  padding-bottom: 30px;
}

.console-pages .applications_view_page .cont .formbody h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #000000;
  padding: 30px 0px 0px 25px;
}

.console-pages .applications_view_page .cont form input {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #696969;
  border: 0;
  border-bottom: 1px solid #cbcbcb;
  padding: 10px 2px;
  margin-top: 20px;
  width: calc(100% - 35px);
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.console-pages .applications_view_page .cont form input:focus {
  outline: none;
  border-bottom-color: #7ea1ff;
}

.console-pages .applications_view_page .buttons {
  text-align: right;
  margin-bottom: 50px;
  margin-top: 75px;
}

.console-pages .applications_view_page .buttons .savebtn {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  color: #ffffff;
  display: inline-block;
  background: #7ea1ff;
  border: 1px solid #7ea1ff;
  padding: 11px 18px;
  border-radius: 5px;
  margin-right: 6px;
}

.console-pages .applications_view_page .buttons .nextbtn {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  color: #7ea1ff;
  display: inline-block;
  background: transparent;
  border: 1px solid #7ea1ff;
  padding: 11px 18px;
  border-radius: 5px;
  margin-right: 6px;
}

.console-pages .applications_view_page .buttons a:hover {
  text-decoration: none;
  opacity: 0.7;
}

.console-pages .cont .contbox .header {
  margin-bottom: 60px;
}

.console-pages .cont .contbox .header h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  color: #000000;
  margin: 0;
}

.console-pages .cont .contbox .header p {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
  color: #484848;
  margin: 0;
  margin-top: 5px;
}

.console-pages .cont .contbox .body input {
  width: 100%;
  padding: 8px 30px 8px 15px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #7e7e7e;
  border: 1px solid #969696;
}

.console-pages .cont .contbox .body input:focus {
  outline: none;
}

.console-pages .cont .contbox .body p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #7e7e7e;
  padding-top: 9px;
}

.console-pages .cont .contbox .body .inputdiv {
  position: relative;
}

.console-pages .cont .contbox .body .inputdiv img {
  position: absolute;
  right: 15px;
  top: 11px;
}

.console-pages .cont .contbox .body .boxes {
  margin-left: -10px;
  margin-top: 25px;
  text-align: center;
}

.console-pages .cont .contbox .body .boxes .box {
  max-width: 209px;
  margin-right: 10px;
  margin-top: 10px;
  width: 100%;
  padding: 8px;
  -webkit-box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  margin-left: 10px;
  display: inline-block;
}

.console-pages .cont .contbox .body .boxes .box img {
  max-width: 100%;
}

.console-pages .cont .contbox .body .boxes .box h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #393939;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 50px;
}

.console-pages .cont .contbox .body .boxes .box a {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #707070;
  padding: 5px 15px;
  border: 1px solid #707070;
  border-radius: 17px;
  text-align: center;
  margin: auto;
  display: table;
  margin-bottom: 10px;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.console-pages .cont .contbox .body .boxes .box a svg {
  margin-right: 5px;
  margin-top: -3px;
  stroke: #707070;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.console-pages .cont .contbox .body .boxes .box a:hover {
  background: #7ea1ff;
  border-color: #7ea1ff;
  color: #fff;
  text-decoration: none;
}

.console-pages .cont .contbox .body .boxes .box a:hover svg {
  stroke: #fff;
}

.console-pages .cont .contbox .body .boxes .box .selected {
  background: #7ea1ff;
  border-color: #7ea1ff;
  color: #fff;
  text-decoration: none;
}

.console-pages .cont .contbox .body .boxes .box .selected svg {
  stroke: #fff;
}

.console-pages .cont .contbox .body .boxes .box:last-child {
  margin-right: 0;
}

.console-pages .cont .contbox .body .boxes .carousel-control-prev {
  left: -35px;
  opacity: 1;
  width: 30px;
}

.console-pages .cont .contbox .body .boxes .carousel-control-next {
  right: -40px;
  opacity: 1;
  width: 30px;
}

.console-pages .buttons {
  text-align: right;
  margin-bottom: 30px;
  margin-top: 30px;
}

.console-pages .buttons .nextbtn {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 23px;
  color: #7ea1ff;
  display: inline-block;
  background: transparent;
  border: 1px solid #7ea1ff;
  padding: 8px 11px;
  border-radius: 5px;
  margin-left: 6px;
}

.console-pages .buttons a:hover {
  text-decoration: none;
  opacity: 0.7;
}

.console-pages .new_application_2 .cont .contbox .body .boxes {
  text-align: left;
}

.console-pages .new_application_2 .box .divforimg {
  min-height: 145px;
  position: relative;
}

.console-pages .new_application_2 .box .divforimg img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 15px;
  max-width: 100px;
  max-height: 100px;
  margin: auto;
}

.console-pages .new_application_3 .cont .contbox .body h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 25px;
}

.console-pages .new_application_3 .cont .contbox .body .boxwithcroll {
  /* max-width: 430px; */
  width: 100%;
  height: 410px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid #d9d9d9;
}

.console-pages .new_application_3 .cont .contbox .body .boxwithcroll h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #969696;
  background: #f2f2f2;
  padding: 10px 15px;
  margin: 0;
  margin-top: -1px;
}

.console-pages .new_application_3 .cont .contbox .body .boxwithcroll .controw {
  border-bottom: 1px solid #d6d6d6;
  padding: 15px;
}

.console-pages
  .new_application_3
  .cont
  .contbox
  .body
  .boxwithcroll
  .controw
  h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #393939;
  margin-bottom: 8px;
}

.console-pages
  .new_application_3
  .cont
  .contbox
  .body
  .boxwithcroll
  .controw
  p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #393939;
  margin-bottom: 0px;
}

.console-pages
  .new_application_3
  .cont
  .contbox
  .body
  .boxwithcroll
  .controw
  p
  span {
  color: #7ea1ff;
}

.console-pages
  .new_application_3
  .cont
  .contbox
  .body
  .boxwithcroll
  .controw
  .parent {
  position: relative;
}

.console-pages
  .new_application_3
  .cont
  .contbox
  .body
  .boxwithcroll
  .controw
  .selectdiv {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  left: 0;
}

.console-pages
  .new_application_3
  .cont
  .contbox
  .body
  .boxwithcroll
  .controw
  .selectdiv
  a {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #707070;
  padding: 5px 15px;
  border: 1px solid #707070;
  border-radius: 17px;
  text-align: center;
  margin: auto;
  display: table;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.console-pages
  .new_application_3
  .cont
  .contbox
  .body
  .boxwithcroll
  .controw
  .selectdiv
  a
  svg {
  margin-right: 5px;
  margin-top: -3px;
  stroke: #707070;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.console-pages
  .new_application_3
  .cont
  .contbox
  .body
  .boxwithcroll
  .controw
  .selectdiv
  a:hover {
  background: #7ea1ff;
  border-color: #7ea1ff;
  color: #fff;
  text-decoration: none;
}

.console-pages
  .new_application_3
  .cont
  .contbox
  .body
  .boxwithcroll
  .controw
  .selectdiv
  a:hover
  svg {
  stroke: #fff;
}

.console-pages
  .new_application_3
  .cont
  .contbox
  .body
  .boxwithcroll
  .controw
  .selectdiv
  .selected {
  background: #7ea1ff;
  border-color: #7ea1ff;
  color: #fff;
  text-decoration: none;
  padding: 5px 8px;
}

.console-pages
  .new_application_3
  .cont
  .contbox
  .body
  .boxwithcroll
  .controw
  .selectdiv
  .selected
  svg {
  stroke: #fff;
  margin-right: 1px;
}

.console-pages .new_application_3 .cont .contbox .body .rightside {
  text-align: right;
}

.console-pages .new_application_3 .cont .contbox .body .rightside .wraper {
  /* max-width: 400px; */
  text-align: left;
  display: inline-block;
  width: 100%;
}

.console-pages
  .new_application_3
  .cont
  .contbox
  .body
  .rightside
  .wraper
  .select {
  position: relative;
}

.console-pages
  .new_application_3
  .cont
  .contbox
  .body
  .rightside
  .wraper
  .select
  img {
  position: absolute;
  right: 15px;
  top: 18px;
  z-index: 50;
  cursor: normal;
}

.console-pages
  .new_application_3
  .cont
  .contbox
  .body
  .rightside
  .wraper
  .select
  select {
  width: 100%;
  background: transparent;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #777777;
  border-radius: 0;
  border: 1px solid #707070;
  padding: 8px 15px;
  position: relative;
  z-index: 1;
  margin-bottom: 25px;
}

.console-pages
  .new_application_3
  .cont
  .contbox
  .body
  .rightside
  .wraper
  .select
  select:focus {
  outline: none;
}

.console-pages .new_application_5 h1 {
  margin-bottom: 50px;
}

.console-pages .new_application_5 .cont {
  margin-bottom: 50px;
}

.console-pages .new_application_5 .cont .contbox_5 {
  max-width: 761px;
  width: 100%;
  margin: auto;
  background: #fff;
  padding: 70px 20px;
}

.console-pages .new_application_5 .cont .contbox_5 .imgdiv {
  text-align: center;
}

.console-pages .new_application_5 .cont .contbox_5 .imgdiv .img {
  position: relative;
  display: inline-block;
}

.console-pages .new_application_5 .cont .contbox_5 .imgdiv .img .check {
  position: absolute;
  bottom: 3px;
  right: 3px;
}

.console-pages .new_application_5 .cont .contbox_5 h1 {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 700 !important;
  font-size: 33px !important;
  line-height: 45px !important;
  color: #626262 !important;
  text-align: center !important;
  padding: 0;
  margin: 0;
  margin-top: 50px;
}

.console-pages .new_application_5 .cont .contbox_5 h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 25px;
  line-height: 34px;
  color: #868686;
  text-align: center;
  padding: 0;
  margin: 0;
  margin-bottom: 70px;
}

.console-pages .new_application_5 .cont .contbox_5 a {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #9d9d9d;
  text-align: center;
  padding: 0;
  text-decoration: underline;
  margin: 0;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.console-pages .new_application_5 .cont .contbox_5 a:hover {
  opacity: 0.7;
}

.console-pages .new_application_5 .cont .contbox_5 .btns {
  margin: 0;
  margin-top: 20px;
}

.console-pages .new_application_5 .cont .contbox_5 .btns a {
  text-decoration: none;
  background: #7ea1ff;
  color: #fff;
  border-radius: 5px;
  padding: 11px 25px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  display: inline-block;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.console-pages .tuition_payments {
  padding-left: 0px;
  padding-right: 0;
}

.console-pages .tuition_payments .cont {
  max-width: 2000px;
}

.console-pages .tuition_payments .cont .boxpaid .box {
  background: #fff;
  -webkit-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  max-width: 557px;
  width: 100%;
  display: inline-block;
  margin-bottom: 40px;
  margin-right: 40px;
}

.console-pages .tuition_payments .cont .boxpaid .box .header {
  background: #7ea0ff;
  background: -moz-linear-gradient(left, #7ea0ff 0%, #915aff 100%);
  background: -webkit-gradient(
    left top,
    right top,
    color-stop(0%, #7ea0ff),
    color-stop(100%, #915aff)
  );
  background: -webkit-linear-gradient(left, #7ea0ff 0%, #915aff 100%);
  background: -o-linear-gradient(left, #7ea0ff 0%, #915aff 100%);
  background: -ms-linear-gradient(left, #7ea0ff 0%, #915aff 100%);
  background: linear-gradient(to right, #7ea0ff 0%, #915aff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7ea0ff', endColorstr='#915aff', GradientType=1 );
  padding: 20px 45px;
  position: relative;
}

.console-pages .tuition_payments .cont .boxpaid .box .header h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  margin: 0;
  padding: 0;
}

.console-pages .tuition_payments .cont .boxpaid .box .header p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #e5e5e5;
  margin: 0;
  padding: 0;
}

.console-pages .tuition_payments .cont .boxpaid .box .header .paid {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 62px;
  color: #fff;
  display: block;
  width: 68px;
  height: 68px;
  text-align: center;
  border-radius: 50%;
  border: 3px solid #fff;
  background: #1bc88a;
  margin: 0;
  padding: 0;
  position: absolute;
  right: -34px;
  top: -34px;
}

.console-pages .tuition_payments .cont .boxpaid .box .header .pending {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 62px;
  color: #fff;
  display: block;
  width: 68px;
  height: 68px;
  text-align: center;
  border-radius: 50%;
  border: 3px solid #fff;
  background: #c85a1b;
  margin: 0;
  padding: 0;
  position: absolute;
  right: -34px;
  top: -34px;
}

.console-pages .tuition_payments .cont .boxpaid .box .header .unpaid {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 62px;
  color: #fff;
  display: block;
  width: 68px;
  height: 68px;
  text-align: center;
  border-radius: 50%;
  border: 3px solid #fff;
  background: #c81b1b;
  margin: 0;
  padding: 0;
  position: absolute;
  right: -34px;
  top: -34px;
}

.console-pages .tuition_payments .cont .boxpaid .box .body .info {
  margin: 20px 45px 45px;
}

.console-pages .tuition_payments .cont .boxpaid .box .body .info p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #646464;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

.console-pages .tuition_payments .cont .boxpaid .box .body .info p span {
  color: #525252;
}

.console-pages
  .tuition_payments
  .cont
  .boxpaid
  .box
  .body
  .tablediv
  .table
  thead
  th {
  border: 0;
  border-bottom: 1px solid #e5e5e5;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  padding-left: 45px;
}

.console-pages
  .tuition_payments
  .cont
  .boxpaid
  .box
  .body
  .tablediv
  .table
  tbody
  td,
.console-pages
  .tuition_payments
  .cont
  .boxpaid
  .box
  .body
  .tablediv
  .table
  tbody
  th {
  border: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #646464;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 45px;
}

.console-pages
  .tuition_payments
  .cont
  .boxpaid
  .box
  .body
  .tablediv
  .table
  tbody
  tr:first-child
  td,
.console-pages
  .tuition_payments
  .cont
  .boxpaid
  .box
  .body
  .tablediv
  .table
  tbody
  tr:first-child
  th {
  padding-top: 30px;
}

.console-pages .tuition_payments .cont h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #042470;
  margin: 0;
  padding: 0;
  margin-left: 45px;
  padding-bottom: 45px;
  margin-top: 50px;
}

.console-pages .tablebox2 .box {
  max-width: calc(50% - 18px);
  min-width: 280px;
  margin-left: 15px;
  background: #fff;
  border-radius: 9px;
  margin-bottom: 15px;
  display: inline-block;
  width: 100%;
  -webkit-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
}

.console-pages .tablebox2 .box h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #b1b1b1;
  padding: 14px 20px;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
}

.console-pages .tablebox2 .box h2 img {
  position: absolute;
  right: 15px;
  top: 10px;
}

.console-pages .tablebox2 .box .boxcon {
  padding: 36.2px 0;
}

.console-pages .tablebox2 .box .boxcon h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 55px;
  line-height: 75px;
  color: #000000;
  text-align: center;
}

.console-pages .tablebox2 .box .boxcon p {
  font-family: "Open Sans", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #7b7b7b;
  text-align: center;
}

.console-pages .tablebox2 .box .boxcon p span {
  font-style: 20px;
  color: #63abe0;
  margin-right: 8px;
}

.console-pages .credits .cont .col-lg-3-new .pricebox {
  margin-top: 0;
}

.console-pages .credits .cont .col-lg-3-new .pricebox .box h2 {
  padding-left: 15px;
}

.console-pages .credits .cont .col-lg-3-new .pricebox .box .boxbtns {
  text-align: center;
}

.console-pages .credits .cont .col-lg-3-new .pricebox .box .boxbtns a {
  display: inline-block;
  width: calc(50% - 20px);
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 12px;
  line-height: 17px;
  color: #ffffff;
  background: #7ea1ff;
  border: 1px solid #7ea1ff;
  padding: 10px 0;
  border-radius: 5px;
  margin-right: 3px;
  margin-left: 3px;
  margin-bottom: 15px;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}

.console-pages .credits .cont .col-lg-3-new .pricebox .box .boxbtns .chackout {
  background: transparent;
  color: #7ea1ff;
}

.console-pages .credits .cont .col-lg-3-new .pricebox .box .boxbtns a:hover {
  opacity: 0.7;
  text-decoration: none;
}

.console-pages .credits .cont .tablecont .table tbody td {
  padding: 35px 20px 35px 20px;
}

.console-pages .credits .cont .tablecont .table tbody td .par {
  margin: 0;
  margin-top: -10px;
  padding: 0;
}

.console-pages .credits .cont .tablecont .table tbody td:last-child {
  padding-left: 20px;
  padding-right: 20px;
}

.console-pages .credits .cont .tablecont .table thead th {
  padding: 20px 20px 20px 20px;
}

.console-pages .admin .pricingplan {
  padding-left: 0px;
  padding-right: 0;
}

.console-pages .admin .pricingplan .cont .boxes .addnew {
  max-width: 270px;
  display: inline-block;
  width: 100%;
  margin: 30px 15px 0;
  border: 3px dashed #d3d3d3;
  padding-top: 103.5px;
  padding-bottom: 103.5px;
  border-radius: 7px;
  background: none;
  vertical-align: top;
}

.console-pages .admin .pricingplan .cont .boxes .box {
  max-width: 270px;
  overflow: hidden;
  border-radius: 7px;
  background: #fff;
  margin-right: 30px;
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
  -webkit-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
}

.console-pages .admin .pricingplan .cont .boxes .box h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
}

.console-pages .admin .pricingplan .cont .boxes .box h2 a {
  position: absolute;
  right: 20px;
}

.console-pages .admin .pricingplan .cont .boxes .box .head {
  padding-top: 25px;
  padding-bottom: 25px;
}

.console-pages .admin .pricingplan .cont .boxes .box .innerbox {
  margin: 30px 15px 0;
  border: 1px solid #e3e3e3;
}

.console-pages .admin .pricingplan .cont .boxes .box .head {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  text-align: center;
  margin: 0;
}

.console-pages .admin .pricingplan .cont .boxes .box .head .info {
  margin-top: 14px;
}

.console-pages .admin .pricingplan .cont .boxes .box .head .info p {
  color: #5a5a5a;
  font-style: 15px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 5px;
}

.console-pages .admin .pricingplan .cont .boxes .box .head p img {
  position: relative;
  margin-top: -4px;
  height: 14px;
}

.console-pages .admin .pricingplan .cont .boxes .box .downcols {
  background: #f4f6fd;
  border-top: 1px solid #d5d5d5;
}

.console-pages .admin .pricingplan .cont .boxes .box .downcols p {
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 25px;
  color: #000000;
  text-align: center;
}

.console-pages .admin .pricingplan .cont .boxes .box .downcols .paddingdiv {
  padding-top: 25px;
  padding-bottom: 15px;
}

.console-pages .admin .pricingplan .cont .boxes .box .downcols .borderleft {
  border-left: 1px solid #d5d5d5;
}

.console-pages .my_earning {
  padding-left: 0px;
  padding-right: 0;
}

.console-pages .my_earning .cont {
  padding-left: 3rem;
  padding-right: 3rem;
  max-width: 2000px;
}

.console-pages .my_earning .cont .boxes .box {
  max-width: calc(25% - 20px);
  width: 100%;
  -webkit-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  -moz-box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.06);
  background: #fff;
  border-radius: 7px;
  margin-right: 21px;
  margin-bottom: 20px;
  display: inline-block;
}

.console-pages .my_earning .cont .boxes .box h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
  padding: 17px 14px 19px 20px;
  border-bottom: 1px solid #e5e5e5;
}

.console-pages .my_earning .cont .boxes .box .info {
  padding-top: 40px;
  padding-bottom: 60px;
  padding-left: 20px;
}

.console-pages .my_earning .cont .boxes .box .info h3 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 40px;
  line-height: 55px;
  color: #042470;
  margin: 0;
}

.console-pages .my_earning .cont .boxes .box .info p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #000000;
  margin: 0;
}

.console-pages .messages #wrapper #content-wrapper #content {
  overflow: hidden;
}

.new_application .cont .contbox {
  /* max-width: 1021px; */
  margin: auto;
  background: #fff;
  padding: 50px;
  margin-bottom: 50px;
  -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.04);
  position: relative;
}
.new_application .cont .contbox .nom {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 40px;
  line-height: 70px;
  color: #ffffff;
  text-align: center;
  background: #7ea1ff;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  position: absolute;
  left: -35px;
  top: -35px;
}
.new_application .cont .contbox .header {
  margin-bottom: 20px;
}
.new_application .cont .contbox .header h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  color: #000000;
  margin: 0;
}
.new_application .cont .contbox .header p {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 17px;
  line-height: 23px;
  color: #484848;
  margin: 0;
  margin-top: 5px;
}
.new_application .cont .contbox .body input {
  width: 100%;
  padding: 8px 30px 8px 15px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #7e7e7e;
  border: 1px solid #969696;
}
.new_application .cont .contbox .body input:focus {
  outline: none;
}
.new_application .cont .contbox .body p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #7e7e7e;
  padding-top: 9px;
}
.new_application .cont .contbox .body .inputdiv {
  position: relative;
}
.new_application .cont .contbox .body .inputdiv img {
  position: absolute;
  right: 15px;
  top: 11px;
}
.new_application .cont .contbox .body .boxes {
  margin-left: -10px;
  margin-top: 25px;
  text-align: center;
}
.new_application .cont .contbox .body .boxes .box {
  max-width: 209px;
  margin-right: 10px;
  margin-top: 10px;
  width: 100%;
  padding: 8px;
  -webkit-box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  margin-left: 10px;
  display: inline-block;
}
.new_application .cont .contbox .body .boxes .box img {
  max-width: 100%;
}
.new_application .cont .contbox .body .boxes .box h2 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #393939;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 50px;
}
.new_application .cont .contbox .body .boxes .box a {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #707070;
  padding: 5px 15px;
  border: 1px solid #707070;
  border-radius: 17px;
  text-align: center;
  margin: auto;
  display: table;
  margin-bottom: 10px;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}
.new_application .cont .contbox .body .boxes .box a svg {
  margin-right: 5px;
  margin-top: -3px;
  stroke: #707070;
  -webkit-transition: all 0.15s ease;
  -moz-transition: all 0.15s ease;
  -ms-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}
.new_application .cont .contbox .body .boxes .box a:hover {
  background: #7ea1ff;
  border-color: #7ea1ff;
  color: #fff;
  text-decoration: none;
}
.new_application .cont .contbox .body .boxes .box a:hover svg {
  stroke: #fff;
}
.new_application .cont .contbox .body .boxes .box .selected {
  background: #7ea1ff;
  border-color: #7ea1ff;
  color: #fff;
  text-decoration: none;
}
.new_application .cont .contbox .body .boxes .box .selected svg {
  stroke: #fff;
}
.new_application .cont .contbox .body .boxes .box:last-child {
  margin-right: 0;
}
.new_application .cont .contbox .body .boxes .carousel-control-prev {
  left: -35px;
  opacity: 1;
  width: 30px;
}
.new_application .cont .contbox .body .boxes .carousel-control-next {
  right: -40px;
  opacity: 1;
  width: 30px;
}

.react-datepicker__input-container {
  width: 100%;
}
.react-datepicker-wrapper {
  width: 100%;
}

.green-circle {
  width: 8px;
  height: 8px;
  background-color: #1bc88a;
  border-radius: 8px;
  display: inline-block;
}

.orange-circle {
  width: 8px;
  height: 8px;
  background-color: #f9a41e;
  border-radius: 8px;
  display: inline-block;
}

.grey-circle {
  width: 8px;
  height: 8px;
  background-color: #d5d5d5;
  border-radius: 8px;
  display: inline-block;
}

.red-circle {
  width: 8px;
  height: 8px;
  background-color: #e71515;
  border-radius: 8px;
  display: inline-block;
}

.p-30-0-0-25 {
  padding: 30px 0 0 25px;
}

.console-pages .sidebar-brand img {
  max-width: 100%;
}

.mt-25px {
  margin-top: 25px !important;
}

.money-box-sub {
  line-height: 20px;
  font-size: 11px;
  width: fit-content;
  margin: auto auto;
}

.money-box-sub-num {
  font-weight: 600;
  margin-right: 8px;
  font-size: 16px;
  color: #63abe0;
}

.react-dropdown-select-content input {
  line-height: inherit !important;
  /* width: calc(3px) !important; */
  border: none !important;
  margin-left: 5px !important;
  background: transparent !important;
}

.console-pages .sidebar {
  height: 100vh;
  position: fixed;
  z-index: 9999;
}

@media only screen and (min-width: 1300px) {
  .spreadlist {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    list-style-position: inside;
  }
}
@media (max-width: 1100px) {
  .console-pages .sidebar {
    position: fixed;
    left: -224px;
    transition-property: left;
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
  }

  .console-pages .sidebar-shown {
    left: 0 !important;
  }

  .console-pages #wrapper #content-wrapper {
    padding-left: 0;
  }

  .console-pages .cont {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  #sidebarToggleTop {
    display: block;
  }
}

@media (max-width: 370px) {
  .console-pages .cont {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

#sidebarToggleTop {
  position: fixed;
  left: 15px;
  top: 1rem;
  background-color: rgba(236, 239, 250, 0.931);
  z-index: 15000;
  transition-property: left;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  border: 1px solid rgba(236, 239, 250, 0.931);
}

.sidebarToggleActive {
  left: 235px !important;
  background-color: #eceffa !important;
}

#sidebarToggleTop:active {
  outline: none;
}

@media (min-width: 1100px) {
  #sidebarToggleTop {
    display: none;
  }
}

.console-pages #wrapper {
  position: relative;
  overflow-x: hidden;
  width: 100vw;
}

.console-pages #root {
  /* display: flex !important; // Commenting out to fix the issue on the kid program link page */
}

.boxes-row {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  margin-left: -8px;
  margin-right: 30px;
  /* margin-right: -8px;  */
}

.boxes-row .box {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  min-width: 300px;
  max-width: 430px;
  margin-left: 8px;
  margin-right: 8px;
}

.boxes-row-fluid {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  margin-left: -8px;
  margin-right: -8px;
}

.boxes-row-fluid .box-50 {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  min-width: 300px;
  max-width: unset !important;
  margin-left: 8px;
  margin-right: 8px;
  flex: 1 1 calc(50% - 16px) !important;
}

.boxes-row-fluid .box-33 {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  min-width: 300px;
  max-width: unset !important;
  margin-left: 8px;
  margin-right: 8px;
  flex: 0 1 calc(33% - 16px) !important;
}

@media (max-width: 1252px) {
  .boxes-row-fluid .box-33 {
    flex: 0 1 calc(50% - 16px) !important;
  }
}

@media (max-width: 648px) {
  .boxes-row-fluid .box-50,
  .boxes-row-fluid .box-33 {
    flex: 1 !important;
  }
}

.react-dropdown-select {
  font-size: 15px;
}

@media (max-width: 450px) {
  .none-450 {
    display: none;
  }
}

.console-pages .buttons .nextbtn:disabled {
  border-color: #cccccc !important;
  color: #cccccc !important;
  cursor: not-allowed;
}

.console-pages .buttons .deletebtn:disabled {
  border-color: #c78787;
  color: #c78787;
}

@media (max-width: 500px) {
  .stepStatus span {
    display: block;
  }

  .stepStatus i {
    margin-right: 0 !important;
    margin-bottom: 0.3rem;
  }
}

.stepStatus {
  font-size: 13px;
  text-align: center;
  cursor: pointer;
  font-family: "Open Sans", sans-serif !important;
  font-weight: 700 !important;
}

.stepStatus:hover {
  /* font-weight: 600 !important; */
  color: #ff6663 !important;
}

.stepStatus i {
  background-color: #e6e6e6;
  border: 1px solid #c7c7c7;
  padding: 5px;
  color: #c7c7c7;
  border-radius: 50%;
  margin-right: 1rem;
}

.stepStatus i.stepComplete {
  /* background-color: #b8deb8;
  border: 1px solid green;
  padding: 5px;
  color: green;
  border-radius: 50%; */
  background-color: #24cb86;
  border: 1px solid #d6d6d6;
  padding: 5px;
  color: white;
  border-radius: 50%;
}

.step-name {
}

.requiredField {
  margin-left: 2px;
  color: red;
}

.stepActive {
  /* font-family: "Open Sans",sans-serif !important;
  font-weight: 700 !important;
  color: #4e73df; */
  font-family: "Open Sans" !important;
  font-weight: 800 !important;
  color: #ff6663;
}

.stepActive:hover {
  /* font-weight: 600 !important; */
}

@media (max-width: 640px) {
  .ip-boxes {
    justify-content: center;
  }

  .step-name {
    font-size: 11px;
  }

  .console-pages .tablecont ul .active button {
    padding: 12px 12px;
    position: relative;
    display: table;
    color: #000;
  }

  .console-pages .tablecont ul li button {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #a3a3a3;
    padding: 8px 10px;
    background: #fff;
    border-top-right-radius: 14px;
    border-top-left-radius: 14px;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16);
    position: relative;
    transition: all 0.15s ease;
  }

  .console-pages .tablecont ul .active {
    margin-right: 8px;
  }
  .console-pages .tablecont ul li {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 8px;
    z-index: 0;
  }
}

@media (max-width: 450px) {
  .console-pages .tablecont ul .active button {
    padding: 10px 6px;
    position: relative;
    display: table;
    color: #000;
  }

  .console-pages .tablecont ul {
    min-height: 40px;
    overflow-x: auto;
  }

  .console-pages .tablecont ul li button {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 9px;
    line-height: 14px;
    color: #a3a3a3;
    padding: 7px 6px;
    background: #fff;
    border-top-right-radius: 14px;
    border-top-left-radius: 14px;
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.16);
    position: relative;
    transition: all 0.15s ease;
  }

  .console-pages .tablecont ul .active {
    margin-right: 6px;
  }
  .console-pages .tablecont ul li {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 6px;
    z-index: 0;
  }
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.spacer {
  flex: 1;
}

.console-pages .box .bgofbox textarea {
  border-radius: 8px;
  border-color: #e3e3e3;
  font-size: 14px;
}

.console-pages .box .bgofbox .remove-box {
  background-color: red !important;
  border-radius: 50% !important;
  color: white !important;
  align-self: center;
  padding: 0 !important;
  border: none;
  font-size: 11px;
  width: 18px;
  height: 18px;
}

.console-pages .box .bgofbox .remove-box i {
  width: 18px;
  height: 18px;
  line-height: 18px;
}

.react-dropdown-select-dropdown-position-bottom {
  max-height: 150px !important;
}

.console-pages .cont .formbody h4 {
  font-size: 12px;
  font-weight: 600;
  color: #303030;
}

.console-pages .cont .formbody {
  font-size: 12px;
}

.formbody .add-form-button {
  background: none;
  border: 1px solid #8f58ff;
  font-size: 11px;
  color: #8f58ff;
  padding: 4px 8px;
  border-radius: 8px;
  transition: all 0.2s;
  outline: none;
}

.formbody .add-form-button:focus {
  outline: none;
}

.formbody .add-form-button:hover {
  background-color: #8f58ff;
  color: white;
}

.disabled-link {
  pointer-events: none;
}

.disabled-link span {
  color: #cbc4e0 !important;
}

a[disabled] {
  pointer-events: none;
}

a[disabled] span {
  color: #cbc4e0 !important;
}

.console-pages .selectButton {
  text-align: center;
  text-decoration: none;
  color: #7ea1ff;
  background-color: #fff;
  border-color: #7ea1ff;
  padding: 1px 10px;
  margin: 4px 2px;
  border-radius: 15px;
  width: 100px;
  border: 1px solid;
  margin-left: calc(50% - 51px);
}

.console-pages .selectedButton {
  text-align: center;
  text-decoration: none;
  color: #fff;
  background-color: #7ea1ff;
  padding: 1px 10px;
  margin: 4px 2px;
  border-radius: 15px;
  width: 100px;
  border: 1px solid;
  margin-left: calc(50% - 51px);
}

.console-pages .selectedButton::after {
  content: "Selected";
}
.console-pages .selectButton::after {
  content: "Select";
}
.console-pages .selectedButton:focus,
.console-pages .selectButton:focus {
  outline: none;
}

/**
GEOSUGGEST CSS
**/
/**
 * The geosuggest module
 * NOTE: duplicated font-sizes' are for browsers which don't support rem (only IE 8)
 */
.geosuggest {
  position: relative;
  width: 100%;
  text-align: left;
  display: flex;
  /* flex-grow: 1; */
  align-items: flex-end;
}
.geosuggest__input-wrapper {
  width: 100%;
}
.geosuggest__input {
  width: 100%;
}
.geosuggest__input:focus {
  border-color: #267dc0;
  box-shadow: 0 0 0 transparent;
}
.geosuggest__suggests {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 25em;
  padding: 0;
  margin-top: -1px;
  background: #fff;
  border: 2px solid #267dc0;
  border-top-width: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  z-index: 5;
  -webkit-transition: max-height 0.2s, border 0.2s;
  transition: max-height 0.2s, border 0.2s;
}
.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

/**
 * A geosuggest item
 */
.geosuggest__item {
  font-size: 18px;
  font-size: 1rem;
  padding: 0.5em 0.65em;
  cursor: pointer;
}
.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #f5f5f5;
}
.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
  background: #ccc;
}
.geosuggest__item__matched-text {
  font-weight: bold;
}

raf-new-activities-notification {
  position: sticky;
  top: 0;
}

iframe {
  border: 0;
}
