/* Modal specific CSS  */
.request-modal-cont .request-modal.new-modal {
  height: min-content;
  padding: 35px 30px 25px;
  overflow: visible;
  display: flex;
  flex-direction: column;
  max-height: 70vh;
}

.request-modal.new-modal .modal-head button.MuiButtonBase-root {
  margin-right: 0 !important;
  padding: 12px !important;
  border-radius: 50%;
  color: #2880ff;
  margin-bottom: 0.5rem;
}

.request-modal.new-modal .modal-head button.MuiIconButton-root:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.request-modal.new-modal .modal-banner {
  height: 30px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  border-radius: 15px 15px 0px 0px;
}

.request-modal.new-modal .modal-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.request-modal-cont .request-modal.new-modal h3 {
  margin-top: 0;
  margin-bottom: 0.4rem;
  font-size: 1.8rem;
}

.request-modal.new-modal .modal-footer {
  margin-top: 0.5rem;
  width: 100%;
}

.request-modal.new-modal .modal-footer button {
  margin-top: 0.5rem !important;
}

.request-modal.new-modal .modal-main {
  overflow: auto;
  overflow-y: inherit;
  height: min-content;
  max-height: 30rem;
  padding-right: 10px;
  margin: 1rem 0 0.75rem;
  scrollbar-width: thin;
}

.request-modal.new-modal ::-webkit-scrollbar {
  width: 6px;
}

.request-modal.new-modal ::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
}

.request-modal.new-modal ::-webkit-scrollbar-thumb {
  background-color: #ddd;
  width: 6px;
  border-radius: 8px;
}

.request-modal.new-modal > form {
  display: flex;
  flex-direction: column;
  overflow: auto;
}
