/* Kids programs organization profile */

.skip-programs{
    display: flex;
    align-items: center;
    margin-left: 8px;
    max-width: 430px;
    position: absolute;
    top: 181px;
    left: 581px;
    font-weight: 600;
    font-family: "Open Sans";
}

.skip-programs-admin{
    display: flex;
    align-items: center;
    margin-left: 8px;
    max-width: 430px;
    position: absolute;
    top: 235px;
    left: 450px;
    font-weight: 600;
    font-family: "Open Sans";
}

/* Tablets */
@media only screen and (max-width: 960px) {
    .skip-programs{
        display: flex;
        align-items: center;
        margin-left: 8px;
        max-width: 430px;
        position: absolute;
        top: 175px;
        left: 320px;
        font-weight: 600;
        font-family: "Open Sans";
    }
    
    .skip-programs-admin{
        display: flex;
        align-items: center;
        margin-left: 8px;
        max-width: 430px;
        position: absolute;
        top: 235px;
        left: 450px;
        font-weight: 600;
        font-family: "Open Sans";
    }
}


/* Phones */
@media only screen and (max-width: 767px) {
    .skip-programs{
        display: flex;
        align-items: center;
        margin-left: 8px;
        max-width: 430px;
        position: absolute;
        top: 218px;
        left: 30px;
        font-weight: 600;
        font-family: "Open Sans";
    }
    
    .skip-programs-admin{
        display: flex;
        align-items: center;
        margin-left: 8px;
        max-width: 430px;
        position: absolute;
        top: 235px;
        left: 450px;
        font-weight: 600;
        font-family: "Open Sans";
    }
}