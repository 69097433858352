.modal-form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  flex-wrap: wrap;
}

.modal-form-group > label.modal-label {
  width: 25%;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.modal-form-group input.form-control {
  font-size: 1.5rem;
  font-family: futura-pt, sans-serif;
  color: #626262
}

.modal-form-group div.react-datepicker-wrapper {
  width: 70%;
}

.modal-form-group select.form-control {
  width: 70%;
}

.modal-form-group .react-datepicker__tab-loop {
  flex-basis: 100%;
}

.modal-form-group .modal-span {
  width: 70%;
  color: black;
  font-size: 16px;
}
