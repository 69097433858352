.custom-addChild-date{
  border: none !important;
  width: 100% !important;
  padding: 5px !important;
}

.react-datepicker__month .react-datepicker__month-text {
  width: 3.5rem !important;
}
@media only screen and (max-width: 400px) {
  .react-datepicker-popper {
    left: -40px !important;
  }
}
