/* .string-input-field {
  height: 40px;
  width: 100%;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  font-size: 0.93rem !important;
  color: #000000;
  padding-left: 14px;
  margin-top: 8px !important;
} */

.string-input-field::placeholder {
  color: #797979;
}

.string-input-invalid {
  border: 1px solid rgb(255, 0, 0);
}

.string-input-field::-webkit-outer-spin-button,
.string-input-field::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.string-input-field[type="number"] {
  -moz-appearance: textfield;
}
