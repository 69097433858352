.cart-modal-container {
  padding: 1rem;
  position: absolute;
  z-index: 100000;
  top: 75px;
  right: 15px;
  width: 30vw;
  border-radius: 20px;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  max-width: 300px;
  height: 500px;
  background: white;
  display: none; /* mobile screens */
  flex-direction: column;
  justify-content: start;
  line-height: 1.15 !important;
}

.cart-modal-header {
  font-size: 22px;
  font-family: "Montserrat", sans-serif;
  color: #363e8a;
  font-weight: 800;
  margin: 1rem;
}

.cart-modal-list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
  width: 100%;
}

.cart-modal-list::-webkit-scrollbar {
  width: 5px;
}

.cart-modal-list::-webkit-scrollbar-track {
  background-color: #f2f2f2;
}

.cart-modal-list::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.cart-modal-list::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

.cart-modal-entry {
  display: flex;
  flex-direction: row;
  font-size: 15px !important;
  line-height: 1.15 !important;
  font-family: "Lato", "Helvetica", "Arial", sans-serif !important;
  margin: 1rem;
}

.cart-modal-entry > img {
  width: auto;
  object-fit: cover;
  aspect-ratio: 4 / 3;
  height: 90px;
  margin-right: 1rem;
}
.cart-modal-entry-info {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.cart-page-remove {
  display: flex;
  color: #c51c1d;
  font-weight: 600;
  cursor: pointer;
  align-items: flex-end;
  vertical-align: text-bottom;
  margin-top: auto;
}

.cart-modal-entry-title {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.cart-modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  margin: 1rem;
}

.cart-view-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
}

.cart-view-subtotal {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  line-height: 20px;
}

.cart-modal-button {
  font-size: 16px !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600 !important;
  padding: 10px;
  background: #3e9d3e;
  color: white !important;
  border-radius: 12px !important;
  margin: 0 !important;
  border: none;
}
.cart-modal-button:hover {
  background: #75b975;
}
.cart-modal-button:disabled {
  background: #375737;
}

.cart-modal-subtotal {
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-right: auto;
}

.cart-checkout-button {
  font-size: 18px !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600 !important;
  padding: 16px 32px;
  background: #c71c1c;
  color: white !important;
  border-radius: 3px !important;
  margin: 0 !important;
  border: none;
  line-height: 20px !important;
  margin-top: 5px !important;
}

.cart-continue-shopping-button {
  font-size: 18px !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 600 !important;
  padding: 16px 32px;
  background: green;
  color: white !important;
  border-radius: 3px !important;
  margin: 0 !important;
  border: none;
  margin-right: 5px !important;
  line-height: 20px !important;
}

@media screen and (max-width: 930px) {
  .cart-modal-container {
    max-width: 500px;
    display: flex;
    height: 80vh;
    width: 50vw;
  }

  .cart-modal-entry > img {
    aspect-ratio: 16 / 9;
  }
}

@media screen and (max-width: 430px) {
  .cart-modal-container {
    max-width: 500px;
    display: flex;
    height: 50vh;
    width: 92vw;
  }

  .cart-modal-entry > img {
    aspect-ratio: 16 / 9;
  }
}

@media screen and (min-width: 768px) {
  .cart-modal-container {
    max-width: 500px;
    display: flex;
  }

  .cart-modal-entry > img {
    aspect-ratio: 16 / 9;
  }
}

@media screen and (min-width: 1024px) {
  .cart-modal-container {
    max-width: 700px;
    display: flex;
  }
}

.cart-modal-dropdown {
  right: -500px;
  position: fixed;
  transition: right 0.3s ease-out;
  z-index: 9999;
}

.cart-modal-dropdown.open {
  right: 15px;
  transition: right 0.3s ease-out;
}
