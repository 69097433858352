.n-search-header {
  position: relative;
  width: 100%;
}

.n-search-container {
  align-items: center;
  display: flex;
  width: 100%;
}

.n-search-program {
  position: relative;
  max-width: 700px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
}
.geosuggest__suggests {
  border: 1px solid lightgrey !important;
  border-top-width: 0;
  border-radius: 4px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
}

.geosuggest__item {
  font-size: 1.2rem !important;
}

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0 !important;
}
.geosuggest__item--active {
  background: lightgrey !important;
  color: #fff;
}
.n-search-header input {
  width: 100%;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px !important;
  color: #808285;
  padding: 15px 15px 15px 40px !important;
  border-radius: 5px 5px 5px 5px !important;
  border: lightgray 1px solid !important;
}

.n-search-reg {
  width: 75%;
}
.n-search-blue {
  width: 100%;
}
.n-search-reg input {
  font-weight: 400;
  font-size: 12px !important;
  color: #808285;
  height: 34px;
  padding: 10px !important;
  border-radius: 5px !important;
  border: lightgray 1px solid !important;
  width: 100%;
}
.n-search-blue input {
  font-weight: 400;
  font-size: 12px !important;
  color: #808285;
  height: 34px;
  padding: 0px !important;
  border: none !important;
  border-radius: 0px !important;
  /* border-bottom: lightgray 1px solid !important; */

  width: 100%;
}
@media (max-width: 768px) {
  .n-search-header input {
    font-size: 12px !important;
  }
}

@media (max-width: 359px) {
  .n-search-header input {
    font-size: 10px !important;
  }
}
@media only screen and (max-width: 500px) {
  .n-search-header {
    margin-top: 15px;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .n-search-header {
    margin-top: 15px;
  }
}
.n-search-header button {
  background: transparent !important;
  border: 0 !important;
  position: absolute !important;
  right: 0 !important;
  height: 100% !important;
  color: #d6a087 !important;
}

.n-search-header button img {
  max-width: 20px !important;
}

.organization-search {
  position: absolute !important;
  border: 1px solid #d3d3d3 !important;
  font-size: 1.2rem !important;
  z-index: 99 !important;
  cursor: pointer !important;
  top: 100% !important;
  left: 0 !important;
  right: 0 !important;
  border-radius: 4px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
}

.organization-search-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.organization-search-item:last-child {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.organization-search-item {
  padding: 0.5em 0.65em !important;
  background-color: #fff;
}

.organization-search-item:hover {
  background-color: #f5f5f5 !important;
}
