.maintitleprogram {
  font-family: Montserrat, sans-serif !important;
  color: #505050 !important;
  font-weight: 700 !important;
  flex-grow: 1;
  margin: 0 !important;
  margin-bottom: 20px !important;
}
.containerall {
  display: flex;
  padding: 1.5em;
  margin: 0;
}
.leftcontainerprogram {
  overflow: hidden;
  width: 70%;
  margin-bottom: 14px;
}
.rightcontainerprogram {
  width: 30%;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 0px;
  border-radius: 15px;
}
.pricingcard {
  width: 100%;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin: 0px 0px 20px;
  height: 100px;
  cursor: pointer;
}
.maincontainerdiv {
  margin: 10px 30px;
  display: flex;
}
.imageprogram {
  width: 40%;
  height: inherit;
  box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
  object-fit: "cover";
  max-height: 300px;
  min-width: 388px;
}
.titledivision {
  display: block;

  margin: 10px 30px;
}
.widgetdata {
  text-align: center;
  margin-left: 50px;
}

@media only screen and (max-width: 768px) {
  .maintitleprogram {
    font-family: Montserrat, sans-serif !important;
    color: #505050 !important;
    font-weight: 700 !important;
    flex-grow: 1;
    margin: 0 !important;
    margin-bottom: 20px !important;
  }
  .maincontainerdiv {
    margin: 10px 30px;
    display: block;
  }

  .imageprogram {
    width: 100%;
    height: inherit;
    box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.15);
    border-radius: 7px;
    object-fit: "cover";
    max-height: unset;
    min-width: unset;
  }
  .widgetdata {
    text-align: center;
    margin-top: 20px;
    margin-left: 0px;
  }
  .leftcontainerprogram {
    width: 100%;
  }
  .containerall {
    display: block;
    padding: 0.5em;
    margin: 0;
  }
  .rightcontainerprogram {
    width: 100%;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 15px;
  }
  .pricingcard {
    width: 100%;
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin: 0px 0px 20px;
    height: 100px;
    cursor: pointer;
  }
}
