.header-img {
  height: 80px !important;
}

.content-area {
  background-color: #fafafa !important;
}

.top-content {
  box-shadow: 0 1px 0 #e5e5e5 !important;
  background-color: white !important;
}

.content-display {
  display: flex !important;
  margin: 3em 5em 6em 5em !important;
}
.iframe {
  margin: 0em 5em 1.5em 5em !important;
}

.left-content {
  width: 60% !important;
  padding-right: 4em !important;
}
.iflef {
  width: 100% !important;
  padding-right: 4em !important;
}
.title {
  margin: 0 !important;
  font-family: "Montserrat" !important;
  font-weight: 700 !important;
  /* font-size: 3rem !important; */
  line-height: 1.25 !important;
  color: #505050 !important;
  letter-spacing: 0.1px !important;
  font-size: 22px !important;
  position: fixed;
  top: 38px;
  z-index: 9999;
  left: 118px;
  cursor: pointer;
}
.title-inner {
  margin: 0 !important;
  font-family: "Montserrat" !important;
  font-weight: 700 !important;
  /* font-size: 3rem !important; */
  line-height: 1.25 !important;
  color: #505050 !important;
  letter-spacing: 0.1px !important;
  font-size: 22px !important;
}
.titleiframe {
  margin: 0 !important;
  font-family: "Montserrat" !important;
  font-weight: 700 !important; /* changed */
  color: #505050 !important;
  letter-spacing: 0.1px !important; /* changed */
  font-size: 22px !important;
  position: absolute;
  top: 37px;
  z-index: 9999;
  left: 73px;
  cursor: pointer;
}
.button-options {
  display: flex !important;
  justify-content: space-between !important;
  list-style-type: none !important;
  padding-left: 0 !important;
  align-items: flex-start !important;
  margin-top: 7px !important;
}

.main-btns {
  display: flex !important;
}

.icon-btns {
  display: flex !important;
  padding-right: 70px !important;
}

.button-list {
  margin-right: 5px !important;
  font-size: 1.7rem !important;
}

.text-area {
  display: flex !important;
  justify-content: space-between !important;
  margin-top: 30px !important;
}

.text-description {
  font-weight: 400 !important;
  font-family: "Open sans", sans-serif !important;
  white-space: pre-line;
}

#form-checkout-dob {
  width: 150px !important;
}

#form-checkout-input {
  width: 500px !important;
  max-width: 100%;
  font-weight: 500;
}

.display-icons {
  text-align: center !important;
  margin: 0px auto !important;
  display: flex !important;
  margin-top: 40px !important;
}

.icon-grid-view {
  margin-top: 15px !important;
  display: grid !important;
  grid-template-columns: 1fr 1fr !important;
  /* grid-template-rows: 1fr 1fr 1fr !important; */
  column-gap: 10px !important;
  row-gap: 10px !important;
}

.icon-containers {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  font-family: "Quicksand", sans-serif !important;
  font-size: 12px !important;
}

.middle-icon-img {
  width: 40px !important;
  height: 40px !important;
  margin-right: 15px !important;
}

.icon-name {
  display: block !important;
  margin: 0 !important;
  font-weight: bold !important;
}

.icon-inner-text {
  display: flex !important;
  justify-content: flex-start !important;
  margin: 0 !important;
  font-size: 12px !important;
  width: 150px;
}

.box-img {
  display: none !important; /* GALLERY IMAGES DISPLAY */
  margin-left: 0 !important;
}

.gallery-img {
  width: 60px !important;
  height: 60px !important;
  margin-right: 15px !important;
  cursor: pointer !important;
  object-fit: cover !important;
  border-radius: 3px !important;
}

.map-container {
  width: 40% !important;
  height: inherit !important;
}

.tab-headers {
  width: 100% !important;
}

.sub-list {
  display: flex !important;
  list-style-type: none !important;
  padding-left: 0 !important;
  justify-content: flex-start !important;
  color: #ff6663 !important;
  font-weight: bold !important;
  font-size: 14px !important;
  background-color: #fff !important;
  border-top: 1px solid rgb(236, 236, 236) !important;
  border-bottom: 1px solid rgb(236, 236, 236) !important;
  padding: 6px 70px 6px 70px !important;
  width: 100% !important;
  margin: 0 auto !important;
}

.sublist-items {
  margin-right: 25px !important;
  cursor: pointer !important;
}

.card-container {
  padding: 45px 70px 180px 70px !important;
  background: white !important;
}

.card-area {
  display: flex !important;
  justify-content: space-between !important;
  margin-bottom: 20px !important;
  width: 100% !important;
  border-radius: 7px !important;
  align-items: center !important;
  padding: 20px !important;
  cursor: pointer !important;
  background-color: white !important;
}

.party-card-area {
  display: inline-flex !important;
  justify-content: space-between !important;
  flex-wrap: wrap;
  flex-direction: column;
  margin-left: 1.5%;
  margin-right: 1.5%;
  margin-bottom: 20px !important;
  width: 30% !important;
  border-radius: 7px !important;
  align-items: center !important;
  padding: 20px !important;
  cursor: pointer !important;
  background-color: white !important;
}

.card-img {
  width: 22% !important;
  height: 147px !important;
  object-fit: cover !important;
  border-radius: 7px !important;
  box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.1) !important;
}

.party-card-img {
  width: 100% !important;
  height: 147px !important;
  object-fit: cover !important;
  border-radius: 7px !important;
  box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.1) !important;
}

.card-content {
  display: flex !important;
  width: 70% !important;
  justify-content: space-between !important;
}


.party-card-content {
  padding: 10px !important;
  display: flex !important;
  width: 100% !important;
  justify-content: space-between !important;
  flex-direction: column;
}

.card-sub1 {
  width: 25% !important;
  padding: 5px !important;
}

.party-card-sub1 {
  width: 100% !important;
  padding: 5px !important;
  display: flex;
  flex-direction: column;
}

.membership-card-sub1 {
  width: 25% !important;
  padding: 5px !important;
}

.party-sub1-name {
  margin: 0 !important;
  color: 333 !important;
  font-weight: bold !important;
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif !important;
}

.sub1-name {
  margin: 0 !important;
  color: 333 !important;
  font-weight: bold !important;
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif !important;
}

.sub1-age {
  margin-top: 5px !important;
  color: lightgrey !important;
  font-family: Lato, sans-serif !important;
}

.sub1-description {
  margin-top: 5px !important;
  color: rgb(128, 128, 128) !important;
  font-family: Lato, sans-serif !important;
}

.sub1-type {
  font-family: Lato, sans-serif;
  display: block;
  color: lightgrey;
}

.card-sub2 {
  width: 25% !important;
  padding: 5px !important;
}

.sub2-label-value {
  margin-top: 5px !important;
  color: rgb(128, 128, 128) !important;
  font-family: Lato, sans-serif !important;
}

.party-card-sub2 {
  width: 100% !important;
  padding: 5px !important;
}

.sub2-timing {
  margin: 0 !important;
  color: 333 !important;
  font-weight: bold !important;
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif !important;
}

.sub2-label {
  margin: 0 !important;
  color: 333 !important;
  font-family: Lato, sans-serif !important;
  margin-top: 5px !important;
}

.sub2-label-img {
  width: 5% !important;
  margin: 5px !important;
}

.sub2-date {
  margin-top: 5px !important;
  color: lightgrey !important;
  font-family: Lato, sans-serif !important;
}

.card-sub3 {
  width: 20% !important;
  padding: 5px !important;
}

.party-card-sub3 {
  width: 100% !important;
  padding: 5px !important;
}

.sub3-pricing {
  margin: 0 !important;
  color: #192aa7 !important;
  font-weight: bold !important;
  font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif !important;
  width: 170px;
}

.sub3-date {
  margin-top: 5px !important;
  color: lightgrey !important;
  font-family: Lato, sans-serif !important;
}

.membership-img-container {
  width: 22% !important;
  box-shadow: 4px 4px 5px #d6c3c3 !important;
  border-radius: 10px !important;
  height: 180px !important;
}

.membership-img {
  width: 100% !important;
  object-fit: cover !important;
  height: 100% !important;
  border-radius: 10px !important;
}

.news-card {
  margin-bottom: 20px !important;
  padding: 20px !important;
  cursor: pointer !important;
  border-radius: 7px !important;
  background-color: white !important;
}

.news-title {
  margin: 0 0 1em 0 !important;
  color: 333 !important;
  font-weight: bold !important;
  font-family: "Open Sans", sans-serif !important;
}

.gift-title {
  margin: 30px 0 2px 0 !important;
  color: #2347ae !important;
  font-weight: bold !important;
  font-family: "Open Sans", sans-serif !important;
}

.news-img-container {
  max-width: 200px;
  margin-right: 30px;
}

.news-img {
  width: 100% !important;
  height: 147px !important;
  object-fit: cover !important;
  border-radius: 7px !important;
  box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.1) !important;
}

.news-desc {
  width: 80% !important;
  font-family: "Open Sans", sans-serif !important;
}

.news-date {
  display: inline-block !important;
  margin-top: 10px !important;
  color: grey !important;
  font-family: "Lato", sans-serif !important;
}

.gift-card {
  margin-bottom: 20px !important;
  padding: 20px !important;
  cursor: pointer !important;
  border-radius: 7px !important;
  background-color: white !important;
}

.line-container {
  display: flex;
  margin: 10px 0;
}

.appointment-line-container {
  display: block !important;
  align-items: center !important;
  /* margin-left: auto !important; */
  text-align: center !important;
  margin-right: auto;
  width: 100% !important;
  position: relative;
  height: 700px;
}

.appointment-calendar-box {
  display: flex !important;
  align-items: center !important;
  border: 1px solid #eee;
  border-radius: 15px;
  justify-content: center !important;
  width: 85%;
  position: absolute;
  left: 7%;
}

.appointment-calendar-box-iframe {
  display: flex !important;
  align-items: center !important;
  border: 1px solid #eee;
  border-radius: 15px;
  justify-content: center !important;
  width: 100%;
  position: absolute;
  left: 7%;
}

.form-multiselect {
  display: flex;
  gap: 12px;
  align-items: center;
  flex-wrap: wrap;
  font-family: "Open-sans", sans-serif !important;
}

.form-container {
  width: 50%;
  align-items: center;
  padding: 0 15px;
}

.appointment-form-container {
  width: 50% !important;
  margin-bottom: 20px !important;
  padding: 0 5px 0 5px !important;
  display: flex !important;
  height: 100% !important;
  min-height: 200px !important;
  margin-top: 20px !important;
}

.appointment-form-container:first-child {
  margin-left: 11% !important;
  margin-right: 60px;
}

.appointment-nxt-btn {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 30px 0 30px;
  position: absolute;
  top: 70%;
}

.appointment-nxt-btn-iframe {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 30px 0 30px;
  position: absolute;
  top: 75%;
}

.appointment-form-container-iframe {
  width: 50% !important;
  margin-bottom: 20px !important;
  padding: 0 5px 0 5px !important;
  display: flex !important;
  height: 100% !important;
  min-height: 200px !important;
  margin-top: 20px !important;
}

.appointment-form-container-iframe:first-child {
  margin-left: 11% !important;
  margin-right: 140px;
}

.form-input {
  height: 3em;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  font-family: "Open Sans", sans-serif !important;
  padding-left: 0.5em;
}

/* Material UI Overridden styles */
.MuiTabs-scrollable {
  overflow-y: hidden !important;
}

/* MuiButtonBase-root PreviewPartner-tabRoot-29  */
/* .MuiButtonBase-root {
  margin-right: 10px !important;
  padding: 6px 12px !important;
  min-width: 0 !important;
} */

/* .MuiTabs-flexContainer {
  margin-left: 57px !important;
} */

.MuiTabs-root {
  padding-left: 72px !important;
}

.MuiTabs-root .PreviewPartner-subHeadings-10 {
  height: 44px !important;
}

.PreviewPartner-tabSelected-4 {
  height: 44px !important;
}

.PreviewPartner-subHeadings-10 {
  align-items: flex-start !important;
}

.MuiTabScrollButton-root.Mui-disabled {
  opacity: 0;
  display: none;
}

/* Form Appointments React Date Picker for the benefit of the other areas where it is used */

.react-datepicker {
  font-size: 1em;
}
.react-datepicker__header {
  padding-top: 0.8em;
}
.react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}
.react-datepicker__current-month {
  font-size: 1em;
}
.react-datepicker__navigation {
  /* top: 1em; */
  line-height: 1.7em;
  /* border: 0.5em solid transparent; */
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc;
  left: 1em;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc;
  right: 1em;
}
.react-datepicker__navigation--years-previous {
  border-top-color: #ccc;
  border-width: 0.5em;
}
.react-datepicker__navigation--years-upcoming {
  border-bottom-color: #ccc;
  border-width: 0.5em;
}

/* styling for the new nice react date picker lib for appointments */

.calendar-box {
  border: 1px solid #eee;
  width: 600px;
}

.nice-dates-navigation_current {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
}

.nice-dates-week-header_day {
  color: #bdbec3 !important;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.nice-dates-day {
  font-family: "Open Sans", sans-serif;
  /* font-weight: 600; */
}

.nice-dates-day:before {
  background-color: #ff6663 !important;
}

.nice-dates-day:after {
  border: 2px solid #ff6663 !important;
}

/* Appt Time Scroll Section */

.appt-time-scroll {
  height: 400px;
  width: 200px;
  z-index: 99;
  overflow: hidden;
  overflow-y: auto;
  padding: 10px;
  display: block;
  text-align: center;
  border: 1px solid #ece9e9;
  /* position: relative; */
  /* scrollbar-width: thin !important; */
}

.appt-time-scroll::-webkit-scrollbar {
  width: 5px;
}

/* .appt-time-scroll-offset-area{
  height: 100%;
  background-color: pink;
  width: 20px;
  position: absolute;
  top: 0;
  right: 15;
  z-index: 999;
} */

.time-list-btn {
  margin: 0 5px 0 0;
  /* width:100px;
  height:50px; */
}

.time-list-btn label,
.time-list-btn input {
  display: flex;
  justify-content: center;
}

.time-list-btn input[type="radio"] {
  opacity: 0.011;
  z-index: 100;
}

.time-list-btn input[type="radio"]:checked + label {
  background: #ff6663;
  color: white;
}

.time-list-btn label {
  padding: 8px 0px;
  border: 1px solid #ccc;
  cursor: pointer;
  z-index: 90;
  width: 140px;
  font-family: "Open Sans", sans-serif;
  font-weight: 700 !important;
  font-size: 13px;
  border-radius: 5px;
  color: #ff6663;
}

.time-list-btn label:hover {
  background: white;
  border: 1px solid #ff6663;
  color: #ff6663;
}

/* Tablets */
@media only screen and (max-width: 1164px) {
  .header-img {
    height: 80px !important; /* changed */
  }

  .content-area {
    background-color: #fafafa !important;
  }

  .top-content {
    box-shadow: 0 1px 0 #e5e5e5 !important;
    background-color: white !important;
  }

  .content-display {
    display: flex !important;
    margin: 3em 5em 6em 5em !important;
  }
  .iframe {
    margin: 0em 5em 1.5em 1.7em !important;
  }

  .left-content {
    width: 60% !important;
    padding-right: 4em !important;
  }
  .iflef {
    width: 100% !important;
    padding-right: 4em !important;
  }

  .title {
    margin: 0 !important;
    font-family: "Montserrat" !important;
    font-weight: 700 !important;
    /* font-size: 2.5rem !important; */
    color: #505050 !important;
    left: 76px;
  }
  .titleiframe {
    margin: 0 !important;
    font-family: "Montserrat" !important;
    font-weight: 700 !important; /* changed */
    color: #505050 !important;
    letter-spacing: 0.1px !important; /* changed */
    font-size: 2rem !important;
    left: 24px;
  }

  .button-options {
    display: flex !important;
    list-style-type: none !important;
    padding-left: 0 !important;
    justify-content: space-between !important; /* changed */
    flex-wrap: wrap; /* changed */
    align-items: flex-start !important;
    margin-top: 7px !important;
  }

  .main-btns {
    display: flex !important;
  }

  .icon-btns {
    display: flex !important;
    padding-right: 0 !important; /* changed */
  }

  .button-list {
    margin-right: 5px !important;
    font-size: 1.3rem !important; /* changed */
  }

  .text-area {
    display: flex !important;
    justify-content: space-between !important;
    margin-top: 30px !important;
  }

  .text-description {
    font-weight: 400 !important;
    font-family: Open sans, sans-serif !important;
  }

  .display-icons {
    text-align: center !important;
    margin: 0px auto !important;
    display: block !important;
    margin-top: 20px !important; /* Changed */
    flex-wrap: wrap !important; /* Changed */
  }

  .icon-grid-view {
    display: grid !important;
    grid-template-columns: 1fr 1fr !important;
    grid-template-rows: 1fr 1fr !important;
    column-gap: 10px !important;
    row-gap: 10px !important;
  }

  .icon-containers {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    font-family: "Quicksand", sans-serif !important;
    font-size: 12px !important;
  }

  .middle-icon-img {
    width: 40px !important;
    height: 40px !important;
    margin-right: 15px !important;
  }

  .icon-name {
    display: none !important;
    margin: 0 !important;
    font-weight: bold !important;
  }

  .icon-inner-text {
    display: flex !important;
    justify-content: flex-start !important;
    margin: 0 !important;
    font-size: 12px !important;
  }

  .box-img {
    display: none !important; /* GALLERY IMAGES DISPLAY */
    margin-left: 0 !important; /* Changed */
  }

  .gallery-img {
    width: 60px !important;
    height: 60px !important;
    margin-right: 15px !important;
    cursor: pointer !important;
    object-fit: cover !important;
    border-radius: 3px !important;
  }

  .map-container {
    width: 40% !important;
    height: inherit !important;
  }

  .tab-headers {
    width: 100% !important;
  }

  .sub-list {
    display: flex !important;
    list-style-type: none !important;
    padding-left: 0 !important;
    justify-content: flex-start !important;
    color: #ff6663 !important;
    font-weight: bold !important;
    font-size: 14px !important;
    background-color: #fff !important;
    border-top: 1px solid rgb(236, 236, 236) !important;
    border-bottom: 1px solid rgb(236, 236, 236) !important;
    padding: 6px 50px 6px 50px !important; /* changed */
    width: 100% !important;
    margin: 0 auto !important;
  }

  .sublist-items {
    margin-right: 25px !important;
    cursor: pointer !important;
  }

  .card-container {
    padding: 20px 20px 20px 20px !important;
    background: white !important;
  }

  .card-area {
    display: flex !important;
    justify-content: space-between !important;
    margin-bottom: 20px !important;
    width: 100% !important;
    border-radius: 7px !important;
    align-items: center !important;
    padding: 20px !important;
    cursor: pointer !important;
    background-color: white !important;
  }

  .card-img {
    width: 25% !important;
    height: 100px !important;
    object-fit: cover !important;
    border-radius: 7px !important;
    box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.1) !important;
  }

  .card-content {
    display: flex !important;
    justify-content: space-between !important;
  }

  .card-sub1 {
    width: 25% !important;
    padding: 5px !important;
    margin-left: 20px !important;
  }

  .membership-card-sub1 {
    width: 25% !important;
    padding: 5px !important;
    margin-left: 20px !important;
  }

  .sub1-name {
    margin: 0 !important;
    color: 333 !important;
    font-weight: bold !important;
    font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif !important;
    font-size: 1.2rem !important; /* changed */
  }

  .sub1-age {
    margin-top: 5px !important;
    color: lightgrey !important;
    font-family: Lato, sans-serif !important;
    font-size: 1.2rem !important; /* changed */
  }

  .sub1-type {
    font-family: Lato, sans-serif;
    display: block;
    color: lightgrey;
    font-size: 1.2rem !important;
  }

  .card-sub2 {
    width: 35% !important;
    padding: 5px !important;
  }

  .sub2-timing {
    margin: 0 !important;
    color: 333 !important;
    font-weight: bold !important;
    font-size: 1.1rem !important; /* changed */
    font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif !important;
  }

  .sub2-date {
    margin-top: 5px !important;
    color: lightgrey !important;
    font-family: Lato, sans-serif !important;
    font-size: 1.2rem !important; /* changed */
  }

  .card-sub3 {
    width: 15% !important;
    padding: 5px !important;
  }

  .sub3-pricing {
    margin: 0 !important;
    color: #192aa7 !important;
    font-weight: bold !important;
    font-size: 1.2rem !important; /* changed */
    font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif !important;
  }

  .sub3-date {
    margin-top: 5px !important;
    color: lightgrey !important;
    font-family: Lato, sans-serif !important;
  }

  .membership-img-container {
    width: 25% !important; /* changed */
    box-shadow: 4px 4px 5px #d6c3c3 !important;
    border-radius: 10px !important;
    height: 110px !important; /* changed */
  }

  .membership-img {
    width: 100% !important;
    object-fit: cover !important;
    height: 100% !important;
    border-radius: 10px !important;
  }

  .news-card {
    margin-bottom: 20px !important;
    padding: 20px !important;
    cursor: pointer !important;
    border-radius: 7px !important;
    background-color: white !important;
  }

  .news-title {
    margin: 0 0 1em 0 !important;
    color: 333 !important;
    font-weight: bold !important;
    font-family: "Open Sans", sans-serif !important;
  }

  .news-img-container {
    max-width: 200px;
    margin-right: 20px; /* changed */
  }

  .news-img {
    width: 100% !important;
    height: 147px !important;
    object-fit: cover !important;
    border-radius: 7px !important;
    box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.1) !important;
  }

  .news-desc {
    width: 80% !important;
    font-family: "Open Sans", sans-serif !important;
  }

  .news-date {
    display: inline-block !important;
    margin-top: 10px !important;
    color: grey !important;
    font-family: "Lato", sans-serif !important;
  }

  .gift-card {
    margin-bottom: 20px !important;
    padding: 20px !important;
    cursor: pointer !important;
    border-radius: 7px !important;
    background-color: white !important;
  }

  .appointment-calendar-box-iframe {
    display: flex !important;
    align-items: center !important;
    border: 1px solid #eee;
    border-radius: 15px;
    justify-content: center !important;
    width: 100%;
    position: absolute;
    left: 7%;
  }

  .appointment-form-container {
    width: 50% !important;
    margin-bottom: 20px !important;
    padding: 0 5px 0 5px !important;
    /* display: flex !important; */
    height: 100% !important;
    min-height: 200px !important;
    margin-top: 20px !important;
  }

  .appointment-form-container:first-child {
    margin-left: 10px !important;
    margin-right: 65px !important;
  }

  .appointment-form-container-iframe {
    width: 50% !important;
    margin-bottom: 20px !important;
    padding: 0 5px 0 5px !important;
    /* display: flex !important; */
    height: 100% !important;
    min-height: 200px !important;
    margin-top: 20px !important;
  }

  .appointment-form-container-iframe:first-child {
    margin-left: 10px !important;
    margin-right: 65px;
  }

  .form-input {
    height: 2em; /* changed */
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    font-family: "Montserrat", sans-serif !important;
    padding-left: 0.5em;
  }

  /* Material UI Overridden styles */
  .MuiTabs-scrollable {
    overflow-y: hidden !important;
  }

  /* .MuiButtonBase-root {
    margin-right: 10px !important;
    padding: 6px 12px !important;
    min-width: 0 !important;
  } */

  .MuiTabs-flexContainer {
    margin-left: 0px !important;
  }

  .MuiTabs-root {
    padding-left: 15px !important;
  }

  .MuiTabs-root .PreviewPartner-subHeadings-10 {
    height: 44px !important;
  }

  .PreviewPartner-tabSelected-4 {
    height: 44px !important;
  }

  .PreviewPartner-subHeadings-10 {
    align-items: flex-start !important;
  }

  .MuiTabScrollButton-root.Mui-disabled {
    opacity: 0;
    display: none;
  }

  .calendar-box {
    border: 1px solid #eee;
    width: 350px;
  }

  .appointment-nxt-btn {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 30px 0 30px;
    position: absolute;
    top: 62%;
  }

  .appointment-nxt-btn-iframe {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 30px 0 30px;
    position: absolute;
    top: 62%;
  }
}

/* Phones */
@media only screen and (max-width: 767px) {
  .header-img {
    height: 60px !important; /* changed */
  }

  .content-area {
    background-color: #fafafa !important;
  }

  .top-content {
    box-shadow: 0 1px 0 #e5e5e5 !important;
    background-color: white !important;
  }

  .content-display {
    display: block !important; /* changed */
    margin: 3em 1em 2em 1em !important; /* changed */
  }
  .iframe {
    margin: 0em 1em 2em 1.7em !important;
  }
  .left-content {
    width: 100% !important; /* changed */
    padding-right: 0px !important; /* changed */
  }
  .iflef {
    width: 100% !important;
    padding-right: 0em !important;
  }

  .title {
    margin: 0 !important;
    font-family: "Montserrat" !important;
    font-weight: 700 !important; /* changed */
    color: #505050 !important;
    letter-spacing: 0.1px !important; /* changed */
    font-size: 2.2rem !important;
    position: inherit;
    cursor: default;
  }

  .title-inner {
    display: none;
  }

  .titleiframe {
    margin: 0 !important;
    font-family: "Montserrat" !important;
    font-weight: 700 !important; /* changed */
    color: #505050 !important;
    letter-spacing: 0.1px !important; /* changed */
    font-size: 1.8rem !important;
    position: inherit;
    cursor: default;
  }

  .button-options {
    display: flex !important;
    list-style-type: none !important;
    justify-content: space-between !important; /* changed */
    flex-wrap: wrap; /* changed */
    align-items: flex-start !important;
    margin-top: 7px !important;
  }

  .main-btns {
    display: flex !important;
    padding-right: 0px !important; /* changed */
  }

  .icon-btns {
    display: flex !important;
    padding-right: 0 !important; /* changed */
  }

  .button-list {
    margin-right: 5px !important;
    font-size: 1.1rem !important; /* changed */
  }

  .text-area {
    display: flex !important;
    justify-content: space-between !important;
    margin-top: 30px !important;
  }

  .text-description {
    font-weight: 400 !important;
    font-family: Open sans, sans-serif !important;
  }

  .display-icons {
    text-align: center !important;
    margin: 0px auto !important;
    display: flex !important;
    margin-top: 10px !important; /* changed */
    margin-bottom: 1em !important; /* changed */
    flex-wrap: wrap !important; /* Changed */
  }

  .icon-grid-view {
    display: grid !important;
    grid-template-columns: 1fr !important;
    grid-template-rows: 1fr 1fr !important;
    column-gap: 10px !important;
    row-gap: 10px !important;
  }

  .icon-containers {
    display: flex !important;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    font-family: "Quicksand", sans-serif !important;
    font-size: 12px !important;
    width: 200px !important;
  }

  .middle-icon-img {
    width: 40px !important;
    height: 40px !important;
    margin-right: 5px !important;
  }

  .icon-name {
    display: none !important;
    margin: 0 !important;
    font-weight: bold !important;
  }

  .icon-inner-text {
    display: flex !important;
    justify-content: flex-start !important;
    margin: 0 !important;
    font-size: 10px !important;
  }

  .box-img {
    display: none !important; /* changed */
    flex-wrap: wrap !important; /* Changed */
    margin-left: 0 !important; /* Changed */
  }

  .gallery-img {
    width: 60px !important; /* Changed */
    height: 60px !important; /* Changed */
    margin-right: 15px !important; /* Changed */
    cursor: pointer !important;
    object-fit: cover !important;
    border-radius: 3px !important;
  }

  .map-container {
    width: 100% !important; /* changed */
    height: 150px !important; /* changed */
  }

  .tab-headers {
    width: 100% !important;
  }

  .sub-list {
    display: flex !important;
    list-style-type: none !important;
    padding-left: 0 !important;
    justify-content: center !important;
    color: #ff6663 !important;
    font-weight: bold !important;
    font-size: 11px !important; /* changed */
    background-color: #fff !important;
    border-top: 1px solid rgb(236, 236, 236) !important;
    border-bottom: 1px solid rgb(236, 236, 236) !important;
    padding: 10px 5px !important; /* changed */
    width: 100% !important;
    margin: 0 auto !important;
    margin-left: 5px !important;
  }

  .sublist-items {
    margin-right: 7px !important; /* changed */
    cursor: pointer !important;
  }

  .sublist-items:last-child {
    margin-right: 0 !important; /* changed */
  }

  .card-container {
    padding: 10px 5px 10px 5px !important; /* changed */
    background: white !important;
  }

  .card-area {
    display: flex !important;
    justify-content: space-between !important;
    margin-bottom: 20px !important;
    width: 100% !important;
    border-radius: 7px !important;
    align-items: flex-start !important;
    padding: 10px !important; /* changed */
    cursor: pointer !important;
    background-color: white !important;
    border-bottom: 1px solid gainsboro !important;
  }

  .card-img {
    width: 93px !important; /* changed */
    height: 66px !important; /* changed */
    object-fit: cover !important;
    border-radius: 7px !important;
    box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.1) !important;
  }

  .card-content {
    display: block !important;
    margin-left: 15px !important;
  }

  .card-sub1 {
    width: 100% !important;
    padding: 0 !important; /* changed */
    margin-left: 0px !important; /* changed */
  }

  .membership-card-sub1 {
    width: 350px !important;
    padding: 0 !important; /* changed */
    margin-left: 0px !important; /* changed */
  }

  .sub1-name {
    margin: 0 !important;
    color: 333 !important;
    font-size: 1.3rem !important; /* changed */
    font-weight: bold !important;
    font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif !important;
  }

  .sub1-age {
    margin-top: 5px !important;
    font-weight: bold !important;
    color: #595959 !important;
    font-size: 1.2rem !important; /* changed */
    font-family: Lato, sans-serif !important;
  }

  .sub1-type {
    font-family: Lato, sans-serif;
    display: block;
    color: #595959;
    font-weight: bold !important;
    font-size: 1.2rem !important; /* changed */
  }

  .card-sub2 {
    width: 100% !important;
    padding: 0 !important; /* changed */
  }

  .sub2-timing {
    margin: 0 !important;
    color: 333 !important;
    font-weight: normal !important;
    font-size: 1.1rem !important; /* changed */
    font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif !important;
    letter-spacing: 0px !important; /* changed */
    color: #595959 !important;
  }

  .sub2-date {
    margin-top: 5px !important;
    color: #595959 !important;
    font-size: 1.1rem !important; /* changed */
    font-family: Lato, sans-serif !important;
    letter-spacing: 0px !important; /* changed */
  }

  .card-sub3 {
    width: 100% !important;
    padding: 0 !important; /* changed */
  }

  .sub3-pricing {
    margin: 0 !important;
    color: #192aa7 !important;
    font-weight: bold !important;
    font-size: 1.1rem !important; /* changed */
    font-family: Century Gothic, CenturyGothic, AppleGothic, sans-serif !important;
  }

  .sub3-date {
    margin-top: 5px !important;
    color: lightgrey !important;
    font-size: 1.1rem !important; /* changed */
    font-family: Lato, sans-serif !important;
  }

  .membership-img-container {
    width: 93px !important; /* changed */
    box-shadow: 4px 4px 5px #d6c3c3 !important;
    border-radius: 10px !important;
    height: 70px !important; /* changed */
  }

  .membership-img {
    width: 100% !important;
    object-fit: cover !important;
    height: 100% !important;
    border-radius: 10px !important;
  }

  .news-card {
    margin-bottom: 20px !important;
    padding: 10px !important; /* changed */
    cursor: pointer !important;
    border-radius: 7px !important;
    background-color: white !important;
    height: 160px !important; /* changed */
    border-bottom: 1px solid gainsboro !important;
  }

  .news-title {
    margin: 0 0 1em 0 !important;
    color: 333 !important;
    font-weight: bold !important;
    font-size: 1.3rem !important; /* changed */
    font-family: "Open Sans", sans-serif !important;
  }

  .news-img-container {
    max-width: 93px; /* changed */
    margin-right: 5px; /* changed */
  }

  .news-img {
    width: 100% !important;
    height: 66px !important;
    object-fit: cover !important;
    border-radius: 7px !important;
    box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.1) !important;
  }

  .news-desc {
    width: 100% !important; /* changed */
    font-family: "Open Sans", sans-serif !important;
    font-size: 1.1rem !important; /* changed */
  }

  .news-date {
    display: inline-block !important;
    margin-top: 2px !important; /* changed */
    color: grey !important;
    font-family: "Lato", sans-serif !important;
    font-size: 1rem !important; /* changed */
  }

  .gift-card {
    margin-bottom: 20px !important;
    padding: 20px !important;
    cursor: pointer !important;
    border-radius: 7px !important;
    background-color: white !important;
    height: 140px !important; /* changed */
    border-bottom: 1px solid gainsboro !important;
  }

  .appointment-calendar-box {
    display: block !important;
    align-items: center !important;
    border: 1px solid #eee;
    border-radius: 15px;
    justify-content: center !important;
    width: 85%;
    position: absolute;
    left: 9%;
  }

  .appointment-calendar-box {
    display: block !important;
    align-items: center !important;
    border: 1px solid #eee;
    border-radius: 15px;
    justify-content: center !important;
    width: 85%;
    position: absolute;
    left: 9%;
  }

  .appointment-calendar-box-iframe {
    display: block !important;
    align-items: center !important;
    border: 1px solid #eee;
    border-radius: 15px;
    justify-content: center !important;
    width: 100%;
    position: absolute;
    left: 7%;
  }

  .form-container {
    width: 100%;
    display: block;
  }

  .appointment-form-container {
    width: 100% !important;
    /* display: flex !important; */
    /* align-items: center !important;
    justify-content: center !important; */
    margin-bottom: 20px !important;
    padding: 0 5px 0 5px !important;
  }

  .appointment-form-container:first-child {
    margin-left: 0px !important;
  }

  .appointment-form-container:last-child {
    margin-left: 15px !important;
  }

  .appointment-form-container-iframe {
    width: 100% !important;
    /* display: flex !important; */
    /* align-items: center !important;
    justify-content: center !important; */
    margin-bottom: 20px !important;
    padding: 0 5px 0 5px !important;
  }

  .appointment-form-container-iframe:first-child {
    margin-left: 0px !important;
  }
  .appointment-form-container-iframe:last-child {
    margin-left: 15px !important;
  }

  .appointment-nxt-btn {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 30px 0 30px;
    position: absolute;
    top: 105%;
  }

  .appointment-nxt-btn-iframe {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0 30px 0 30px;
    position: absolute;
    top: 105%;
  }

  .form-input {
    height: 1.5em; /* changed */
    border: 1px solid #e8e8e8;
    border-radius: 3px;
    font-family: "Montserrat", sans-serif !important;
    padding-left: 0.5em;
  }

  /* Material UI Overridden styles */
  .MuiTabs-scrollable {
    overflow-y: hidden !important;
  }

  /* .MuiButtonBase-root {
    margin-right: 10px !important;
    padding: 6px 12px !important;
    min-width: 0 !important;
  } */

  .MuiTabs-flexContainer {
    margin-left: 0px !important;
  }

  .MuiTabs-root {
    padding-left: 15px !important;
  }

  .MuiTabs-root .PreviewPartner-subHeadings-10 {
    height: 44px !important;
  }

  .PreviewPartner-tabSelected-4 {
    height: 44px !important;
  }

  .PreviewPartner-subHeadings-10 .PreviewPartner-tabIndicator-5 {
    margin-bottom: 10px !important;
  }

  .PreviewPartner-subHeadings-10 {
    align-items: flex-start !important;
    margin-left: 0px !important;
  }

  .calendar-box {
    border: 1px solid #eee;
    width: 230px;
  }
}

.form-container div.file-upload-element input[type="file"] {
  position: relative;
  border: none;
}

.form-container
  div.file-upload-element
  input[type="file"]::file-selector-button {
  width: 136px;
  color: transparent;
}

/* Faked label styles and icon */
.form-container div.file-upload-element input[type="file"]::before {
  position: absolute;
  pointer-events: none;
  top: 10px;
  left: 16px;
  height: 20px;
  width: 20px;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%230964B0'%3E%3Cpath d='M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zM7 9l1.41 1.41L11 7.83V16h2V7.83l2.59 2.58L17 9l-5-5-5 5z'/%3E%3C/svg%3E");
}

.form-container div.file-upload-element input[type="file"]::after {
  position: absolute;
  pointer-events: none;
  top: 11px;
  left: 40px;
  color: #0964b0;
  content: "Upload File";
}

.form-container div.radio-group-element input[type="radio"].form-radio-element {
  width: 15px; /* Set the width of the radio button */
  height: 15px; /* Set the height of the radio button */
  border-radius: 50%; /* Make the radio button circular */
}

/* file upload button */
.form-container
  div.file-upload-element
  input[type="file"]::file-selector-button {
  border-radius: 4px;
  padding: 0 16px;
  height: 40px;
  cursor: pointer;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  margin-right: 16px;
  transition: background-color 200ms;
}

/* file upload button hover state */
.form-container
  div.file-upload-element
  input[type="file"]::file-selector-button:hover {
  background-color: #f3f4f6;
}

/* file upload button active state */
.form-container
  div.file-upload-element
  input[type="file"]::file-selector-button:active {
  background-color: #e5e7eb;
}

.checkbox-wrapper-1 *,
.checkbox-wrapper-1 ::after,
.checkbox-wrapper-1 ::before {
  box-sizing: border-box;
}
.checkbox-wrapper-1 [type="checkbox"].substituted {
  margin: 0px 0px 0px -10px;
  width: 0;
  height: 0;
  display: inline;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.checkbox-wrapper-1 [type="checkbox"].substituted + label:before {
  content: "";
  display: inline-block;
  vertical-align: top;
  height: 1em;
  width: 1em;
  margin-right: 0.6em;
  margin-top: 0.2rem;
  color: rgba(0, 0, 0, 0.275);
  border: solid 0.06em;
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em inset,
    0 0 0 0.07em transparent inset;
  border-radius: 0.2em;
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="white" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>')
      no-repeat center,
    white;
  background-size: 0;
  will-change: color, border, background, background-size, box-shadow;
  transform: translate3d(0, 0, 0);
  transition: color 0.1s, border 0.1s, background 0.15s, box-shadow 0.1s;
}

.checkbox-wrapper-1 [type="checkbox"].substituted:checked + label:before {
  background-color: #3b99fc;
  background-size: 0.75em;
  color: rgba(0, 0, 0, 0.075);
}
.checkbox-wrapper-1
  [type="checkbox"].substituted:checked:enabled:active
  + label:before,
.checkbox-wrapper-1
  [type="checkbox"].substituted:checked:enabled
  + label:active:before {
  background-color: #0a7ffb;
  color: rgba(0, 0, 0, 0.275);
}

.checkbox-wrapper-1 [type="checkbox"].substituted:disabled + label:before {
  opacity: 0.5;
}

.checkbox-wrapper-1 [type="checkbox"].substituted.dark + label:before {
  color: rgba(255, 255, 255, 0.275);
  background-color: #222;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="rgba(34, 34, 34, 0.999)" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>');
}
.checkbox-wrapper-1
  [type="checkbox"].substituted.dark:enabled:active
  + label:before,
.checkbox-wrapper-1 [type="checkbox"].substituted.dark:checked + label:before {
  background-color: #a97035;
  color: rgba(255, 255, 255, 0.075);
}
.checkbox-wrapper-1
  [type="checkbox"].substituted.dark:checked:enabled:active
  + label:before,
.checkbox-wrapper-1
  [type="checkbox"].substituted.dark:checked:enabled
  + label:active:before {
  background-color: #c68035;
  color: rgba(0, 0, 0, 0.275);
}
