.text-image-list-image {
  /*   height: 45px;
  width: 45px; */
  border-radius: 4px;
  margin-left: 0px;
  margin-right: 0px;
}

.with_border {
  border: 3px solid #ddd;
  border-radius: 7px;
}

.text-image-list-title {
  margin-right: 28px !important;
  justify-content: center;
}
