.main-pages .request-modal-cont {
  background-color: rgba(196, 196, 196, 0.32);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9000;
  display: flex;
  font-family: Lato, Helvetica, Arial, sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.01rem;
  line-height: 1.5;
}

.main-pages .request-modal {
  max-width: 500px;
  width: 100%;
  min-width: 300px;
  margin: auto;
  padding: 30px;
  z-index: 10001;
  color: black;
  max-height: 60vh;
  overflow-y: auto;
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  /* border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2); */
  border-radius: 15px;
  outline: 0;
  /* -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5); */
  -webkit-box-shadow: 0px 0px 15px 3px rgba(211, 211, 211, 0.75);
  box-shadow: 0px 0px 3px 3px rgba(211, 211, 211, 0.75);
  -ms-overflow-style: none; /* IE and Edge Scrollbar on the right */
  scrollbar-width: none; /* Firefox Scrollbar on the right */
}

.main-pages .request-modal::-webkit-scrollbar {
  display: none;
}

.main-pages .request-modal h3 {
  /* color: #31517D !important; */
  color: grey !important;
  font-size: 2rem !important;
  /* font-size: 1.5rem !important; */
  font-weight: 700 !important;
  letter-spacing: 0.1rem !important;
  /* letter-spacing: .3px !important; */
  line-height: 1.3 !important;
  margin-bottom: 1rem;
}

.main-pages .request-modal label {
  margin: 14px 0 0;
  color: gray;
  padding-bottom: 3px;
  text-align: left !important;
  font-size: 1rem;
  font-weight: normal !important;
  margin-bottom: 8px;
}

.main-pages .request-modal .modal-footer button {
  margin: 14px 3px 0 !important;
  background-color: #267fff;
  border: 1px solid #267fff;
  width: 200px;
  border-radius: 7px !important;
  color: white;
  padding: 8px;
}

.main-pages .request-modal .modal-footer .backbtn {
  background-color: #df4e4e;
  border: 1px solid #cc2727;
}

.main-pages .request-modal .modal-footer {
  /* border-top: 1px solid #e3e3e3; */
  width: fit-content;
  margin: 25px auto 0;
  padding: 0 5px 0;
}

.main-pages .request-modal .react-datepicker__day,
.main-pages .request-modal .react-datepicker__day-name {
  width: 3.5rem;
  line-height: 3.5rem;
  text-align: center;
}

.main-pages .request-modal .react-datepicker {
  font-size: 8pt;
}

.main-pages .request-modal .react-datepicker__current-month {
  font-size: 8pt;
}

.MuiTypography-body1 {
  font-family: futura-pt, sans-serif !important;
}

.react-dropdown-select {
  font-size: 1.5rem;
  border: 1px solid #c5c5c5 !important;
  border-radius: 0.5rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #626262;
}

.sigCanvas-container {
  margin-left: -14px;
}

.form-control > .DraftEditor-root {
  height: 100% !important;
}

@media (max-width: 768px) {
  .sigCanvas {
    max-width: 315px;
  }

  .sigCanvas-container {
    margin-left: -8px;
  }
}
