.required-star {
  color: rgb(255, 0, 0);
}

.field-component-title {
  font-size: 1.15rem;
  font-weight: 500;
  color: #626262;
  margin-bottom: 0 !important;
}

.field-component-input-box {
  height: 40px;
  width: 100%;
  border: 1px solid #c5c5c5 !important;
  font-family: futura-pt, sans-serif !important;
  border-radius: 4px;
  font-size: 0.93rem !important;
  color: #000000;
  padding-left: 14px;
  margin-top: 8px !important;
}

.field-component-small-text {
  font-size: 1rem;
  color: #797979;
  margin-top: 2px !important;
}

.base-modal-name-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
