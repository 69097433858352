*,
*:before,
*:after {
  box-sizing: border-box;
  outline: none;
}
.loader {
  position: relative;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 2px;
  width: 60px;
  height: 60px;
}
.loader > div {
  position: relative;
  width: 100%;
  height: 100%;
  background: #07a3ce;
  transform: scale(0);
  transform-origin: center center;
  animation: loader 2s infinite ease;
}
.loader > div:nth-of-type(1),
.loader > div:nth-of-type(5),
.loader > div:nth-of-type(9) {
  animation-delay: 0.4s;
}
.loader > div:nth-of-type(4),
.loader > div:nth-of-type(8) {
  animation-delay: 0.2s;
}
.loader > div:nth-of-type(2),
.loader > div:nth-of-type(6) {
  animation-delay: 0.6s;
}
.loader > div:nth-of-type(3) {
  animation-delay: 0.8s;
}

.loader > div:nth-of-type(3),
.loader > div:nth-of-type(7) {
  background: #5ab2ce;
}

.loader > div:nth-child(even) {
  background: #197edb;
}

@keyframes loader {
  0% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
