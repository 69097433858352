.category-tag {
  background-color: #FF6663;
  color: white;
  width: fit-content;
  border-radius: 8px;
  padding: 4px 6px;
  font-size: 13px;
  line-height: 140%;
  margin-right: 7px;
  margin-bottom: 7px;
}

.category-tag img {
  width: 16px;
  height: 16px;
}

@media only screen and (max-width: 769px) {
  .category-tag {
    font-size: 10px !important;
    padding: 2px 6px !important;
  }
  .category-tag img {
    width: 10px;
    height: 10px;
  }
}