.signin-modal {
  padding: 0px;
  position: fixed;
  height: 100% !important;
  /* height: 453px !important; */
  max-height: 453px !important;
}

.signup-modal {
  padding: 0px;

  height: 100% !important;
  /* height: 531px !important; */
  max-height: 531px !important;
}

.signin-modal .first-level-div,
.signup-modal .first-level-div {
  padding: 30px 30px 0px 30px;
}

.signin-modal h4.modal-title,
.signup-modal h4.modal-title {
  text-align: center;
  color: #343f64;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 2rem;
}

.signin-modal .intro-text,
.signup-modal .intro-text {
  text-align: center;
  width: 90%;
  margin: 0px auto 20px;
  color: gray;
}

.signin-modal .form-group,
.signup-modal .form-group {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px !important;
}

.signin-modal .form-group input,
.signup-modal .form-group input {
  width: 70%;
  padding: 20px;
}

.signin-modal .form-group .show-password-toggle,
.signup-modal .form-group .show-password-toggle {
  position: absolute;
  right: 15%;
  background-color: transparent;
}

.signin-modal button.submit-button,
.signup-modal button.submit-button {
  width: 70%;
  padding: 10px;
  background-color: #343f64;
  color: white;
  font-weight: bold;
  border: 0;
}

.signin-modal .close-button,
.signup-modal .close-button {
  position: absolute;
  top: 0px;
  right: 0px;
}

.signin-modal .signup-terms,
.signup-modal .signup-terms {
  /* width: 94%; */
  margin: 10px auto;
  align-items: center;
  text-align: center;
  color: gray;
  font-size: 13px;
  padding: 0 80px;
}

.signin-modal a,
.signup-modal a,
.signin-modal span.clickable,
.signup-modal span.clickable {
  color: #2298ff;
  cursor: pointer;
}

.signin-modal .switch-to-signup,
.signup-modal .switch-to-signin {
  width: 100%;
  padding: 20px;
  background-color: #f3f3f3;
  /* position: absolute; */
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 1001; /* to go over the <select> in signup page. */

  text-align: center;
  color: gray;
  margin: 0;
  font-family: "Merriweather", serif;
  margin-top: 50px;
}

/* Tablets */
@media only screen and (max-width: 960px) {
  .signin-modal {
    /* height: 448px !important; */
    max-height: 448px !important;
  }

  .signup-modal {
    /* height: 526px !important; */
    max-height: 526px !important;
  }

  .signin-modal .first-level-div,
  .signup-modal .first-level-div {
    padding: 25px 25px 0px 25px;
  }

  .signup-terms {
    padding: 0 73px;
  }
}

/* Phones */
@media only screen and (max-width: 640px) {
  .signin-modal {
    /* height: 453px !important; */
    max-height: 412px !important;
  }

  .signup-modal {
    /* height: 531px !important; */
    max-height: 508px !important;
  }

  .signin-modal .first-level-div,
  .signup-modal .first-level-div {
    padding: 20px 20px 20px 20px;
  }

  .signin-modal .signup-terms {
    padding: 0 40px !important;
  }
}

/* Smaller Phones */
@media only screen and (max-width: 420px) {
  .signin-modal {
    /* height: 453px !important; */
    max-height: 440px !important;
  }

  .signup-modal {
    /* height: 531px !important; */
    max-height: 535px !important;
  }
}
