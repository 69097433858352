.tui-full-calendar-month-dayname-item{
    font-family: "Montserrat";
  }
  
  .tui-full-calendar-weekday-grid-line .tui-full-calendar-weekday-grid-date {
    font-family: "Montserrat";
  }
  
  .tui-full-calendar-weekday-schedule-title{
    font-family: "Montserrat";
    font-weight: 600;
  }