.console-pages .modal {
  background: rgba(196, 196, 196, 0.32);
}

.console-pages .modal .modal-dialog {
  max-width: 546px;
  margin-top: 45px;
}

.console-pages .modal .modal-body {
  padding: 20px 40px 35px;
}

.console-pages .modal .modal-header {
  padding: 20px 21px 20px 40px;
}

.console-pages .modal p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #696969;
  margin-bottom: 10px;
}

.console-pages .modal .modal-header .close {
  padding-top: 12px;
  opacity: 1;
}

.console-pages .modal .modal-header .close:focus {
  outline: none;
}

.console-pages .modal h4 {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  color: #000000;
}

.console-pages .modal input {
  width: 100%;
  border: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #696969;
  padding: 9px 0px 10px 0px;
  border-bottom: 1px solid #cbcbcb;
  margin-bottom: 12px;
}

.console-pages .modal input:focus {
  outline: none;
}

.console-pages .modal [type="date"] {
  background: transparent url("../../assets/img/cali.svg") 97% 50% no-repeat;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  padding: 8px 0 10px 56px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
  width: 100%;
}

.console-pages
  .admin
  .adminpromotion
  .modal
  [type="date"]::-webkit-inner-spin-button {
  display: none;
}

.console-pages
  .admin
  .adminpromotion
  .modal
  [type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.console-pages .modal .fromda:before {
  content: "From:";
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #696969;
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
}

.console-pages .modal .toda:before {
  content: "To:";
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #696969;
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
}

.console-pages .modal .toda {
  margin-bottom: 20px;
}

.console-pages .modal .toda [type="date"] {
  padding: 8px 0 10px 35px;
}

.console-pages .modal select {
  display: block;
  border: 0;
  border-radius: 4px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  background: transparent url("../../assets/img/selectarrowpop.svg") 97% 55%
    no-repeat;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  border: 1px solid #c4c4c4;
  line-height: normal;
  color: #7b7b7b;
  margin: 0;
  padding: 7px 10px;
  margin-bottom: 16px;
}

.console-pages .modal select:focus {
  outline: none;
}

.console-pages .modal .input1:before {
  content: "Dollars";
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  position: absolute;
  margin-top: 10px;
  margin-left: 20px;
  z-index: 0;
}

.console-pages .modal .input1 input {
  padding-left: 90px;
  position: relative;
  z-index: 1;
  background: transparent;
  color: #000;
  margin-bottom: 5px;
}

.console-pages .modal .input1 .unactive {
  position: absolute;
  margin-top: 16px;
  margin-left: 4px;
}

.console-pages .modal .input1 .activepop {
  position: absolute;
  margin-top: 16px;
  margin-left: 4px;
  display: none;
}

.console-pages .modal .input2 input {
  padding-left: 120px;
  position: relative;
  z-index: 1;
  background: transparent;
  color: #000;
}

.console-pages .modal .input2 .unactive {
  position: absolute;
  margin-top: 16px;
  margin-left: 4px;
}

.console-pages .modal .input2 .activepop {
  position: absolute;
  margin-top: 16px;
  margin-left: 4px;
  display: none;
}

.console-pages .modal .input2:before {
  content: "Percentage";
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  position: absolute;
  margin-top: 10px;
  margin-left: 20px;
  z-index: 0;
}

.console-pages .modal .activeinput .activepop {
  display: block;
}

.console-pages .modal textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  min-height: 150px;
  max-width: 100%;
  min-width: 100%;
  margin-bottom: 20px;
}

.console-pages .modal textarea:focus {
  outline: none;
}

.console-pages .modal .buttons {
  text-align: right;
  margin-bottom: 0px;
  margin-top: 10px;
}

.console-pages .modal .buttons .nextbtn {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #7ea1ff;
  display: inline-block;
  background: transparent;
  border: 1px solid #7ea1ff;
  padding: 10px 15px;
  border-radius: 5px;
  margin-right: 6px;
}

.console-pages .modal .buttons a:hover {
  text-decoration: none;
  opacity: 0.7;
}

@media (max-width: 700px) {
  .console-pages .modal .modal-body * {
    font-size: 10px !important;
  }

  .console-pages .modal .modal-body {
    padding: 20px;
  }

  .console-pages .modal .modal-header {
    padding: 20px;
  }
}
