@import url("https://use.typekit.net/tsh3lwo.css");

.application-header {
  color: black;
  font-size: 15px;
  font-weight: 700;
  font-family: futura-pt, sans-serif !important;
  flex-grow: 1;
}

.btn-section {
  display: flex;
}

.back-btn {
  width: 100px;
  height: 40px;
  padding: 10px;
  background-color: #fff;
  color: rgb(52, 63, 100) !important;
  font-weight: bold;
  border: 1px solid rgb(52, 63, 100);
  display: flex;
  justify-content: center;
  align-items: center;
}

.next-btn {
  width: 100px;
  height: 40px;
  padding: 10px;
  background-color: rgb(42 65 162);
  color: white !important;
  font-weight: bold;
  border: 1px solid rgb(52, 63, 100);
  margin-left: 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.choose-child {
  flex-grow: 1;
  font-size: 18px;
}

.add-btn {
  /* font-size: 12px;
  color: firebrick;
  cursor: pointer;
  border: 1px solid gainsboro;
  padding: 15px 20px; */
  font-size: 14px;
  padding: 6px 14px;
  border-radius: 10px;
  /* margin-right: 20px; */
  color: white;
  font-weight: 700;
  background: rgb(92, 200, 220);
  border: 1px solid rgb(92, 200, 220);
  box-shadow: rgb(205 205 205) 2px 2px 5px 2px;
}

.choose-child-items {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 30px;
}

.choose-label {
  color: grey;
  margin: 0 0px 0px 0px;
  font-weight: 500;
  width: 30%;
}

.choose-select {
  width: 30%;
  padding: 10px;
}

.show-summary-title-section {
  margin: 0;
  color: black;
  display: flex;
  justify-content: space-between;
  font-family: futura-pt, sans-serif !important;
}

.show-summary-title-text {
  flex-grow: 1;
  font-size: 18px;
  color: #232795;
}

.show-summary-edit-btn {
  font-size: 12px;
  color: white;
  cursor: pointer;
  border: 1px solid gainsboro;
  padding: 8px 20px;
  background: #ff6663;
  border-radius: 5px;
}

.show-summary-choose-btn {
  font-size: 12px;
  color: firebrick;
  cursor: pointer;
  border: 1px solid gainsboro;
  padding: 15px 20px;
  margin-left: 15px;
}

.show-summary-form-items {
  display: flex;
  align-items: center;
}

.show-form-item {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.show-label {
  color: #514848;
  margin: 0 0px 0px 0px;
  font-weight: bold;
  width: 125px;
  font-family: futura-pt, sans-serif !important;
}

.show-value {
  font-family: futura-pt, sans-serif !important;

  margin-bottom: 5px !important;
  margin-left: 8px !important;
}

.payment-wrapper {
  width: 80%;
  padding: 20px;
  margin: 0 auto;
  padding-bottom: 60px;
}

.payment-card {
  width: 80%;
  margin: 0 auto;
  background-color: rgb(252 252 252 / 1);
  border-radius: 20px;
  border: 1px solid lightgrey;
  padding: 0px 25px;
}

.dob-border {
  border: 1px solid #e8e8e8;
  padding: 1px 2px 1px 7.5px;
  height: 2.9em;
}

.red-border {
  border: 1px solid red !important;
}

.show-value-input:disabled {
  background: #f8f8f8 !important;
}

/* Tablets */
@media only screen and (max-width: 960px) {
  .show-summary-form-items {
    display: block; /* changed */
  }

  .show-form-item {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* changed */
  }

  .show-label {
    color: #595959;
    margin: 0 0px 0px 0px;
    font-weight: bold;
    width: 125px; /* changed */
  }

  .show-value {
    width: 60%; /* changed */
  }
}

/* Phones */
@media only screen and (max-width: 767px) {
  .application-header {
    color: grey;
    font-size: 12px; /* changed */
  }

  .back-btn {
    width: 60px; /* changed */
    padding: 5px; /* changed */
    background-color: #fff;
    color: rgb(52, 63, 100) !important;
    font-weight: bold;
    border: 1px solid rgb(52, 63, 100);
  }

  .next-btn {
    width: 60px; /* changed */
    padding: 5px; /* changed */
    background-color: rgb(42 65 162);
    color: white !important;
    font-weight: bold;
    border: 1px solid rgb(42 65 162);
    margin-left: 10px !important; /* changed */
  }

  .choose-child {
    flex-grow: 1;
    font-size: 16px; /* changed */
  }

  .add-btn {
    /* font-size: 12px;
    color: firebrick;
    cursor: pointer;
    border: 1px solid gainsboro;
    padding: 5px 10px; changed */
    font-size: 14px;
    padding: 6px 14px;
    border-radius: 10px;
    /* margin-right: 20px; */
    color: white;
    font-weight: 700;
    background: rgb(92, 200, 220);
    border: 1px solid rgb(92, 200, 220);
    box-shadow: rgb(205 205 205) 2px 2px 5px 2px;
  }

  .choose-child-items {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; /* changed */
    margin-bottom: 20px;
    margin-top: 30px;
  }

  .choose-label {
    color: grey;
    margin: 0 0px 0px 0px;
    font-weight: 500;
    width: 38%; /* changed */
    font-size: 12px; /* changed */
  }

  .choose-select {
    width: 60%; /* changed */
    padding: 10px; /* changed */
  }

  .show-summary-title-text {
    flex-grow: 1;
    font-size: 12px; /* changed */
  }

  .show-summary-edit-btn {
    font-size: 12px;
    color: white;
    cursor: pointer;
    border: 1px solid gainsboro;
    padding: 5px 10px; /* changed */
    background: #ff6663;
    border-radius: 5px;
  }

  .show-summary-choose-btn {
    font-size: 12px;
    color: firebrick;
    cursor: pointer;
    border: 1px solid gainsboro;
    padding: 5px 10px; /* changed */
    margin-left: 7px; /* changed */
  }

  .show-summary-form-items {
    display: block; /* changed */
  }

  .show-form-item {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* changed */
  }

  .show-label {
    color: #595959;
    margin: 0 0px 0px 0px;
    font-weight: bold;
    width: 50%; /* changed */
    font-size: 12px; /* changed */
  }

  .show-value {
    font-size: 12px; /* changed */
    width: 50%; /* changed */
  }

  .payment-wrapper {
    width: 105%;
    padding: 20px;
    margin: 0 auto;
    padding-bottom: 60px;
  }

  .payment-card {
    width: 110%; /* changed */
    margin: 0 auto;
    background-color: rgb(252 252 252 / 1);
    border-radius: 20px;
    border: 1px solid lightgrey;
    padding: 0px 5px; /* changed */
  }
}

/* copied from liveView.css */
.cart-checkout-app-forms-section .line-container-full .form-container {
  width: 100%;
  align-items: center;
  padding: 0 15px;

  margin-bottom: 20px;
}

.form-container input.textbox-element {
  width: 500px;
  max-width: 100%;
  border: 1px solid #e8e8e8;
  height: 4rem;
  padding: 0px 7px 0px 7px;
}
.form-container textarea.text-area-element {
  width: 100%;
  border: 1px solid #e8e8e8;
}

.form-container div.radio-group-element {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border: none;
  padding-left: 5px;
  margin-right: 10px;
}
.form-container div.radio-group-element > div {
  margin-right: 10px;
}
.form-container div.radio-group-element input[type="radio"] {
  margin: 0px 5px;
}

.form-container div.radio-group-element input[type="radio"].form-radio-element {
  width: 15px; /* Set the width of the radio button */
  height: 15px; /* Set the height of the radio button */
  border-radius: 50%; /* Make the radio button circular */
}

.form-container div.checkbox-group-element {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.form-container div.checkbox-group-element > div {
  display: flex;
  align-items: center;
  border: none;
  padding-left: 5px;
  margin-right: 10px;
}
.form-container div.checkbox-group-element input[type="checkbox"] {
  margin: 0px 5px;
}

.form-container div.policy-group-element {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.form-container div.policy-group-element div.policy-checkbox {
  display: flex;
  margin: -5px 0px 0px 0px;
}
.form-container div.policy-group-element input[type="checkbox"] {
  margin-right: 10px;
}

.file-upload-element {
  width: 150px;
  display: flex;
  flex-wrap: wrap;
}
.file-upload-element > div {
  width: 100%;
  /* border: 1px dashed #e8e8e8; */
  padding: 4px 2px;
}

@media (max-width: 768px) {
  .file-upload-element {
    /* Set a specific width or use flexbox/grid to adjust the size */
    width: 200px;
    /* Add a fixed height or allow it to adjust based on content */
    height: auto;
    /* Enable scrolling if the content exceeds the height */
    overflow: auto;
  }

  .filename-label {
    /* Allow text to wrap within the container */
    word-wrap: break-word;
  }
}

.file-info-container {
  display: flex;
  align-items: center;
}

.file-upload-element span.close-button {
  margin-left: 6px;
  color: darkred;
  cursor: pointer;
}
.file-upload-element input[type="file"] {
  width: 100%;
  border-radius: 5px;
}

.form-container div.signature-element {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

#form-element-dob {
  width: 155px !important;
}
/* the following styles affect the time-picker:
  #1- hide disabled time options;
  #2- thin separator line between time ranges; */
.program-sessions-time-picker li.react-datepicker__time-list-item--disabled {
  display: none;
}
.program-sessions-time-picker
  li.react-datepicker__time-list-item--disabled
  + li:not(.react-datepicker__time-list-item--disabled) {
  border-top: 2px solid gray;
}

.file-upload-element input[type="file"] {
  position: relative;
  border: none;
}

.file-upload-element input[type="file"]::file-selector-button {
  width: 136px;
  color: transparent;
}

/* Faked label styles and icon */
.file-upload-element input[type="file"]::before {
  position: absolute;
  pointer-events: none;
  top: 10px;
  left: 16px;
  height: 20px;
  width: 20px;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%230964B0'%3E%3Cpath d='M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zM7 9l1.41 1.41L11 7.83V16h2V7.83l2.59 2.58L17 9l-5-5-5 5z'/%3E%3C/svg%3E");
}

.file-upload-element input[type="file"]::after {
  position: absolute;
  pointer-events: none;
  top: 11px;
  left: 40px;
  color: #0964b0;
  content: "Upload File";
}

/* ------- From Step 1 ------- */

/* file upload button */
.file-upload-element input[type="file"]::file-selector-button {
  border-radius: 4px;
  padding: 0 16px;
  height: 40px;
  cursor: pointer;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.16);
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  margin-right: 16px;
  transition: background-color 200ms;
}

/* file upload button hover state */
.file-upload-element input[type="file"]::file-selector-button:hover {
  background-color: #f3f4f6;
}

/* file upload button active state */
.file-upload-element input[type="file"]::file-selector-button:active {
  background-color: #e5e7eb;
}

.checkbox-wrapper-1 *,
.checkbox-wrapper-1 ::after,
.checkbox-wrapper-1 ::before {
  box-sizing: border-box;
}
.checkbox-wrapper-1 [type="checkbox"].substituted {
  margin: 0px 0px 0px -10px;
  width: 0;
  height: 0;
  display: inline;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.checkbox-wrapper-1 [type="checkbox"].substituted + label:before {
  content: "";
  display: inline-block;
  vertical-align: top;
  height: 1em;
  width: 1em;
  margin-right: 0.6em;
  margin-top: 0.2rem;
  color: rgba(0, 0, 0, 0.275);
  border: solid 0.06em;
  box-shadow: 0 0 0.04em, 0 0.06em 0.16em -0.03em inset,
    0 0 0 0.07em transparent inset;
  border-radius: 0.2em;
  background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="white" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>')
      no-repeat center,
    white;
  background-size: 0;
  will-change: color, border, background, background-size, box-shadow;
  transform: translate3d(0, 0, 0);
  transition: color 0.1s, border 0.1s, background 0.15s, box-shadow 0.1s;
}

.checkbox-wrapper-1 [type="checkbox"].substituted:checked + label:before {
  background-color: #3b99fc;
  background-size: 0.75em;
  color: rgba(0, 0, 0, 0.075);
}
.checkbox-wrapper-1
  [type="checkbox"].substituted:checked:enabled:active
  + label:before,
.checkbox-wrapper-1
  [type="checkbox"].substituted:checked:enabled
  + label:active:before {
  background-color: #0a7ffb;
  color: rgba(0, 0, 0, 0.275);
}

.checkbox-wrapper-1 [type="checkbox"].substituted:disabled + label:before {
  opacity: 0.5;
}

.checkbox-wrapper-1 [type="checkbox"].substituted.dark + label:before {
  color: rgba(255, 255, 255, 0.275);
  background-color: #222;
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve" fill="rgba(34, 34, 34, 0.999)" viewBox="0 0 9 9"><rect x="0" y="4.3" transform="matrix(-0.707 -0.7072 0.7072 -0.707 0.5891 10.4702)" width="4.3" height="1.6" /><rect x="2.2" y="2.9" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 12.1877 2.9833)" width="6.1" height="1.7" /></svg>');
}
.checkbox-wrapper-1
  [type="checkbox"].substituted.dark:enabled:active
  + label:before,
.checkbox-wrapper-1 [type="checkbox"].substituted.dark:checked + label:before {
  background-color: #a97035;
  color: rgba(255, 255, 255, 0.075);
}
.checkbox-wrapper-1
  [type="checkbox"].substituted.dark:checked:enabled:active
  + label:before,
.checkbox-wrapper-1
  [type="checkbox"].substituted.dark:checked:enabled
  + label:active:before {
  background-color: #c68035;
  color: rgba(0, 0, 0, 0.275);
}

.button-39 {
  background-color: #ffffff;
  border: 1px solid rgb(209, 213, 219);
  border-radius: 0.5rem;
  box-sizing: border-box;
  color: #111827 !important;
  font-family: "Inter var", ui-sans-serif, system-ui, -apple-system, system-ui,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 0.75rem 1rem;
  text-align: center;
  text-decoration: none #d1d5db solid;
  text-decoration-thickness: auto;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  border-radius: 5px;
}

.button-39:hover {
  color: #111827 !important;
  background-color: rgb(249, 250, 251);
}

.button-39:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.button-39:focus-visible {
  box-shadow: none;
}

#checkout-form-textarea {
  font-family: "Open sans", sans-serif !important;
  font-weight: 500 !important;
  padding: 10px 5px 10px 5px !important;
}
