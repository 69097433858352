#page-title{
    font-family: "Open Sans",sans-serif !important;
    font-weight: 700 !important;
}

.box-header{
    font-family: "Open Sans",sans-serif !important;
    font-weight: 700 !important;
    font-size: 15px !important;
    color: #143fa3 !important;
}

.label-names{
    font-size: 13px !important;
    color: #535353 !important;
}

.input-fields{
    font-family: "Open Sans",sans-serif !important;
    font-size: 13px !important;
    color: #4b1f1f !important;
    border: 1px solid #e9e6e6 !important;
    padding: 5px !important;
    padding-left: 10px !important;
    /* overflow-y: scroll;
    scrollbar-width: thin; */
}

.scrollbar-mini{}

.scrollbar-mini::-webkit-scrollbar {
    width: 5px;
  }

.scrollbar-mini::-webkit-scrollbar-track {
    background: grey;
}

.text-border-color{}

.text-border-color:focus{
    border: 1px solid gainsboro;
    border-color: gainsboro;
}

.input-fields::placeholder {
    color: #beb8b8 !important;
  }

/* Tag BG colors  */
.css-w3djcp-OptionComponent {
    background: #ff6663 !important;
}


/* Geosuggest library styling */
/* .geosuggest__input{
    font-family: "Open Sans",sans-serif !important;
    font-size: 13px !important;
    color: #4b1f1f !important;
    border: 1px solid #e9e6e6 !important;
    padding: 5px !important;
    padding-left: 10px !important;
}

.geosuggest__input-wrapper{
    font-family: "Open Sans",sans-serif !important;
    font-size: 13px !important;
    color: #4b1f1f !important;
    border: 1px solid #e9e6e6 !important;
    padding: 5px !important;
    padding-left: 10px !important;
}

.n-search-blue input{
    border-bottom: none !important;
} */

.operational-hours-container{
    width: 100%;
}

.remove-btn{
    color: red;
    cursor: pointer;
}