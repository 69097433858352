.MuiButtonBase-root {
    /* padding: 0px 4px !important; */
    margin-right: 10px !important;
    min-width: 0 !important;
  }


.MuiTabs-root{
  padding-left: 80px !important;
}


/* Tablets */
@media only screen and (max-width: 960px){
  .MuiTabs-root{
    padding-left: 30px !important;
  }
}

/* Phones */
@media only screen and (max-width: 767px){
  .MuiTabs-root{
    padding-left: 20px !important;
  }
}