.header-img {
  height: 80px !important;
}

.content-area-kids {
  background-color: #fafafa !important;
}

.top-content-kids {
  background-color: white !important;
}
.galwdt {
  width: 100%;
  vertical-align: middle;
  font-size: 1rem;
}
.tag {
  background-color: #fd6e6e;
  padding: 3px;
  font-size: 12px;
  margin-right: 10px;
  color: white;
  border-radius: 8px;
  margin-top: 10px;
  flex-basis: 115px;
  text-align: center;
}
.littlemarg {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
}
.content-display-kids {
  display: flex !important;
  margin: 3em 5em 0em 5em !important;
}
.content-display-no-flex {
  margin: 3em 5em 6em 5em !important;
}

.spanview {
  font-size: 12px;
  font-family: Montserrat;
  padding: 5px;
  margin-top: 10px;
  margin-right: 15px;
  color: white;
  border-radius: 8px;
}
.left-content-kids {
  width: 66%;
  padding-right: 4em !important;
}

.right-content-kids {
  width: 33%;
  background-color: #F1F1F1;
  padding-left: 25px;
  border-radius: 25px;
  padding-right: 25px;
  padding-bottom: 10px;
  margin-bottom: auto;
}

.contacts-wrapper img {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.contacts-wrapper {
  font-size: 18px;
  padding-left: 2px;
  margin-top: 10px;
}

.contacts-wrapper div {
  display: flex;
  align-items: center;
}

.top-content-kids .sub-title {
  font-size: 28px !important;
  margin-bottom: 5px !important;
  margin-top: 20px !important;
}

#gallery-title {
  margin-top: 50px !important;
}

#location-title {
  margin-top: 0px !important;
}

.title-kids {
  margin: 0 !important;
  font-family: Montserrat, sans-serif !important;
  font-weight: 700 !important;
  color: #505050 !important;
  margin-bottom: 8px !important;
}

.button-options {
  display: flex !important;
  justify-content: space-between !important;
  list-style-type: none !important;
  padding-left: 0 !important;
  align-items: flex-start !important;
  margin-top: 1em !important;
}

.main-btns-kids {
  display: flex !important;
}

.left-content-kids .icon-btns {
  display: flex !important;
  padding-right: 0px !important;
}

.button-list {
  margin-right: 5px !important;
  font-size: 1.7rem !important;
}

.text-area-kids {
  display: flex !important;
  justify-content: space-between !important;
  margin-top: 0px !important;
}

.text-description {
  font-weight: 400 !important;
  font-family: Open sans, sans-serif !important;
}

.explore-programs-wrapper {
  width: 100% !important;
  margin-bottom: 60px;
}

.explore-programs-wrapper .sub-title {
  color: #052470 !important;
  margin-top: 0px !important;
}
.kids-programs-panel-wrapper {
  padding: 10px;
  border-radius: 5px;
  border-width: 2px;
  border-style: solid;
  border-color: #D9D9D9;
  padding-bottom: 0px;
}

.kids-programs-panel {
  display: flex;
  overflow: scroll;
  width: 100%;
  padding-bottom: 10px;
}

.kid-program-card {
  padding-right: 10px;
}

.display-icons-kids {
  text-align: center !important;
  margin: 0px auto !important;
  display: flex !important;
  margin-top: 40px !important;

  flex-wrap: wrap;
}

.icon-containers-kids {
  display: flex !important;
  align-items: center !important;
  margin-right: 40px !important;
  margin-bottom: 30px;

  align-items: center !important;
  font-family: "Quicksand", sans-serif !important;
  font-size: 12px !important;
  flex-basis: calc(100vw * 0.13);
}

.icon-img-kids {
  width: calc(100vw * 0.05) !important;
  max-height: calc(100%) !important;
  margin-right: 1em !important;
}

.icon-name-kids {
  display: block !important;
  margin: 0 !important;
  font-weight: bold !important;
}

.icon-inner-text-kids {
  margin: 0 !important;
  font-size: 12px !important;
}

.box-img {
  display: flex !important;
  margin-left: 1.9em !important;
}

.gallery-img {
  width: 80px !important;
  height: 80px !important;
  margin-right: 15px !important;
  cursor: pointer !important;
  object-fit: cover !important;
  border-radius: 3px !important;
}

.new-gallery-img {
  width: 250px;
  height: 250px;
  margin: 10px;
}

.map-container-kids {
  width: 30% !important;
  height: inherit !important;
}

.tab-headers {
  width: 100% !important;
}

.sub-list {
  display: flex !important;
  list-style-type: none !important;
  padding-left: 0 !important;
  justify-content: flex-start !important;
  color: #ff6663 !important;
  font-weight: bold !important;
  font-size: 14px !important;
  background-color: #fff !important;
  border-top: 1px solid rgb(236, 236, 236) !important;
  border-bottom: 1px solid rgb(236, 236, 236) !important;
  padding: 6px 70px 6px 70px !important;
  width: 100% !important;
  margin: 0 auto !important;
}

.sublist-items {
  margin-right: 25px !important;
  cursor: pointer !important;
}

.alignement {
  text-align: left;
}

.abootschool {
  font-family: "Open Sans", sans-serif !important;
  font-weight: 600 !important;
  font-size: 26px !important;
  letter-spacing: 5px !important;
  margin: 0 !important;
  margin-bottom: 10px !important;
}


.open-hours-container-expanded{
  /* height: 238px; */
  border: 1px solid #eceaea;
  border-radius: 5px;
  margin-top: 15px;
  position: relative;
}

.open-hours-container-contained{
  height: 50px;
  border: 1px solid #eceaea;
  border-radius: 5px;
  margin-top: 15px;
  /* overflow: hidden; */
  position: relative;
}

.verification-container {
  white-space: nowrap;
}

.show-hours-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 1px solid #ff7f7f;
  height: 21px;
  width: 22px;
  border-radius: 50%;
  position: absolute;
  bottom: -13px;
  left: 50%;
  /* z-index: 9999; */
  opacity: 1;
  background: white;
}

.open-hours{
  padding: 10px;
}

.open-hours-title{
  color: #8e8484;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  margin-bottom: 17px;
  display: flex;
  align-items: center;
  margin-top: 3px;
  font-size: 13px
}

.week-timings{
  border-bottom: 1px solid gainsboro;
}

.week-timings:last-child{
  border-bottom: none;
}

.daily-timings{
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
  margin-top: 2px;
}

.days-of-week{
  font-family: "Quicksand", sans-serif;
  font-size: 20px;
  width: 68px;
}

.days-timings{
  font-family: "Quicksand", sans-serif;
  font-size: 16px;
}

/* Tablets */
@media only screen and (max-width: 960px) {
  .header-img {
    height: 90px !important; /* changed */
  }

  .content-area-kids {
    background-color: #fafafa !important;
  }

  .top-content-kids {
    background-color: white !important;
  }

  .content-display-kids {
    display: flex !important;
    margin: 3em 1em 0em 1em !important;
  }

  .left-content-kids {
    width: 66% !important;
    padding-right: 4em !important;
  }

  .right-content-kids {
    width: 33% !important;
  }

  .days-of-week {
    font-size: 15px;
  }

  .days-timings {
    font-size: 12px;
  }

  .title-kids {
    margin: 0 !important;
    font-family: Montserrat, sans-serif !important;
    font-weight: 700 !important;
    color: #505050 !important;
  }
  .alignement {
    text-align: justify;
    margin-right: auto !important;
  }

  .button-options {
    display: flex !important;
    list-style-type: none !important;
    padding-left: 0 !important;
    justify-content: flex-start !important; /* changed */
    flex-wrap: wrap; /* changed */
    align-items: flex-start !important;
    margin-top: 1em !important;
  }

  .main-btns-kids {
    display: flex !important;
  }

  .icon-btns {
    display: flex !important;
    padding-right: 0 !important; /* changed */
  }

  .button-list {
    margin-right: 5px !important;
    font-size: 1.3rem !important; /* changed */
  }

  .text-area {
    display: flex !important;
    justify-content: space-between !important;
    margin-top: 30px !important;
  }

  .contacts-wrapper {
    font-size: 15px;
    padding-left: 2px;
    margin-top: 10px;
  }

  .contacts-wrapper img {
    width: 20px;
    height: 20px;
  }

  .text-description {
    font-weight: 400 !important;
    font-family: Open sans, sans-serif !important;
  }

  .display-icons-kids {
    text-align: center !important;
    margin: 0px auto !important;
    display: flex !important;
    padding-left: 0px;
    margin-top: 20px !important; /* Changed */
    flex-wrap: wrap !important; /* Changed */
  }

  .icon-containers-kids {
    display: flex !important;
    align-items: center !important;
    margin-right: 0 !important; /* changed */
    flex-direction: column !important;
    align-items: center !important;
    font-family: "Quicksand", sans-serif !important;
    font-size: 12px !important;
    margin: 5px !important; /* changed */
  }

  .icon-img-kids {
    width: 70px !important; /* changed */
    height: 70px !important; /* changed */
    margin-bottom: 1em !important;
    margin-right: auto !important;
  }

  .icon-name-kids {
    display: block !important;
    margin: 0 !important;
    font-weight: bold !important;
  }

  .icon-inner-text-kids {
    margin: 0 !important;
    font-size: 12px !important;
  }

  .box-img {
    display: flex !important;
    margin-left: 0 !important; /* Changed */
  }

  .gallery-img {
    width: 80px !important;
    height: 80px !important;
    margin-right: 15px !important;
    cursor: pointer !important;
    object-fit: cover !important;
    border-radius: 3px !important;
  }

  .map-container-kids {
    width: 40% !important;
    height: inherit !important;
  }

  .tab-headers {
    width: 100% !important;
  }

  .sub-list {
    display: flex !important;
    list-style-type: none !important;
    padding-left: 0 !important;
    justify-content: flex-start !important;
    color: #ff6663 !important;
    font-weight: bold !important;
    font-size: 14px !important;
    background-color: #fff !important;
    border-top: 1px solid rgb(236, 236, 236) !important;
    border-bottom: 1px solid rgb(236, 236, 236) !important;
    padding: 6px 50px 6px 50px !important; /* changed */
    width: 100% !important;
    margin: 0 auto !important;
  }

  .sublist-items {
    margin-right: 25px !important;
    cursor: pointer !important;
  }
}

/* Phones */
@media only screen and (max-width: 769px) {
  .header-img {
    height: 65px !important; /* changed */
  }

  .content-area-kids {
    background-color: #fafafa !important;
  }

  .top-content-kids {
    background-color: white !important;
  }

  .content-display-kids {
    display: block !important; /* changed */
    margin: 3em 1em 2em 1em !important; /* changed */
  }

  .content-display-no-flex {
    margin: 3em 1em 2em 1em !important;
  }
  .galwdt {
    width: 100%;
    vertical-align: middle;
    font-size: 1rem;
  }

  .left-content-kids {
    width: 100% !important; /* changed */
    padding-right: 0px !important; /* changed */
    margin-top: 20px;
  }

  .right-content-kids {
    width: 100% !important;
    padding-top: 1px !important;
  }

  .contacts-wrapper {
    font-size: 18px;
  }

  .contacts-wrapper img {
    width: 24px;
    height: 24px;
  }

  .title-kids {
    margin: 0 !important;
    font-family: Montserrat, sans-serif !important;
    font-weight: 700 !important; /* changed */
    color: #505050 !important;
    letter-spacing: 0.1px !important; /* changed */
    font-size: 22px !important;
  }

  .button-options {
    display: flex !important;
    list-style-type: none !important;
    justify-content: flex-start !important; /* changed */
    flex-wrap: wrap; /* changed */
    align-items: flex-start !important;
    margin-top: 1em !important;
  }

  .main-btns-kids {
    display: flex !important;
    padding-right: 45px !important; /* changed */
  }

  .icon-btns {
    display: flex !important;
    padding-right: 0 !important; /* changed */
  }

  .button-list {
    margin-right: 5px !important;
    font-size: 1.1rem !important; /* changed */
  }

  .text-area {
    display: flex !important;
    justify-content: space-between !important;
    margin-top: 30px !important;
  }

  .text-description {
    font-weight: 400 !important;
    font-family: Open sans, sans-serif !important;
  }

  .display-icons-kids {
    text-align: center !important;
    margin: 0px auto !important;
    display: flex !important;
    justify-content: flex-start !important;
    margin-top: 10px !important; /* changed */
    margin-bottom: 1em !important; /* changed */
    flex-wrap: wrap !important; /* Changed */
  }

  .icon-containers-kids {
    display: flex !important;
    align-items: center !important;
    margin-right: 40px !important;
    flex-direction: column !important;
    align-items: center !important;
    font-family: "Quicksand", sans-serif !important;
    font-size: 12px !important;
    margin: 10px !important; /* changed */
    flex-basis: calc(80% / 3);
  }

  .kids-program-description {
    font-size: 15px !important;
  }

  .icon-img-kids {
    width: 50px !important; /* changed */
    height: 50px !important; /* changed */
    margin-bottom: 1em !important;
  }

  .icon-name-kids {
    display: block !important;
    margin: 0 !important;
    font-weight: bold !important;
  }

  .icon-inner-text-kids {
    margin: 0 !important;
    font-size: 10px !important;
    word-break: normal;
  }

  .spanview {
    padding: 1px 5px;
    font-size: 10px;
  }

  .box-img {
    display: none !important; /* changed */
    flex-wrap: wrap !important; /* Changed */
    margin-left: 0 !important; /* Changed */
  }

  .top-content-kids .sub-title {
    font-size: 25px !important;
  }

  .tag {
    flex-basis: 105px;
  }
  .gallery-img {
    width: 80px !important; /* Changed */
    height: 80px !important; /* Changed */
    margin-right: 15px !important; /* Changed */
    cursor: pointer !important;
    object-fit: cover !important;
    border-radius: 3px !important;
  }

  .new-gallery-img {
    width: 150px;
    height: 150px;
  }

  .map-container-kids {
    margin-top: 20px;
    width: 100% !important; /* changed */
  }

  .tab-headers {
    width: 100% !important;
  }

  .sub-list {
    display: flex !important;
    list-style-type: none !important;
    padding-left: 0 !important;
    justify-content: center !important;
    color: #ff6663 !important;
    font-weight: bold !important;
    font-size: 11px !important; /* changed */
    background-color: #fff !important;
    border-top: 1px solid rgb(236, 236, 236) !important;
    border-bottom: 1px solid rgb(236, 236, 236) !important;
    padding: 10px 5px !important; /* changed */
    width: 100% !important;
    margin: 0 auto !important;
    margin-left: 5px !important;
  }

  .sublist-items {
    margin-right: 7px !important; /* changed */
    cursor: pointer !important;
  }
  .spaniblock {
    display: block;
    margin-top: 20px;
    font-family: Montserrat;
    font-size: 12px;
  }

  .sublist-items:last-child {
    margin-right: 0 !important; /* changed */
  }
}
