/* .dropdown-select-field {
  height: 40px;
  width: 100%;
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  font-size: 0.93rem !important;
  padding-left: 14px;
  margin-top: 8px !important;
} */

.dropdown-select-field {
  padding-right: 14px;
}

.dropdown-select-grey {
  color: #797979 !important;
}

.dropdown-select-black {
  color: #000 !important;
}

.dropdown-select-invalid {
  border: 1px solid rgb(255, 0, 0);
}
