.days .day {
  cursor: pointer;
  user-select: none;

  padding: 12px 0;
  font-size: 14px;
  text-align: center;
  color: #797979;
  border-right:  1px solid #c5c5c5;
  border-top:  1px solid #c5c5c5;
  border-bottom: 1px solid #c5c5c5;
  background-color: #fff;
  margin: 0;
  float: left !important;
  width: 14% !important;
  display: inline-block !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.days .daytoni {
  cursor: pointer;
  user-select: none;

  padding: 12px 0;
  font-size: 14px;
  text-align: center;
  color: #797979;
  border-right:  1px solid #c5c5c5;
  border-top:  1px solid #c5c5c5;
  border-bottom: 1px solid #c5c5c5;
  background-color: #fff;
  margin: 0;
  float: left !important;
  width: 20% !important;
  display: inline-block !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.days .daytoni.day-monday {
  border-left: 1px solid #c5c5c5;
  border-radius: 4px 0 0 4px;
}
.days .day.day-monday {
  border-left: 1px solid #c5c5c5;
  border-radius: 4px 0 0 4px;
}
.childofdiv {
  width: 100%;
}
.days {
  margin: 0 auto;
  text-align: center;
}
.days .daytoni.day-sunday {
  border-radius: 0 4px 4px 0;
}
.days .day.day-sunday {
  border-radius: 0 4px 4px 0;
  display: block;
  width: 16% !important;
}
.selecta {
  color: white !important;
  background-color: #0635BA !important;
  border-color: none !important;
}

.inputStyle {
  all: none !important;
  border: 1px solid grey !important;
  border-radius: 10px;
}
.inputStyle input {
  border-bottom: none !important;
}
.submenu:hover {
  text-decoration: none !important;
}
.tableticket {
  margin-top: 20px;
}
.new-heaven .completeline {
  /* background-color: #fff !important; */
}
.new-heaven .justify-evenly {
  display: flex !important;
  justify-content: center !important;
}
.new-heaven .stepStatus {
  flex-grow: 1;
  font-family: cursive;
  /* color: #4e73df !important; */
}
.new-heaven .stepStatus i {
  /* background-color: #fff; */
}
.new-heaven .stepStatus i.stepComplete {
  /* border-color: #0c2b84;
  color: #0c2b84;
  background-color: #fff;  */
}
.new-heaven .box {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 15px 0px,
    rgba(0, 0, 0, 0.08) 0px 25px 45px 0px !important;
  border-radius: 5px;
}
.new-heaven .tablebox {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 15px 0px,
    rgba(0, 0, 0, 0.08) 0px 25px 45px 0px !important;
}
.new-heaven .stepActive {
  text-decoration: underline;
  font-weight: bold;
  /* color: #0c2b84 !important; */
}
.new-heaven .progress-bar {
  background-color: #0635BA;
}
/* Removing !important from background */
.new-heaven .tablecont .table thead {
  background: #7ea0ff;
  background: -moz-linear-gradient(top, #5a6aae 0%, #35406d 100%);
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, #7ea0ff),
    color-stop(100%, #8f57ff)
  );
  background: -webkit-linear-gradient(top, #5a6aae 0%, #35406d 100%);
  background: -o-linear-gradient(top, #5a6aae 0%, #35406d 100%);
  background: -ms-linear-gradient(top, #5a6aae 0%, #35406d 100%);
  background: linear-gradient(to right, #5a6aae 0%, #35406d 100%);
}
.css-w3djcp-OptionComponent {
  background: #ff6663 !important;
}
.new-heaven .buttons .nextbtn {
  color: #5a6aae !important;
  border-color: #5a6aae !important;
}
.new-heaven .buttons .savebtn {
  background-color: #0635BA !important;
  border-color: #0635BA !important;
}
/* .new-heaven .tablecont .table tbody th button,
.new-heaven .tablecont .table tbody td button {
  background: #5a6aae !important;
} */
.new-heaven .tablecont .table tbody th button,
.new-heaven .tablecont .table tbody td button {
  background: #9bb0eb !important;
}
.new-heaven .tablecont .table tbody th button,
.new-heaven .tablecont .table tbody td button:hover {
  background: #1c3ec3 !important;
}
.new-heaven .numb .activeno a {
  background: #5a6aae !important;
}
@media only screen and (max-width: 769px) {
  .endorcedby {
    margin-bottom: 30px;
  }
}
