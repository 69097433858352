.mobile-sticky-footer {
  display: none;
}
@media only screen and (max-width: 850px) {
  .mobile-sticky-footer {
    display: block;
    height: 63px;
    background-color: #fff;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 250;
    position: fixed;
    border-top: 1px solid #24abaf;
  }
  .contact-title {
    width: 30%;
    padding: 5px;
    height: 100%;
    position: relative;
    display: inline-block;
    text-align: center;
  }
  .contact-title .mypara {
    position: absolute;
    padding: 10px 0 10px 15%;
    width: 100%;
    margin: 0 0 11px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #3e3f5d;
    font-size: 12px;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: normal;
    line-height: 1.6;
    text-overflow: ellipsis;
    text-align: left;
    letter-spacing: 0;
    margin-top: 0px;
  }
  .contact-title .mypara .myspan {
    font-size: 12px;
    font-weight: normal;
    line-height: 1.6;
    margin: 0;
    padding-bottom: 5px;
    padding-top: 5px;
    text-overflow: ellipsis;
    color: #9093f9;
    letter-spacing: 0;
  }
  .contact-tour {
    width: 35%;
    padding: 5px;
    display: inline-block;
    position: relative;
    height: 100%;
  }
  .contact-tour .mybtn {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    outline: none;
    font-size: 12px;
    transform: translate(-50%, -50%);
    padding: 5px 10px;
    border-radius: 50px;
    line-height: 1.5;
    border: 3px solid #24abaf;
    background-color: #24abaf;
    color: #fff;
  }
}
.react-calendar {
  width: 100%;
  border: none;
  height: 290px;
}
.react-calendar__navigation__arrow {
  visibility: hidden;
}
